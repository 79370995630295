import React, { useContext, useState } from 'react';
import { Col, Row, Form, Input, Icon, Button } from 'antd';
import { UserContext } from '../../App';
import md5 from 'md5';
import { fb, db } from '../../common/firebase/Firestore';
import * as firebase from "firebase/app";

function Setting(props) {
    const [form] = Form.useForm();
    const user = useContext(UserContext);
    var [disable, setDisable] = useState(false);
    var [notice, setNotice] = useState("");
    var [noticeColor, setNoticeColor] = useState("red");
    var [confirmDirty, setConfirmDirty] = useState(false);

    const changePass = e => {
        setDisable(true);
        setNoticeColor("red");
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Pass: ', values);
                var user = fb.auth().currentUser;

                if (user) {
                    var newPassword = md5(values.new_password);
                    var credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        values.password
                    );
                    console.log('credential: ', credential);
                    // Prompt the user to re-provide their sign-in credentials

                    user.reauthenticateWithCredential(credential).then(function () {
                        // User re-authenticated.
                        user.updatePassword(newPassword).then(function () {
                            setNoticeColor("green");
                            setNotice("Cập nhật mật khẩu thành công!");
                            console.log('Update pass success: ', newPassword);
                            // Update successful.
                        }).catch(function (error) {
                            console.log('Cannot update pass: ', error);
                            setDisable(false);
                            setNotice("Không thể cập nhật mật khẩu do có lỗi xảy ra!");
                            // An error happened.
                        });
                    }).catch(function (error) {
                        // An error happened.
                        console.log('Cannot re-authen: ', error);
                        setNotice("Mật khẩu không chính xác!");
                        setDisable(false);
                    });
                } else {
                    console.log("User signout");
                    setNotice("Không thể lấy thông tin người dùng hiện tại. Hãy thoát ra và đăng nhập lại!");
                    setDisable(false);
                }
            } else {
                console.log('Error: ', values);
                setDisable(false);
            }
        });
    };

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToOldPassword = (rule, value, callback) => {
        if (disable) setDisable(false);

        // const { form } = props;
        if (value && value == form.getFieldValue('password')) {
            callback('Mật khẩu mới trùng với mật khẩu cũ!');
            // } else if(value && confirmDirty ){
            //     form.validateFields(['confirm'], { force: true });
        } else {
            callback();
        }
    };

    const compareToNewPassword = (rule, value, callback) => {
        if (disable) setDisable(false);

        // const { form } = props;
        if (value && value !== form.getFieldValue('new_password')) {
            callback('Nhập lại mật khẩu không giống!');
        } else {
            callback();
        }
    };

    // const validateOldPassword = (rule, value, callback) => {
    //     const { form } = props;
    //     if (value && confirmDirty) {
    //         form.validateFields(['new_password'], { force: true });
    //     }
    //     callback();
    // };

    // const validateToNextPassword = (rule, value, callback) => {
    //     const { form } = props;
    //     if (value && confirmDirty) {
    //         form.validateFields(['confirm'], { force: true });
    //     }
    //     callback();
    // };

    const { getFieldDecorator } = form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div style={{ paddingTop: "20px" }}>
            <h1>Setting</h1>
            <hr />
            <h4>Đổi mật khẩu</h4>
            <Form {...formItemLayout} form={form} onSubmit={changePass} className="setting-form">
                <Form.Item style={{ marginBottom: "10px" }} label="Mật khẩu: "
                    name='password'
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input.Password
                        style={{ maxWidth: "200px" }}
                        placeholder="Old Password"
                    // disabled={disable} 
                    />

                </Form.Item>
                <Form.Item style={{ marginBottom: "10px" }} hasFeedback label="Mật khẩu mới: "
                    name='new_password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        {
                            validator: compareToOldPassword,
                        },
                    ]}>
                    <Input.Password
                        style={{ maxWidth: "200px" }}
                        placeholder="New Password"
                    // disabled={disable}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px" }} hasFeedback label="Nhập lại mật khẩu:" name='confirm'
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        {
                            validator: compareToNewPassword,
                        },
                    ]}
                >
                    <Input.Password
                        style={{ maxWidth: "200px" }}
                        onBlur={handleConfirmBlur}
                        placeholder="Re-type new password"
                    // disabled={disable}
                    />
                </Form.Item>
                <Row><Col span={12} offset={8}><span {...tailFormItemLayout} style={{ color: noticeColor }}>{notice}</span></Col></Row>
                <Form.Item  {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" style={{ width: "100%", height: "30px", maxWidth: "150px" }} disabled={disable}>
                        Xác nhận
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

// const Setting = Form.create({ name: 'setting' })(SettingForm);
export default Setting;
import React, { useState, useEffect, useContext } from 'react';
import { fb, db } from '../../common/firebase/Firestore';
import EditableCell, { EditableContext } from '../components/EditalbeCell';
import { Table, Popconfirm, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Upload, message } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import { UserContext, BrandContext } from '../../App';
import NewBrandComponent from './NewBrandComponent';
import EditableObject from '../components/EditableObject';
import Brand from '../../objects/Brand';
import * as CustomAntd from './antd-modified.css';

// const FormItem = Form.Item;

// const expandedRowRender = record => <p>{record.description}</p>;
// const showHeader = true;
// const footer = () => 'Here is footer';
// const scroll = { y: 240 };
// const pagination = { position: 'bottom' };



function BrandScreen(props) {
    const [form] = Form.useForm();

    // const user = useContext(UserContext);
    const oldData = useContext(BrandContext);
    var [brands, setBrands] = useState([]);
    var [editingBrand, setEditingBrand] = useState(null);
    // var [showingModal, setShowingModal] = useState(false);
    // var [loadingImage, setLoadingImage] = useState(false);
    var [pagination, setPagination] = useState({ position: 'both' });
    var [searchText, setSearchText] = useState("");
    var [isLoading, setIsLoading] = useState(false);
    // var [newImage, setNewImage] = useState([]);
    var [newImageUrl, setNewImageUrl] = useState("");
    var [error, setError] = useState("");
    console.log("==== Brands: ", brands);

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            width: "20%",
            editable: true,
            render: (text) => <div
                style={{ padding: 0, backgroundImage: `url(${text})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "8vmin", height: "8vmin" }}
                className="logo_team"
            />
            // render: (text, record) => {
            //     const imageNumber = newImage.length;
            //     const editable = isEditing(record);
            //     return editable ? (
            //         <Upload
            //             name="Logo"
            //             listType="picture-card"
            //             className="avatar-uploader"
            //             fileList={newImage}
            //             action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            //             style={{ height: "5vmin", width: "5vmin" }}
            //             beforeUpload={ImageHelper.beforeUpload}
            //             onChange={hasNewImage}
            //         >
            //             {
            //                 imageNumber >= 1 ? null :
            //                     <div>
            //                         {isLoading ? <LoadingOutlined /> : <PlusSquareTwoTone />}
            //                         <div className="ant-upload-text">Upload</div>
            //                     </div>
            //             }
            //         </Upload>

            //     ) : (
            //             <div
            //                 style={{ padding: 0, backgroundImage: `url(${text})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "5vmin", height: "5vmin" }}
            //                 className="logo_team"
            //             />
            //         );
            // },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "15%",
            editable: true,
            render: (text) => <a href="#">{text}</a>,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            width: "15%",
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: "20%",
            editable: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: "10%",
            editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "20%",
            render: (text, record) => (
                <div>
                    <Button onClick={() => editBrand(record)} type="primary">
                        <EditFilled />
                    </Button>
                    &nbsp;
                    <Button onClick={() => deleteBrand(record.key)} type="danger">
                        <DeleteFilled />
                    </Button>
                </div>
            ),
            // render: (text, record) => {
            //     const editable = isEditing(record);
            //     return editable ? (
            //         <div>
            //             <EditableContext.Consumer>
            //                 {form => (
            //                     <Button
            //                         type="primary"
            //                         onClick={() => save(form, record.key)}
            //                         style={{ marginRight: 8 }}
            //                     >
            //                         Save
            //                     </Button>
            //                 )}
            //             </EditableContext.Consumer>
            //             <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
            //                 <a>Cancel</a>
            //             </Popconfirm>
            //         </div>
            //     ) : (
            //             <Button disabled={editingKey !== ''} onClick={() => edit(record.key, record.logo)} type="primary">
            //                 Edit
            //             </Button>
            //         );
            // }
        },
    ];

    useEffect(() => {
        setBrands(oldData);
    }, [oldData]);


    // const hasNewImage = ({ fileList }) => {
    //     if (fileList.length > 0) {
    //         ImageHelper.getBase64(fileList[0].originFileObj, newImageUrl =>
    //             setNewImageUrl(newImageUrl)
    //         );
    //     }
    //     setNewImage(fileList);
    //     console.log("Logo change: ", fileList);
    // };

    const handlePaginationChange = e => {
        const { value } = e.target;
        setPagination(value === 'none' ? false : { position: value });
    };

    const cancel = () => {
        setEditingBrand(null);
    };

    const deleteBrand=()=>{}

    const editBrand = (brand) => {
        console.log("Edit brand: ", brand);
        var selectBrand=new Brand(brand);
        selectBrand.addLockedField('key');
        setEditingBrand(selectBrand);
        // setShowingModal(true);
    }

    const updateBrand = async (brandData) => {
        console.log("new brand data: ", brandData);

        if (brands && brands.length > 0 && brands.filter(b => { return b.name.toLowerCase() === brandData.name.toLowerCase() && b.key !== brandData.key }).length > 0) {
            setError("Không thể cập nhật thông tin. Tên thương hiệu [" + brandData.name + "] đã tồn tại");
            setEditingBrand(null);
            setIsLoading(false);
            return;
        }

        //Update client
        const oldData = brands;
        const newData = [...brands];
        const index = newData.findIndex(item => brandData.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...brandData,
            });
        } else {
            newData.push(brandData);
        }
        setBrands(newData);

        //Save to server
        try {
            // let brand = [...brandData];

            db.collection('brand').doc(`${brandData.key}`).update(brandData);
        } catch (error) {
            //Recover old data
            setBrands(oldData);

            console.log('error', error)
        }
        setEditingBrand(null);
        setIsLoading(false);
    }

    // const save = (form, key) => {
    //     form.validateFields((error, row) => {
    //         if (error) {
    //             console.log("Data invalid: ", error);
    //             return;
    //         }
    //         console.log("Key brand: ", key);
    //         console.log("Edited brand: ", row);

    //         if (brands && brands.length > 0 && brands.filter(b => { return b.name.toLowerCase() === row.name.toLowerCase() && b.key !== key }).length > 0) {
    //             setError("Không thể cập nhật thông tin. Tên thương hiệu [" + row.name + "] đã tồn tại");
    //             setEditingKey('');
    //             setIsLoading(false);
    //             return;
    //         }

    //         //Update client
    //         const oldData = brands;
    //         const newData = [...brands];
    //         const index = newData.findIndex(item => key === item.key);
    //         if (index > -1) {
    //             const item = newData[index];
    //             newData.splice(index, 1, {
    //                 ...item,
    //                 ...row,
    //             });
    //         } else {
    //             newData.push(row);
    //         }
    //         setBrands(newData);
    //         setEditingKey('');
    //         setIsLoading(false);

    //         //Save to server
    //         try {
    //             let brand = {
    //                 name: row.name,
    //                 country: row.country,
    //                 description: row.description,
    //                 type: row.type,
    //             };
    //             if (newImageUrl) {
    //                 brand.logo = newImageUrl;
    //             }

    //             db.collection('brand').doc(`${key}`).update(brand);
    //         } catch (error) {
    //             //Recover old data
    //             setBrands(oldData);

    //             console.log('error', error)
    //         }
    //     });
    // }

    // const edit = (key, currImageUrl) => {
    //     setNewImage([{
    //         uid: key, name: 'logo', status: 'done',
    //         url: currImageUrl
    //     }]);
    //     setEditingKey(key);
    //     setNewImageUrl(currImageUrl);
    // }

    // const components = {body: {cell: EditableCell}};

    const dataSource = searchText ?
        brands.filter(asset => {
            if ((asset.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (asset.country.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (asset.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (asset.type.toLowerCase().indexOf(searchText.toLowerCase()) > -1))
                return true;
            else return false;
        })
        : brands;

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    return (
        <div>
            <h1>Quản lý hãng xe</h1>
            <NewBrandComponent brands={brands} isLoading={false} searchListener={(text) => { setSearchText(text) }} />

            {/* <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh Sách thương hiệu</span>
                <EditableContext.Provider value={form}>
                    <Table
                        components={{ body: { cell: EditableCell } }}
                        columns={mapColumns}
                        dataSource={dataSource}
                        loading={brands.length == 0}
                        scroll={{ x: "100%", y: "100%" }}
                        rowClassName="editable-row"
                        size="small"
                        pagination={{
                            onChange: cancel,
                            position: "both"
                        }}
                    />
                </EditableContext.Provider> */}

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh Sách Thương hiệu</span>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource}
                    loading={brands.length == 0}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key + "_" + record.brand)}
                />
            </div>

            <EditableObject
                input={editingBrand}
                onCancel={() => { setEditingBrand(null) }}
                onSubmit={updateBrand}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin hãng" />

            {/* <EditableObject
                // visible={showingModal}
                // onCancel={()=>{setShowingModal(false)}}
                input={editingBrand}
                onSubmit={updateBrand}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin  Xe" /> */}
        </div>
    );
}

// const BrandScreen = Form.create({ name: 'brand' })(BrandForm);
export default BrandScreen;
import React, { useContext, useState, useEffect } from 'react';
import SearchField from "react-search-field";
import { Select, Row, Col } from 'antd';
import { UserContext, BrandContext, BikeContext, ClassContext, GroupContext } from '../../App';
import { fb, db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';
import { useGetClass, useGetGroup } from '../../hooks/PartHook';

const { Option } = Select;

export default function SearchPartComponent(props) {
    const limit = props.limit ? props.limit : 100;
    const onResult = props.onResult;
    const onFilter = props.onFilter;

    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    // const allClasses = useContext(ClassContext);
    // const allGroups = useContext(GroupContext);

    // var [groups, setGroups] = useState(allGroups);
    var [bikeClasses, setBikeClasses] = useState(allClasses);
    var [bikes, setBikes] = useState(allBikes);
    var [selectedGroup, setSelectedGroup] = useState('all');
    var [selectedClass, setSelectedClass] = useState('all');
    var [selectedBike, setSelectedBike] = useState('all');
    var [selectedBrand, setSelectedBrand] = useState('all');
    var [keyword, setKeyword] = useState('');
    
    var allClasses = useGetClass(selectedBrand, selectedBike);
    var allGroups = useGetGroup(selectedBrand, selectedBike, selectedClass);

    // useEffect(() => {
    //     if (allBrands && allBrands.length > 0) {
    //         setBrands(allBrands);
    //     }
    // }, [allBrands]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            setBikes(allBikes);
        }
    }, [allBikes]);

    useEffect(() => {
        if (allClasses && allClasses.length > 0) {
            setBikeClasses(allClasses);
        }
    }, [allClasses]);

    // useEffect(() => {
    //     if (allGroups && allGroups.length > 0) {
    //         setGroups(allGroups);
    //     }
    // }, [allGroups]);


    useEffect(() => {
        var partRef = db.collectionGroup(COLLECTIONS.SUB_PART);
        if (selectedBrand !== 'all')
            partRef = partRef.where("brand", "==", selectedBrand);
        if (selectedBike !== 'all')
            partRef = partRef.where("bike", "==", selectedBike);
        if (selectedClass !== 'all')
            partRef = partRef.where("bikeClass", "==", selectedClass.replace('_','/'));
        if (selectedGroup !== 'all') {
            partRef = partRef.where("group_id", "==", selectedGroup.toString());
        }
        if (keyword)
            partRef = partRef.where("keywords", "array-contains", keyword.toLowerCase());

            console.log("== brand: ", selectedBrand);
            console.log("== bike: ", selectedBike);
            console.log("== class: ", selectedClass);
            console.log("== group: ", selectedGroup);
            console.log("== keyword: ", keyword.toLowerCase());
            // console.log("RESULTS: ", results);

        partRef.limit(limit).get().then((docs) => {
            let results = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    results.push({ key: doc.id, ...doc.data() });
                }
            })
            onResult && onResult(results);
            console.log("RESULTS: ", results);
        });

    }, [selectedBrand, selectedBike, selectedClass, selectedGroup, keyword])

    const onEnter = (text) => {
        setKeyword(text);
    }

    const selectBrand = (brand) => {
        if (brand === 'all') {
            setBikes(allBikes);
        } else if (allBikes && allBikes.length > 0) {
            setBikes(allBikes.filter(bike => {
                if (bike && (bike.brand.toLowerCase().indexOf(brand.toLowerCase()) > -1))
                    return true;
                else return false;
            }));
        }
        setSelectedBrand(brand);
    }
    const selectBike = (bike) => {
        console.log("BIke: ", allClasses);
        if (bike === 'all') {
            setBikeClasses(allClasses);
        } 
        // else if (allClasses && allClasses.length > 0) {
        //     console.log("BIke class: ", allClasses);
        //     setBikeClasses(allClasses.filter(bikeClass => {
        //         if (bikeClass && (bikeClass.bike.toLowerCase().indexOf(bike.toLowerCase()) > -1))
        //             return true;
        //         else return false;
        //     }));
        // }
        setSelectedBike(bike);
    }
    const selectClass = (bikeClass) => {
        console.log("BIke class: ", bikeClass);
        // if (bikeClass === 'all') {
        //     setGroups(allGroups);
        // } 
        // else if (allGroups && allGroups.length > 0) {
        //     setGroups(allGroups.filter(group => {
        //         // console.log("BIke class: ", group);
        //         if (group && group.bikeClass && Array.isArray(group.bikeClass) && group.bikeClass.length == 3 && (group.bikeClass[2].toLowerCase().indexOf(bikeClass.toLowerCase()) > -1))
        //             return true;
        //         else if (group && typeof group.bikeClass === "string" && (group.bikeClass.toLowerCase().indexOf(bikeClass.toLowerCase()) > -1))
        //             return true;
        //         else return false;
        //     }));
        // }
        setSelectedClass(bikeClass);
    }
    const selectGroup = (group) => {
        console.log("BIke group: ", group);
        // setBikes(bikes.filter(bike => {
        //     if ((bike.brand.toLowerCase().indexOf(group.toLowerCase()) > -1))
        //         return true;
        //     else return false;
        // }));
        setSelectedGroup(group);
    }

    const filterClient = (text) => {
        onFilter && onFilter(text);
    }


    return (
        <Row>
            <Col span={5} style={{ paddingRight: "10px" }}>
                <Select
                    defaultValue={selectedBrand}
                    style={{ width: "100%" }}
                    onChange={selectBrand}
                >
                    <Option key="all">Tất cả Thương hiệu</Option>
                    {allBrands.map(brand => (
                        <Option key={brand.key}>{brand.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedBike}
                    onChange={selectBike}
                >
                    <Option key="all">Tất cả Xe</Option>
                    {bikes.map(bike => (
                        <Option key={bike.key}>{bike.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={5} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedClass}
                    onChange={selectClass}
                >
                    <Option key="all">Tất cả Phiên bản</Option>
                    {bikeClasses?bikeClasses.map(bikeClass => (
                        <Option key={bikeClass.key}>{bikeClass.name}</Option>
                    )):null}
                </Select>
            </Col>
            <Col span={5} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedGroup}
                    onChange={selectGroup}
                >
                    <Option key="all">Tất cả nhóm phụ tùng</Option>
                    {allGroups?allGroups.map(group => (
                        <Option key={group.key}>{group.name}</Option>
                    )):null}
                </Select>
            </Col>
            <Col span={5}>
                <SearchField
                    style={{ width: "100%" }}
                    placeholder="Search..."
                    onChange={(e) => filterClient(e)}
                    placeholder="Nhập tên phụ tùng"
                    searchText=""
                    onEnter={(e) => onEnter(e)}
                    onSearchClick={(e) => onEnter(e)}
                />
            </Col>
        </Row>
    );
}

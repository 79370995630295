import React from "react";
import {convertPrice} from "../../common/helpers/Price";

export default function Price(props) {
    const error=props.error;
    const color=props.color?props.color:"white";
    return (
        <span className="price">
            {
                error?
                <span className="price-value" style={{color:"orangered"}}>{convertPrice(props.price)}</span>
                :<span className="price-value" style={{color:`${color}`}}>{convertPrice(props.price)}</span>
            }
            <span className="symbol"> vnd</span>
        </span>
    );
}
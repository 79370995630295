import * as NAME from '../constant/name';

export const getLatest10Years = () => {
    let years = [];
    const date=new Date();
    const currentYear = date.getFullYear();
    for(let i=0;i<10;i++) {
        years.push((currentYear-i).toString());
    }
    return years;
}

export const getOrderStatus = (status) => {
    switch(status) {
        case 0: return NAME.ORDER_STATUS_NAME.NEW;
        case 1: return NAME.ORDER_STATUS_NAME.PROCESSING;
        case 2: return NAME.ORDER_STATUS_NAME.ORDERED;
        case 3: return NAME.ORDER_STATUS_NAME.WAITTING;
        case 4: return NAME.ORDER_STATUS_NAME.STORE;
        case 5: return NAME.ORDER_STATUS_NAME.DELIVERY;
        case 6: return NAME.ORDER_STATUS_NAME.RECEIVED;
        case 7: return NAME.ORDER_STATUS_NAME.CUSTOMER_CANCEL;
        case 8: return NAME.ORDER_STATUS_NAME.REJECTED;
    }
    return NAME.ORDER_STATUS_NAME.NEW;
}

export const getUserStatus = (status) => {
    switch(status) {
        case 0: return NAME.USER_STATUS_NAME.REPORTING;
        case 1: return NAME.USER_STATUS_NAME.NORMAL;
        case 2: return NAME.USER_STATUS_NAME.DISTRUST;
        case 3: return NAME.USER_STATUS_NAME.TRUSTED;
        case 4: return NAME.USER_STATUS_NAME.BLOCKED;
        // case 5: return NAME.USER_STATUS_NAME.DELETED;
    }
    return NAME.USER_STATUS_NAME.NORMAL;
}

export const getUserRole = (role) => {
    switch(role) {
        case "admin": return NAME.USER_ROLE.ADMIN;
        case "customer": return NAME.USER_ROLE.CUSTOMER;
        case "editor": return NAME.USER_ROLE.EDITOR;
    }
    return NAME.USER_ROLE.CUSTOMER;
}

export const getBasicProps = (key, fProps) => {
    var mappedProps = {};
    mappedProps.label = fProps ? (fProps.label ? fProps.label : key) : key;
    mappedProps.placeholder = fProps ? (fProps.placeholder ? fProps.placeholder : key) : key;
    mappedProps.required = fProps ? (fProps.required ? fProps.required : false) : false;
    mappedProps.style = fProps ? (fProps.style ? fProps.style : {}) : {};
    mappedProps.default_require_message = "Please input " + mappedProps.label;
    mappedProps.require_message = fProps ? (fProps.require_message ? fProps.require_message : mappedProps.default_require_message) : mappedProps.default_require_message;
    mappedProps.rules = fProps ? (fProps.rules ? fProps.rules : []) : [];

    mappedProps.defaultValue = "";
    if (fProps && fProps.defaultValue) {
        mappedProps.defaultValue = fProps.defaultValue;
    }

    return mappedProps;
}

export const getCascaderBike = (brands, bikes) => {
    var options = [];
    brands.forEach(brand => {
        if (brand.key) {
            let brandObj = { label: brand.name ? brand.name : brand.key, value: brand.key };
            let bikeOptions = [];
            bikes.forEach(bike => {
                if (bike.brand && brand.key && bike.brand === brand.key) {
                    bikeOptions.push({ label: bike.name ? bike.name : bike.key, value: bike.key, isLeaf: false })
                }
            });
            if (bikeOptions.length > 0)
                brandObj.children = bikeOptions;

            options.push(brandObj);
        }
    });
    return options;
}

export const getCascaderClass = (brands, bikes, classes) => {
    var options = [];
    brands.forEach(brand => {
        if (brand.key) {
            let brandObj = { label: brand.name ? brand.name : brand.key, value: brand.key };
            let bikeOptions = [];
            bikes.forEach(bike => {
                if (bike.brand && brand.key && bike.brand === brand.key) {
                    let bikeObj = { label: bike.name ? bike.name : bike.key, value: bike.key };
                    let classOptions = [];

                    classes.forEach(bikeClass => {
                        if (bikeClass.brand && bikeClass.bike && brand.key && bike.key && bikeClass.brand === brand.key && bikeClass.bike === bike.key) {
                            classOptions.push({ label: bikeClass.name ? bikeClass.name : bikeClass.key, value: bikeClass.key })
                        }
                    });
                    if (classOptions.length > 0)
                        bikeObj.children = classOptions;

                    bikeOptions.push(bikeObj);
                }
            });
            if (bikeOptions.length > 0)
                brandObj.children = bikeOptions;

            options.push(brandObj);
        }
    });
    return options;
}

export const getCascaderGroup = (brands, bikes, classes, groups) => {
    var options = [];
    brands.forEach(brand => {
        if (brand.key) {
            let brandObj = { label: brand.name ? brand.name : brand.key, value: brand.key };
            let bikeOptions = [];
            bikes.forEach(bike => {
                if (bike.brand && brand.key && bike.brand === brand.key) {
                    let bikeObj = { label: bike.name ? bike.name : bike.key, value: bike.key };
                    let classOptions = [];

                    classes.forEach(bikeClass => {
                        if (bikeClass.brand && bikeClass.bike && brand.key && bike.key && bikeClass.brand === brand.key && bikeClass.bike === bike.key) {
                            // console.log("GROUPS: ", groups);
                            let classObj = { label: bikeClass.name ? bikeClass.name : bikeClass.key, value: bikeClass.key };
                            let groupOptions = [];
                            groups.forEach(group => {
                                if (group.brand && group.bike && group.bikeClass && brand.key && bike.key && bikeClass.key && group.brand === brand.key && group.bike === bike.key && group.bikeClass === bikeClass.key) {
                                    groupOptions.push({ label: group.name ? group.name : group.key, value: group.key });
                                }

                            });
                            if (groupOptions.length > 0)
                                classObj.children = groupOptions;

                            classOptions.push(classObj);
                        }
                    });
                    if (classOptions.length > 0)
                        bikeObj.children = classOptions;

                    bikeOptions.push(bikeObj);
                }
            });
            if (bikeOptions.length > 0)
                brandObj.children = bikeOptions;

            options.push(brandObj);
        }
    });
    return options;
}

export const numberWithCommas = (x)=>  {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
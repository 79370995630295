import {InputField, INPUT_TYPE, mapInput} from './InputObject';

export default class UIObject {
    constructor(data) {
        this.originData=data?{...data}:{};
        this.outputData=data?{...data}:{};
        this.inputObject={}
        this.hiddenFields = [];
        this.lockedFields = [];
    }

    addLockedField = (field) => {
        if(!this.lockedFields.includes(field)) {
            this.lockedFields.push(field);
            this.mapData();
        }
    }

    unlockField = (field) => {
        if(this.lockedFields.includes(field)) {
            this.lockedFields= this.lockedFields.filter(function(ele){ return ele != field; });
            this.mapData();
        }
    }

    addHiddenField = (field) => {
        if(!this.hiddenFields.includes(field)) {
            this.hiddenFields.push(field);
            this.mapData();
        }
    }

    unHideField = (field) => {
        if(this.hiddenFields.includes(field)) {
            this.hiddenFields= this.hiddenFields.filter(function(ele){ return ele != field; });
            this.mapData();
        }
    }

    getOriginData = () => {
        return this.originData;
    }

    mapData = () => {
        this.inputObject = mapInput(this.originData, this);
        // console.log("Input objects: ", this.inputObject);
    }

    revert=()=>{
        this.outputData={...this.originData};
    }

    onChange = (data) => {
        this.outputData=data;
        this.mapData();
    }

    inputUI = () => {
        if(this.hiddenFields && this.hiddenFields.length>0) {
            this.hiddenFields.forEach((field)=>{
                if(this.inputObject[field])
                    this.inputObject[field].dataType=INPUT_TYPE.HIDDEN;
            });
        }
        if(this.lockedFields && this.lockedFields.length>0) {
            this.lockedFields.forEach((field)=>{
                if(this.inputObject[field])
                    this.inputObject[field].dataType=INPUT_TYPE.LOCKED;
            });
        }
        return this.inputObject;
    }

    data = () => {
        return this.outputData;
    }
}
import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Login from './module/auth/Login';
import useUserStatus from './hooks/UserStatus';
import logo from './logo.svg';
import * as COLLECTIONS from './common/constant/collections';

import { renderRoutes } from "react-router-config";
import routes from './router';
import { fb, db } from './common/firebase/Firestore';
import LayoutStyle from './module/components/layout.module.css';
import { Spin } from 'antd';

export const UserContext = React.createContext('user');
export const BrandContext = React.createContext('brand');
export const BikeContext = React.createContext('bike');
export const ClassContext = React.createContext('class');
export const GroupContext = React.createContext('group');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function App(props) {
  var [isAdmin, setIsAdmin] = useState(false);
  var [isFinishLoading, setFinishLoading] = useState(false);
  var [user, setUser] = useState(false);
  var [brands, setBrands] = useState([]);
  var [bikes, setBikes] = useState([]);
  var [classes, setClasses] = useState([]);
  var [groups, setGroups] = useState([]);
  var [email, setEmail] = useState("");
  var [notice, setNotice] = useState("");

  const validatePermission = (email) => {
    setEmail(email);
  }

  const onLoginSuccess = (userInfo) => {
    setNotice("");
    setFinishLoading(true);
    if (userInfo) {
      if (userInfo.type === "admin") {
        setUser(userInfo);
        setIsAdmin(true);

        //Get list Brands & push to context
        db.collection(COLLECTIONS.BRAND).get().then((querySnapshot) => {
          let listBrand = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              console.log("Brand: ", doc.data());
              let brand = {
                key: doc.id,
                name: doc.data().name ? doc.data().name : " ",
                country: doc.data().country ? doc.data().country : "Việt Nam",
                description: doc.data().description ? doc.data().description : "des Việt Nam",
                logo: doc.data().logo ? doc.data().logo : " ",
                type: doc.data().type ? doc.data().type : "auth",
              };
              listBrand.push(brand);
            }
          });

          setBrands(listBrand);
          // setBrands((listBrand.length == 0) ? ([{ key: "", logo: "", name: "Không tìm thấy thương hiệu nào", description:" ", country: "", type: "" }]) : listBrand);
        });

        //Get list Bikes & push to context
        db.collectionGroup(COLLECTIONS.SUB_BIKE).get().then((querySnapshot) => {
          let listBike = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              let bike = {
                key: doc.id,
                name: doc.data().name ? doc.data().name : " ",
                full_name: doc.data().full_name ? doc.data().full_name : " ",
                description: doc.data().description ? doc.data().description : " ",
                brand: doc.data().brand ? doc.data().brand : " ",
                year: doc.data().year ? doc.data().year : " ",
                tag: doc.data().tag ? doc.data().tag : " ",
              };
              listBike.push(bike);
            }
          });

          setBikes(listBike);
          // setBrands((listBrand.length == 0) ? ([{ key: "", logo: "", name: "Không tìm thấy thương hiệu nào", description:" ", country: "", type: "" }]) : listBrand);
        });

        // db.collectionGroup(COLLECTIONS.SUB_CLASS).get().then((querySnapshot) => {
        //   let listClass = [];
        //   querySnapshot.forEach((doc) => {
        //     if (doc.exists) {
        //       let bikeClass = {
        //         key: doc.id,
        //         logo: doc.data().logo ? doc.data().logo : " ",
        //         brand: doc.data().brand ? doc.data().brand : "",
        //         bike: doc.data().bike ? doc.data().bike : "",
        //         name: doc.data().name ? doc.data().name : "",
        //         description: doc.data().description ? doc.data().description : "",
        //         year: doc.data().year ? doc.data().year : [" "],
        //         tag: doc.data().tag ? doc.data().tag : [""],
        //       };
        //       listClass.push(bikeClass);
        //     }
        //   });

        //   setClasses(listClass);
        // });

        //Get list Bikes & push to context
        // db.collectionGroup(COLLECTIONS.SUB_GROUP).get().then((querySnapshot) => {
        //   let listGroup = [];
        //   querySnapshot.forEach((doc) => {
        //     if (doc.exists) {
        //       let group = {
        //         key: doc.id,
        //         logo: doc.data().logo ? doc.data().logo : " ",
        //         brand: doc.data().brand ? doc.data().brand : "",
        //         bike: doc.data().bike ? doc.data().bike : "",
        //         bikeClass: doc.data().bikeClass ? doc.data().bikeClass : [""],
        //         name: doc.data().name ? doc.data().name : "",
        //         description: doc.data().description ? doc.data().description : "",
        //         tag: doc.data().tag ? doc.data().tag : [""],
        //       };
        //       listGroup.push(group);
        //     }
        //   });

        //   setGroups(listGroup);
        // });

      } else {
        console.log("Dont have permission");

        fb.auth().signOut().then(function () {
          // Sign-out successful.
          console.log("Sign-out successful");
          setNotice("You don't have permission to access this page");
          setIsAdmin(false);
        }).catch(function (error) {
          // An error happened.
          console.log("Sign-out error: ", error);
        });

      }
    }
  }

  const userStatus = useUserStatus(onLoginSuccess);

  const content = (userStatus && userStatus.isLogin && isAdmin) ?
    <BrowserRouter>
      <UserContext.Provider value={user}>
        <BrandContext.Provider value={brands}>
          <BikeContext.Provider value={bikes}>
            <ClassContext.Provider value={classes}>
              <GroupContext.Provider value={groups}>
                {renderRoutes(routes)}
              </GroupContext.Provider>
            </ClassContext.Provider>
          </BikeContext.Provider>
        </BrandContext.Provider>
      </UserContext.Provider>
    </BrowserRouter>
    :
    <Login signInSuccess={validatePermission} notice={notice} />;

  const loadingScreen = <div style={{ backgroundColor: "black", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
    <img src={logo} className={LayoutStyle.logo} alt="logo" />
    <Spin style={{ margin: "50px" }} size="large" />
    <span style={{ color: "white" }}>Loading...</span>
  </div>

  return (
    <ErrorBoundary>
      {/* {isFinishLoading ? content : loadingScreen}, */}
      {(userStatus != null) ? content : loadingScreen},
    </ErrorBoundary>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import logo from '../../logo.svg';
import LayoutStyle from '../components/layout.module.css';
import { Form, Input, Button, Checkbox } from 'antd';
import { LockFilled, UserOutlined } from '@ant-design/icons';
import { fb, db, DB_TYPE } from '../../common/firebase/Firestore';
import * as SYSTEM from '../../common/constant/system';
import md5 from 'md5';
import * as PATH from '../../common/constant/path';
import { Redirect } from "react-router-dom";

function Login(props) {

    const [form] = Form.useForm();

    var originNotice = props.notice;
    var [notice, setNotice] = useState(props.notice);
    var [disable, setDisable] = useState(false);
    var [email, setEmail] = useState("");
    const signInSuccess = props.signInSuccess;

    useEffect(() => {
        if (originNotice) {
            setNotice(originNotice);
            setDisable(false);
        }
    }, [originNotice]);

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                onLogin(values.username, values.password);
            }
        });
    };

    const onLogin = async (name, pass) => {
        setDisable(true);
        var success = true;
        setNotice("");

        await fb.auth().signInWithEmailAndPassword(name, md5(pass)).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            setNotice(error.message);
            console.log("Error: ", error);
            setDisable(false);
            success = false;
            // ...
        });
        if (success) {
            signInSuccess(name);
        }

        // const key = md5(name + pass);
        // db.collection(key).doc("users").get().then((doc) => {
        //     if (doc.exists && doc.data().permission === 0 && doc.data().type === 0) {
        //         getUserInfo(doc.data().uid);
        //     } else {
        //         setNotice("Invalid user or password");
        //         setDisable(false);
        //     }
        // }).catch((error) => {
        //     setNotice("Error:" + error.message);
        //     setDisable(false);
        // });
    }

    const { getFieldDecorator } = form;

    const onFinish = values => {
        console.log('Received values of form: ', values);
        onLogin(values.username, values.password);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={LayoutStyle.body} style={{ height: "100vh", width: "100vw" }}>
            <div className={LayoutStyle.login_container}>
                <img src={logo} className={LayoutStyle.logo} alt="logo" />
                <Form className="login-form"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Username"
                            disabled={disable}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: "10px" }}
                        name='password'
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input.Password
                            prefix={<LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Password"
                            disabled={disable} />

                    </Form.Item>
                    <span style={{ color: "red" }}>{notice}</span>
                    <Form.Item
                        name='remember'
                        valuePropName='checked'
                        initialValue={true}
                    >
                        <Checkbox style={{ color: "white" }}>Remember me</Checkbox>
                        <a style={{ float: "right" }} href="">Forgot password</a>
                        <Button type="primary" htmlType="submit" style={{ width: "100%", height: "30px", marginTop: "15px" }} disabled={disable}>
                            Đăng nhập
                        </Button>
                    </Form.Item>
                </Form>
                {/* <Input 
                    onChange={(e)=>setName(e.target.value)} 
                    placeholder="Input your name" 
                    disabled={disable}
                    value={name}
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}/>
                
                <Input.Password 
                    onChange={(e)=>{setPass(e.target.value)}}
                    placeholder="Input password"
                    disabled={disable}
                    onPressEnter={onLogin}
                    saveInput={null}
                    autocomplete="off"
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}/>

                <span style={{color:"red"}}>{notice}</span>
                <Button type="primary" style={{width:"100%", height:"30px"}} onClick={onLogin} disabled={disable}>
                    Đăng nhập
                </Button> */}
            </div>
            <div style={{ position: "absolute", bottom: 0, width: "100%", textAlign: "center", color: "white" }}>Version: {SYSTEM.VERSION} - Database: {DB_TYPE}</div>
        </div>
    );
}

export default Login;
import {InputField, INPUT_TYPE} from './InputObject';
import UIObject from './UIObject';
import * as Converter from '../common/helpers/Converter';

export default class Bike extends UIObject {
    constructor(data) {
        super(data);

        this.brand = new InputField("brand", 0) ;
        this.brand.setProps({
            required:true,
            label:"Thương hiệu",
        });
        this.brand.convertToSelect(["Kawasaki"]);

        this.name= new InputField("name", "Brand's name");
        this.name.setProps({
            required:true,
            placeholder: "Please input bike code",
            label:"Ký hiệu xe",
        });

        this.full_name = new InputField ("full_name", this.name.value);
        this.full_name.setProps({
            label:"Tên đầy đủ",
            placeholder: "Please input full name for new bike",
        });

        this.description = new InputField ("description", "description default");
        this.description.setProps({
            label:"Mô tả",
            placeholder: "Please input new bike's description",
        });

        this.year = new InputField("year", "");
        this.year.setProps({
            label:"Years",
            placeholder: "Nhập các đời theo năm"
        });
        this.year.convertToMultiSelect(Converter.getLatest10Years());

        this.tag = new InputField("tag", "");
        this.tag.setProps({
            label:"Tag",
            placeholder: "Nhập các từ khóa liên quan"
        });
        this.tag.convertToMultiSelect([Converter.getLatest10Years()[0]]);

        this.mapData();           
        this.addHiddenField("key");
    }

    // setTags=(brands) => {
    //     this.brand.convertToSelect(brands);
    //     this.mapData();     
    // }

    setBrands=(brands) => {
        this.brand.convertToSelect(brands);
        this.mapData();     
    }
    setBrand=(brand) => {
        this.brand.addProp({defaultValue:brand})
        this.mapData();     
    }
}
import React, { Fragment, useState } from 'react';
import { Row, Col, Button, Switch } from 'antd';
import {BankAccount} from './Bank.js';
import EditableObject from '../components/EditableObject.js';

export default function BankAccountItem(props) {
    const bankAccount = props.data;
    const onUpdateInfo = props.onUpdate;
    const onDelete = props.onDelete;
    const [editingBank, setEditingBank] = useState(null);
    const [error, setError] = useState(null);

    const updateBank = (data) => {
        if(onUpdateInfo) {
            console.log("Update data: ", data);
            const updateStatus = onUpdateInfo(data);
            if(updateStatus!=null) setError(updateStatus);
        }
        setEditingBank(null);
    }

    const deleteBank = (bank) => {
        onDelete && onDelete(bank);
    }

    return (
        <Fragment>
            <Row style = {{marginTop:"20px"}}>
                <Col span={4}><img src={bankAccount.logo} style={{maxWidth:"120px"}}/></Col>
                <Col span={14}>
                    <Row>
                        <Col span={8}>Tên ngân hàng: </Col>
                        <Col span={16}>{bankAccount.name}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>Chi nhánh: </Col>
                        <Col span={16}>{bankAccount.chi_nhanh}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>Số tài khoản: </Col>
                        <Col span={16}>{bankAccount.stk}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>Chủ tài khoản: </Col>
                        <Col span={16}>{bankAccount.owner}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>Công khai: </Col>
                        <Col span={16}><Switch defaultChecked={bankAccount.visible} disabled/></Col>
                    </Row>
                </Col>
                <Col span={6} style={{paddingRight:"40px"}}>
                <Button type="primary" icon="edit" onClick={() => { setEditingBank(new BankAccount(bankAccount)) }}>Chỉnh sửa</Button>
                <Button type="danger" icon="delete" onClick={() => { deleteBank(bankAccount) }}>Xóa</Button>
                </Col>
            </Row>

            <EditableObject
                input={editingBank}
                onCancel={() => { setEditingBank(null) }}
                onSubmit={updateBank}
                error={error}
                submitButton="Cập nhật"
                title="Chỉnh sửa thông tin tài khoản ngân hàng" />
        </Fragment>
    );
}
import {InputField, INPUT_TYPE} from './InputObject';
import UIObject from './UIObject';
import * as Converter from '../common/helpers/Converter';

export default class Customer extends UIObject {
    constructor(data) {
        super(data);

        this.name = new InputField("name", "") ;
        this.name.setProps({
            label:"Tên khách hàng",
        });

        this.email = new InputField("email", data?data.email:"") ;
        this.email.setProps({
            label:"E-mail",
        });

        this.phone = new InputField("phone", data?data.phone:"") ;
        this.phone.setProps({
            label:"Số điện thoại",
        });

        this.address = new InputField("address", data?data.address:"") ;
        this.address.setProps({
            label:"Địa chỉ",
        });

        this.password = new InputField("password", data?data.password:"") ;
        this.password.setProps({
            label:"Mật khẩu",
        });

        //Mã khách hàng
        this.index = new InputField("index", "") ;
        this.index.setProps({
            label:"Mã khách hàng",
        });

        //Level: Cấp độ của người dùng, ảnh hưởng đến giới hạn cho vào giỏ hàng
        this.level = new InputField("level", 0) ;
        this.level.setProps({
            label:"Cấp độ",
        });
        let levelList = ["normal", "vip1", "vip2", "vip3"];
        this.level.convertToSelect(levelList);

        //Status: Trạng thái của người dùng, do admin đánh dấu và theo dõi hoạt động
        this.status = new InputField("status", 0) ;
        this.status.setProps({
            label:"Trạng thái",
        });
        let statusList = [];
        for(var i=0;i<5;i++) 
            statusList.push({key:i, label: Converter.getUserStatus(i)});
        this.status.convertToSelect(statusList);

        //Role: Vai trò của người dùng trên trang
        this.type = new InputField("type", 0) ;
        this.type.setProps({
            label:"Vai trò",
        });
        let typeList = ["admin", "customer", "editor"];
        this.type.convertToSelect(typeList);

        this.mapData();          
        this.addLockedField("index"); 
        this.addLockedField("address");
        this.addLockedField("avatar");
        this.addLockedField("money");
        this.addLockedField("name");
        this.addLockedField("phone");
        // this.addLockedField("email");
        this.addHiddenField("uid");
        this.addHiddenField("isLogin");
        this.addHiddenField("key");
        this.addHiddenField("order");
        this.addHiddenField("photoURL");
        this.addHiddenField("password");
        this.addHiddenField("retype-pass");
        this.addHiddenField("fid");
        this.addHiddenField("permission");
        this.addHiddenField("cash");
    }
}
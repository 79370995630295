import React, { useState, Fragment } from 'react';
import { BankAccount, useGetBanks, save } from './Bank.js';
import BankAccountItem from './BankAcountItem.js';
import { Row, Col, Button } from 'antd';
import EditableObject from '../components/EditableObject.js';
import {PlusOutlined} from '@ant-design/icons';

export default function BankScreen() {
    const [refresh, setRefresh] = useState(false);
    let bankInfo = useGetBanks(refresh);
    const [editingBank, setEditingBank] = useState(null);
    const [error, setError] = useState(null);

    const createNewBank = (data) => {
        let newBank = {key:bankInfo.length};
        newBank.stk=data.stk;
        newBank.logo=data.logo;
        newBank.name=data.name;
        newBank.chi_nhanh=data.chi_nhanh;
        newBank.owner=data.owner;
        newBank.visible=data.visible?data.visible:true;

        bankInfo.push(newBank);
        console.log("All new Banks: ", bankInfo);
        save(bankInfo, ()=>{setRefresh(true)}, (err)=>{setError(err)});

        setEditingBank(null);
    }


    const onUpdate=(newData)=>{
        let error=null;
        var editedBank = bankInfo.find((b)=>{if(b.key===newData.key) return true});
        if(editedBank) {
            editedBank.stk=newData.stk;
            editedBank.logo=newData.logo;
            editedBank.name=newData.name;
            editedBank.chi_nhanh=newData.chi_nhanh;
            editedBank.owner=newData.owner;
            editedBank.visible=newData.visible;
            console.log("All new Banks: ", bankInfo);
            save(bankInfo, ()=>{setRefresh(true)}, (err)=>{setError(err)});
        }
        return error;
    }

    const onDelete=(bank) => {
        console.log("Bank will be deleted: ", bank);
        console.log("All old banks: ", bankInfo);
        var newBankInfo = bankInfo.filter((b)=>{return b.key!==bank.key});
        console.log("Banks after delete: ", newBankInfo);
        save(newBankInfo, ()=>{setRefresh(true)}, (err)=>{setError(err)});
    }

    return (
        <Fragment>
            <h1>Cấu hình Thông Tin tài khoản hiển thị trên website</h1>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => { setEditingBank(new BankAccount()) }}>Thêm mới tài khoản</Button>

            <div style={{ display:"flex", flexDirection:"column", marginTop:"40px" }}>
                <span style={{ }}>Danh sách Các ngân hàng hiện tại </span>
                {
                    (bankInfo && Array.isArray(bankInfo)) ? bankInfo.map((acc) => {
                        return (
                            // acc.visible ?
                                <BankAccountItem data={acc} onUpdate={onUpdate} onDelete={onDelete}/>
                                // : null
                        )
                    }) : null

                }
            </div>

            <EditableObject
                input={editingBank}
                onCancel={() => { setEditingBank(null) }}
                onSubmit={createNewBank}
                error={error}
                submitButton="Thêm mới"
                title="Khai báo tài khoản ngân hàng mới" />

        </Fragment>
    );
}
import { useState, useEffect } from 'react';
import { fb, db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';
import * as NAME from '../../common/constant/name';
import { message } from 'antd';
import * as firebase from "firebase/app";
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import UIObject from '../../objects/UIObject';
import * as Converter from '../../common/helpers/Converter';

export default class BankAccount extends UIObject {
    constructor(data) {
        super(data);

        this.name = new InputField("name", "Bank's name");
        this.name.setProps({
            required: true,
            placeholder: "Hãy nhập tên ngân hàng",
            label: "Tên ngân hàng",
        });

        this.chi_nhanh = new InputField("chi_nhanh", "Hà Nội");
        this.chi_nhanh.setProps({
            required: true,
            placeholder: "Hãy nhập tên chi nhanh",
            label: "Chi nhánh",
        });

        this.stk = new InputField("stk", "");
        this.stk.setProps({
            required: true,
            placeholder: "Hãy nhập Số tài khoản",
            label: "Số tài khoản",
        });

        this.owner = new InputField("owner", "BLUE MOUNTAIN");
        this.owner.setProps({
            required: true,
            placeholder: "Hãy nhập Chủ tài khoản",
            label: "Chủ tài khoản",
        });

        this.logo = new InputField("logo", "");
        this.logo.setProps({
            label:"Logo",
            required:true,
        });
        this.logo.dataType=INPUT_TYPE.LOGO;

        this.visible = new InputField("owner", true);
        this.visible.setProps({
            label: "Công khai",
            defaultValue:true,
            required:true,
        });
        this.visible.dataType=INPUT_TYPE.SWITCH;

        this.mapData();
        this.addHiddenField("key");
    }

}

function useGetBanks(refreshData) {
    var [bankData, setBankData] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (bankData == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let bankRef = db.collection(COLLECTIONS.CONFIG).doc("bank");
        bankRef.get().then((doc) => {
            if (doc.exists) {
                setBankData([...doc.data().accounts ]);
                console.log("Bank data:", doc.data().accounts);
            }
            setRefresh(false);
        });
    }

    return bankData;
}

const save = (banks, onSuccess, onFail) => {
    try {
        //Save bank account info
        db.collection(COLLECTIONS.CONFIG).doc("bank").set({
            accounts: banks
        }).then((doc)=>{
            onSuccess && onSuccess();
        })

    } catch (error) {
        console.log(error);
        onFail && onFail(error.name);
    }
}

// const deleteBank = (bank, onSuccess, onFail) => {
//     try {
//         //Save bank account info
//         db.collection(COLLECTIONS.CONFIG).doc("bank").set({
//             accounts: banks
//         }).then((doc)=>{
//             onSuccess && onSuccess();
//         })

//     } catch (error) {
//         console.log(error);
//         onFail && onFail(error.name);
//     }
// }

export {
    BankAccount,
    useGetBanks,
    save
}

import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button, Col, InputNumber, Row, Input, message } from 'antd';
import { DollarCircleFilled } from '@ant-design/icons';
import { recharge, useGetMoneyHistory } from "../../hooks/Customers";
import { isMobile } from 'react-device-detect';
import * as Date from '../../common/helpers/Date';
import { convertPrice } from "../../common/helpers/Price";

const { TextArea } = Input;

export default function MoneyHistory(props) {
    const user = props.customer;

    const [showingModal, setShowingModal] = useState(false);
    const [history, setHistory] = useState([]);

    const moneyHistory = useGetMoneyHistory(user)

    useEffect(()=>{
        if(moneyHistory && Array.isArray(moneyHistory)) {
            setHistory(moneyHistory)
            console.log("MONEY HISTORY: ", moneyHistory)
        }
    }, [moneyHistory])

    const onCancel = () => {
        setShowingModal(false);
    }

    return (
        <div>
            <Fragment>
                <Button onClick={() => { setShowingModal(true) }} style={{ background: "orange", marginLeft: "5px", color: "white" }}><DollarCircleFilled /> Lịch sử</Button>
                <Modal
                    visible={showingModal}
                    title="Biến động số dư tài khoản"
                    onCancel={onCancel}
                    onOk={onCancel}
                    width={isMobile?"96%":"60%"}
                >
                    <Row>
                        <Col span={4}>Khách hàng: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.name} </Col>
                        <Col span={2}>&nbsp; </Col>
                        <Col span={4}>Số điện thoại: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.phone} </Col>
                    </Row>
                    <Row>
                        <Col span={4}>Số tiền hiện tại: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.money} VNĐ</Col>
                        <Col span={2}>&nbsp; </Col>
                        <Col span={4}>Địa chỉ: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.address} </Col>
                    </Row>
                    <hr />
                    <Row style={{fontSize:"9pt"}}>
                        <Col span={3} style={{fontWeight:"bold"}}>Loại thay đổi </Col>
                        <Col span={9} style={{fontWeight:"bold"}}>Ghi chú </Col>
                        <Col span={3} style={{fontWeight:"bold"}}>Số dư Trước đó</Col>
                        <Col span={3} style={{fontWeight:"bold"}}>Số tiền thay đổi</Col>
                        <Col span={3} style={{fontWeight:"bold"}}>Số dư Sau đó</Col>
                        <Col span={3} style={{fontWeight:"bold"}}>Ngày</Col>
                    </Row>
                    {
                        user && user.money_history?<div>{
                            user.money_history.map((item, key)=>{
                            return (
                                <Row key={key}>
                                    <Col span={3}>{item.exchange_type}</Col>
                                    <Col span={9}>{item.content}</Col>
                                    <Col span={3}>{convertPrice(item.before)}</Col>
                                    <Col span={3} style={{fontWeight:"bold", color:`${item.exchange_type==="Nạp tiền"?"green":"red"}`}}>{`${item.exchange_type==="Nạp tiền"?"+":"-"}`}{convertPrice(item.amount)}</Col>
                                    <Col span={3}>{convertPrice(item.after)}</Col>
                                    <Col span={3}>{Date.getSimpleDateTime(item.created.seconds)}</Col>
                                </Row>
                            )
                        })}
                        </div>
                        :null
                    }
                </Modal>
            </Fragment>
        </div>
    );
}
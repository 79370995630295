import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import EditableCell, { EditableContext } from '../components/EditalbeCell';
import { Table, Tag, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Upload, message, Spin, DatePicker, Select } from 'antd';
import { IdcardFilled, PhoneFilled, EyeFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext } from '../../App';
import Order from '../../objects/Order';
import * as Converter from '../../common/helpers/Converter';
import * as Date from '../../common/helpers/Date';
import Price from '../components/Price';
import { useGetOrders } from '../../hooks/Customers';
import moment from 'moment';
import * as firebase from "firebase/app";
import * as NAME from '../../common/constant/name';
import SearchField from "react-search-field";
import { convertPrice } from "../../common/helpers/Price";
import { isMobile } from 'react-device-detect';

const { Option } = Select;

function OrderScreen(props) {
    const [editingOrder, setEditingOrder] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [modalDetail, setModalDetail] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [hasChangedPartStatus, setHasChangedPartStatus] = useState(false);

    const [newStatus, setNewStatus] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState(null);

    const orders = useGetOrders(searchText, 100, refreshData);

    const columns = isMobile ? [
        {
            title: 'Đơn hàng',
            dataIndex: 'userName',
            key: 'userName',
            width: "55%",
            render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "55vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                <b>{text}</b> <br />
                {record.key} <br />
                {Date.getDateTime(record.created.seconds)} <br />
            </a>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: "15%",
            render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "15vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{Converter.getOrderStatus(text)}</a>
        },
        {
            title: 'Tổng (VNĐ)',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: "30%",
            render: (text, record) => <a onClick={() => viewOrder(record)} style={{ textAlign: "right", maxWidth: "30vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                <b>{convertPrice(record.totalPrice)}</b>
            </a>
        },
    ]
        :
        [
            {
                title: 'Tên Khách hàng',
                dataIndex: 'userName',
                key: 'userName',
                width: "15%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
            },
            {
                title: 'Mã đơn hàng',
                dataIndex: 'key',
                key: 'key',
                width: "15%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
            },
            {
                title: 'Tình trạng',
                dataIndex: 'status',
                key: 'status',
                width: "13%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{Converter.getOrderStatus(text)}</a>
            },
            {
                title: 'Ngày đặt',
                dataIndex: 'created',
                key: 'created',
                width: "15%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{Date.getDateTime(text.seconds)}</a>
            },
            {
                title: 'Đặt cọc',
                dataIndex: 'deposite',
                key: 'deposite',
                width: "12%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{convertPrice(text)}</a>
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                width: "15%",
                render: (text, record) => <a onClick={() => viewOrder(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{convertPrice(text)}</a>
            },
            {
                title: 'Action',
                dataIndex: 'action',
                width: "15%",
                render: (text, record) => (
                    <div>
                        <Button onClick={() => viewOrder(record)} type="primary">
                            <EyeFilled />
                        Xem chi tiết
                    </Button>
                    </div>
                ),
            },
        ];

    const cancel = () => {
        setModalDetail(false);
        setDeliveryDate(null);
        setNewStatus(0);
        setEditingOrder(null);

    };

    const viewOrder = (order) => {
        console.log("Edit Order: ", order);
        setNewStatus(order.status);
        setDeliveryDate(order.delivery_date ? moment(order.delivery_date.seconds * 1000) : null);
        setEditingOrder(order);
        setRefreshData(false);
        setModalDetail(true);
    }

    const setNewStatusPart = async (e, item) => {
        setRefreshData(false);
        if (item && editingOrder && editingOrder.items) {
            var newOrderData = { ...editingOrder };
            var foundOrderPart = null;
            var orderPart;
            for (orderPart of editingOrder.items) {
                console.log("orderPart: ", orderPart);
                console.log("item: ", item);
                if (orderPart.code === item.code) {
                    foundOrderPart = orderPart;
                    console.log("FOUND PART CODE");
                    orderPart.status = e;
                    setHasChangedPartStatus(true);
                }
            }
            console.log("New Order STATUS: ", editingOrder.items);
            if (foundOrderPart) {
                try {
                    newOrderData.canPayMore = ((e == 4 || editingOrder.canPayMore) ? true : false);
                    newOrderData.items = editingOrder.items;
                    await db.collection(COLLECTIONS.ORDER).doc(editingOrder.key).update({ ...newOrderData });

                    //Add new notification
                    db.collection(COLLECTIONS.USER).doc(editingOrder.uid).collection(COLLECTIONS.SUB_NOTI).add({
                        type: NAME.NOTI_TYPE.ORDER_STATUS_CHANGE,
                        read: false,
                        created: firebase.firestore.Timestamp.now(),
                        target: editingOrder.key,
                        content: "Sản phẩm [" + item.key + "] đã chuyển sang trạng thái '" + Converter.getOrderStatus(e) + "'",
                    })

                    //Update current client data
                    setEditingOrder(newOrderData);
                    const index = orders.findIndex(item => newOrderData.key === item.key);
                    if (index > -1) {
                        orders.splice(index, 1, newOrderData);
                    } else {
                        orders.push(newOrderData);
                    }

                    setRefreshData(true);
                } catch (error) {
                    //Recover old data
                    console.log('error', error);
                }
            }
        }
    }

    const updateOrder = async (orderData) => {
        setHasChangedPartStatus(false);
        let newData = {};
        if (newStatus != orderData.status) {
            newData.status = parseInt(newStatus);
            orderData.status = parseInt(newStatus);
        }
        if (deliveryDate != null && (editingOrder.delivery_date == null || moment(deliveryDate).toDate().getDate() != moment(editingOrder.delivery_date.seconds * 1000).toDate().getDate())) {
            orderData.delivery_date = firebase.firestore.Timestamp.fromDate(moment(deliveryDate).toDate());
            newData.delivery_date = orderData.delivery_date;
            console.log('new Delivery Date: ', newData.delivery_date);
        }


        //Save to server
        try {
            await db.collection(COLLECTIONS.ORDER).doc(orderData.key).update(newData);

            //Add new notification
            db.collection(COLLECTIONS.USER).doc(orderData.uid).collection(COLLECTIONS.SUB_NOTI).add({
                type: NAME.NOTI_TYPE.ORDER_STATUS_CHANGE,
                read: false,
                created: firebase.firestore.Timestamp.now(),
                target: orderData.key,
                content: "Đơn hàng " + orderData.key.substr(0, 10) + " đã thay đổi trạng thái",
            })

            //Update curent client data
            const index = orders.findIndex(item => orderData.key === item.key);
            if (index > -1) {
                orders.splice(index, 1, orderData);
            } else {
                orders.push(orderData);
            }
            setRefreshData(true);
        } catch (error) {
            //Recover old data
            console.log('error', error);
        }

        setIsLoading(false);
        setEditingOrder(null);
        setModalDetail(false);
    }

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    const onChangeDeliveryDate = (date, dateString) => {
        setDeliveryDate(date);
    }

    const dataChanged =
        (
            !hasChangedPartStatus
            && editingOrder
            && newStatus == editingOrder.status
            && (deliveryDate == null || (editingOrder.delivery_date && moment(deliveryDate).toDate().getDate() == moment(editingOrder.delivery_date.miliseconds).toDate().getDate()))
        ) ? false : true;

    if (editingOrder) {
        console.log("value: ", newStatus + " - Origin: " + editingOrder.status);
        console.log("data changed: ", dataChanged);
    }


    const payForReadyItem = async (currentOrder) => {
        
        setRefreshData(false);
        var orderItem = { ...currentOrder };
        const uid = orderItem.uid;
        var payResult = {
            result: false,
            message: "Có lỗi xảy ra trong quá trình thanh toán, hãy thử lại sau.",
            errorCode: 0,
        }
        if (orderItem.hasOwnProperty("canPayMore") && orderItem.canPayMore == false)
            return payResult;

        var totalPrice = 0;

        //Found ready items => Pay for them
        var paidItems = [];
        orderItem.items.forEach(item => {
            var paidItem = { ...item };
            if (item.status >= 4 && ((item.hasOwnProperty("paid") && item.paid == false) || item.hasOwnProperty("paid") == false)) {
                //This item has not been already paid => Mark it's paid
                totalPrice += item.count * (item.price_brand ? item.price_brand : item.price_retail) / 2; //Count money to paid
                paidItem.status = 5;//Đang giao hàng
                paidItem.paid = true;
            }
            paidItems.push(paidItem);
        })

        //Check total money to pay not greater than total price of order
        if (totalPrice > (orderItem.totalPrice - orderItem.deposite)) {
            totalPrice = orderItem.totalPrice - orderItem.deposite;
            orderItem.status = 5; //Đang giao hàng
        }

        //Update curent client data
        const index = orders.findIndex(item => orderItem.key === item.key);
        if (index > -1) {
            orders.splice(index, 1, orderItem);
        } else {
            orders.push(orderItem);
        }
        orderItem.deposite = orderItem.deposite + totalPrice;
        orderItem.items = [...paidItems];
        orderItem.canPayMore = false;
        setEditingOrder(orderItem);
        //Update order item on Server
        const addStatus = await db.collection(COLLECTIONS.ORDER).doc(orderItem.key).update(orderItem);

        //Try to find user who order
        const userData = await db.collection(COLLECTIONS.USER).doc(uid).get();
        if (userData.exists) {
            let user = { key: userData.id, ...userData.data() }

            const oldMoney = user.money ? user.money : 0;
            const newMoney = oldMoney - totalPrice;

            //Save new user's moneyx
            await db.collection(COLLECTIONS.USER).doc(uid).update({
                money: newMoney
            }).then((data) => {
                //Update user money (client)
                user.money = newMoney;
            }).catch(function (error) {
                console.log("Error: ", error);
                payResult = {
                    result: true,
                    message: "Không cập nhật được tiền sau thanh toán cho khách",
                    errorCode: 2,
                }

            });

            //Update user's money exchange history
            var money_history = {
                exchange_type: NAME.EXCHANGE_TYPE.PAY,
                amount: totalPrice,
                created: firebase.firestore.Timestamp.now(),
                before: oldMoney,
                after: newMoney,
                content: "Thanh toán hàng mới về của đơn hàng: " + orderItem.key.substr(0, 10),
                order_id: orderItem.key,
            };
            var old_history = user.money_history ? user.money_history : [];
            await db.collection(COLLECTIONS.USER).doc(uid).update({
                money_history: [money_history, ...old_history],
            })
        }

        //Notify to Admin
        await db.collection(COLLECTIONS.ADMIN_NOTI).add({
            userName: orderItem.userName,
            uid: uid,
            type: NAME.ADMIN_NOTI_TYPE.PAID,
            objectId: orderItem.key,
            message: "Đã thanh toán thêm " + convertPrice(totalPrice) + " VND cho đơn hàng " + orderItem.key.substr(0, 10),
            created: firebase.firestore.Timestamp.now()
        }).then((data) => {
        }).catch(function (error) {
            console.log("Error: ", error);
            payResult = {
                result: true,
                message: "Không ghi được thông báo cho admin",
                errorCode: 3,
            }
            return payResult;
        });

        payResult = {
            result: true,
            message: "Thanh toán cho khách thành công",
            errorCode: 0,
        }
        console.log('====== START REFRESH DATA =========', payResult);
        setRefreshData(true);
        return payResult;
    }

    const PayComponent = (props) => {
        const orderItem = props.item;
        console.log("==== Order Item: ", orderItem);
        var canPay = false;
        if (orderItem.hasOwnProperty("canPayMore") == false || (orderItem.hasOwnProperty("canPayMore") && orderItem.canPayMore)) {
            orderItem.items.map(item => {
                if (item.status >= 4 && ((item.hasOwnProperty("paid") && item.paid == false) || item.hasOwnProperty("paid") == false)) //Stored (Đã về kho)
                    canPay = true;
            });
        }
        console.log("CANPAY FOR item: ", canPay);

        return (
            <Button
                disabled={!canPay}
                onClick={() => payForReadyItem(editingOrder)}
                type={canPay ? 'primary' : 'default'}
            >
                THANH TOÁN HỘ KHÁCH
            </Button>
        );
    }

    const dataSource = Search.searchInObject(orders, searchText);

    return (
        <div>
            <h1>Quản lý đơn hàng</h1>

            <div style={{ position: "relative" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Danh sách Đơn Hàng </span>
                    <SearchField
                        placeholder="Search..."
                        onChange={(e) => setSearchText(e)}
                        placeholder="Tìm Đơn hàng"
                        searchText=""
                    />
                </div>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource != null ? dataSource : []}
                    loading={dataSource == null}
                    // dataSource={orders != null ? orders : []}
                    // loading={orders == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key)}
                />
            </div>

            <Modal
                title="Chi tiết đơn hàng"
                centered
                width='80%'
                visible={modalDetail}
                onCancel={cancel}
                footer={
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        {error}
                    </div>
                }
            >
                {
                    editingOrder ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", flex: 1 }}>
                            <Row>
                                <Col md={8} xs={24} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ height: "45px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        {editingOrder.key.substr(0, 10)}
                                    </div>
                                </Col>
                                <Col md={8} xs={24} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        <IdcardFilled />
                                        {editingOrder.userName}
                                    </div>
                                </Col>
                                <Col md={4} xs={24}>
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        <PhoneFilled />
                                        {editingOrder.phone}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "30px" }}>
                                <Col md={8} xs={24}>
                                    <div style={{ display: "flex", flexDirection: "column", height: "50px" }}>
                                        <span style={{ flex: 5, fontSize: "12px", color: "black", fontStyle: "italic" }}>Ngày đặt:</span>
                                        <span style={{ flex: 4, fontSize: "11px", color: "gray" }}>{Date.getTime(editingOrder.created.seconds)}</span>
                                        <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{Date.getDate(editingOrder.created.seconds)}</span>
                                    </div>
                                </Col>
                                <Col md={8} xs={24}>
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        {
                                            (editingOrder.totalPrice - editingOrder.deposite <= 0) ?
                                                <span style={{ color: "darkgreen" }}>Đã thanh toán thành công</span>
                                                : <span style={{ color: "orange" }}>Đơn hàng đang xử lý</span>
                                        }
                                    </div>
                                </Col>
                                <Col md={8} xs={24}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ flex: 4 }}>Ngày dự kiến giao hàng:</div>
                                        <div style={{ flex: 5 }}>
                                            {
                                                editingOrder.delivery_date ?
                                                    <DatePicker defaultValue={moment(editingOrder.delivery_date.seconds * 1000)} onChange={onChangeDeliveryDate} />
                                                    : <DatePicker onChange={onChangeDeliveryDate} />
                                            }

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ fontSize: "12px", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                                <Col span={3}>Code</Col>
                                <Col span={7}>Tên</Col>
                                <Col span={5} style={{ textAlign: "right" }}>Giá (VNĐ)</Col>
                                <Col span={3} style={{ textAlign: "right" }}>Số lượng</Col>
                                <Col span={6} style={{ textAlign: "right" }}>Tình trạng</Col>
                            </Row>
                            {
                                (editingOrder && editingOrder.items && Array.isArray(editingOrder.items) && editingOrder.items.length > 0)
                                    ?
                                    editingOrder.items.map((item) => {
                                        return (
                                            <Row key={item.code} style={{ fontSize: "12px", marginBottom: "6px", paddingTop: "4px", paddingBottom: "3px" }}>
                                                <Col span={3} style={{ fontWeight: "bold" }}>{item.code}</Col>
                                                <Col span={7} style={{ fontSize: "13px", fontWeight: "bold" }}>{item.des_en}</Col>
                                                <Col span={5} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right"><Price price={item.price_brand} color="black" /></Col>
                                                <Col span={3} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right">{item.count}</Col>
                                                <Col span={6} style={{ color: "green", textAlign: "right" }}>
                                                    <Select defaultValue={(item && item.status) ? item.status.toString() : "0"} style={{ width: "80%", flex: 5, marginBottom: "10px" }} onChange={(e) => { setNewStatusPart(parseInt(e), item) }}>
                                                        <Option value="0">Mới</Option>
                                                        <Option value="1">Đang xử lý</Option>
                                                        <Option value="2">Đã đặt hàng</Option>
                                                        <Option value="3">Chờ hàng về VN</Option>
                                                        <Option value="4">Đang nhập kho</Option>
                                                        <Option value="5">Đang giao hàng</Option>
                                                        <Option value="6">Đã nhận hàng</Option>
                                                        <Option value="7">Khách hàng hủy đơn</Option>
                                                        <Option value="8">Từ chối đơn</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    : <Spin style={{ margin: "5px" }} size="small" />
                            }
                            <Row style={{ fontSize: "13px", marginTop: "30px" }}>
                                <Col md={8} xs={12}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>Tổng giá trị đơn hàng: </span>
                                        <span style={{ fontStyle: "italic" }}>Đã thanh toán: </span>
                                        <span style={{ fontStyle: "italic" }}>Còn lại: </span>
                                    </div>
                                </Col>
                                <Col md={4} xs={12}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "end", fontWeight: "bold" }}>
                                        <Price price={editingOrder.totalPrice} color="red" style={{ fontSize: "14px" }} />
                                        <Price price={editingOrder.deposite} color="green" />
                                        <Price price={editingOrder.totalPrice - editingOrder.deposite} color="orange" />
                                    </div>
                                </Col>
                                <Col md={12} xs={24}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
                                        <PayComponent item={editingOrder} />
                                        <span style={{ flex: 4 }}>Tình trạng hiện tại</span>
                                        <Select defaultValue={editingOrder.status.toString()} style={{ width: "80%", flex: 5, marginBottom: "10px" }} onChange={(e) => { setNewStatus(parseInt(e)) }}>
                                            <Option value="0">Mới</Option>
                                            <Option value="1">Đang xử lý</Option>
                                            <Option value="2">Đã đặt hàng</Option>
                                            <Option value="3">Chờ hàng về VN</Option>
                                            <Option value="4">Đang nhập kho</Option>
                                            <Option value="5">Đang giao hàng</Option>
                                            <Option value="6">Đã nhận hàng</Option>
                                            <Option value="7">Khách hàng hủy đơn</Option>
                                            <Option value="8">Từ chối đơn</Option>
                                        </Select>
                                        <Button style={{ flex: 8 }} type="primary" onClick={() => updateOrder(editingOrder)} disabled={!dataChanged}>CẬP NHẬT</Button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : <Spin />
                }
            </Modal>
        </div>
    );
}

// const OrderScreen = Form.create({ name: 'class' })(OrderForm);
export default OrderScreen;
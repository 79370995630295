import React, { useContext, useState, useEffect } from 'react';
import SearchField from "react-search-field";
import { Select, Row, Col } from 'antd';
import { UserContext, BrandContext, BikeContext, ClassContext, GroupContext } from '../../App';
import { fb, db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';

const { Option } = Select;

export default function SearchClassComponent(props) {
    const limit = props.limit ? props.limit : 100;
    const onResult = props.onResult;
    const onFilter = props.onFilter;

    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);

    // var [bikeClasses, setBikeClasses] = useState(allClasses);
    var [bikes, setBikes] = useState(allBikes);
    var [selectedBike, setSelectedBike] = useState('-');
    var [selectedBrand, setSelectedBrand] = useState('-');
    var [keyword, setKeyword] = useState('');

    // useEffect(() => {
    //     if (allBrands && allBrands.length > 0) {
    //         setBrands(allBrands);
    //     }
    // }, [allBrands]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            setBikes(allBikes);
        }
    }, [allBikes]);

    useEffect(() => {
        var classRef = db.collectionGroup(COLLECTIONS.SUB_CLASS);
        if (selectedBrand !== '-' && selectedBike !== '-') {
            classRef = classRef.where("brand", "==", selectedBrand);
            classRef = classRef.where("bike", "==", selectedBike);
            onResult && onResult(null);

            classRef.limit(limit).get().then((docs) => {
                let results = [];
                docs.forEach((doc) => {
                    if (doc.exists) {
                        var validWord = true;
                        if (keyword && doc.data().name.toUpperCase().indexOf(keyword.toUpperCase()) <= -1)
                            validWord = false;

                        if (validWord)
                            results.push({ key: doc.id, ...doc.data() });
                    }
                })
                onResult && onResult(results);
                console.log("FILTER CLASS RESULTS: ", results);
            });
        }

    }, [selectedBike, keyword])

    const onEnter = (text) => {
        setKeyword(text);
    }

    const selectBrand = (brand) => {
        if (brand !== '-' && allBikes && allBikes.length > 0) {
            setBikes(allBikes.filter(bike => {
                if (bike && (bike.brand.toUpperCase().indexOf(brand.toUpperCase()) > -1))
                    return true;
                else return false;
            }));
        }
        setSelectedBrand(brand);
    }
    const selectBike = (bike) => {
        // if (bike === 'all') {
        //     setBikeClasses(allClasses);
        // } else if (allClasses && allClasses.length > 0) {
        //     setBikeClasses(allClasses.filter(bikeClass => {
        //         if (bikeClass && (bikeClass.bike.toLowerCase().indexOf(bike.toLowerCase()) > -1))
        //             return true;
        //         else return false;
        //     }));
        // }
        setSelectedBike(bike);
    }

    // const selectGroup = (group) => {
    //     // setBikes(bikes.filter(bike => {
    //     //     if ((bike.brand.toLowerCase().indexOf(group.toLowerCase()) > -1))
    //     //         return true;
    //     //     else return false;
    //     // }));
    //     setSelectedGroup(group);
    // }

    const filterClient = (text) => {
        onFilter && onFilter(text);
    }


    return (
        <Row>
            <Col span={5} style={{ paddingRight: "10px" }}>
                <Select
                    defaultValue={selectedBrand}
                    style={{ width: "100%" }}
                    onChange={selectBrand}
                >
                    <Option key="-">Trống</Option>
                    {allBrands.map(brand => (
                        <Option key={brand.key}>{brand.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={10} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedBike}
                    onChange={selectBike}
                >
                    <Option key="-">Hãy chọn 1 loại xe</Option>
                    {bikes.map(bike => (
                        <Option key={bike.key}>{bike.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={9}>
                <SearchField
                    style={{ width: "100%" }}
                    placeholder="Search..."
                    onChange={(e) => filterClient(e)}
                    placeholder="Nhập tên phiên bản"
                    searchText=""
                    onEnter={(e) => onEnter(e)}
                    onSearchClick={(e) => onEnter(e)}
                />
            </Col>
        </Row>
    );
}

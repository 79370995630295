import React, { useContext, useState, useEffect } from 'react';
import SearchField from "react-search-field";
import { Select, Row, Col } from 'antd';
import { UserContext, BrandContext, BikeContext, ClassContext, GroupContext } from '../../App';
import { fb, db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';
import { useGetClass } from '../../hooks/PartHook';

const { Option } = Select;

export default function SearchGroupComponent(props) {
    const limit = props.limit ? props.limit : 100;
    const onResult = props.onResult;
    const onFilter = props.onFilter;

    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    // const allClasses = useContext(ClassContext);

    // var [bikeClasses, setBikeClasses] = useState(allClasses);
    var [bikes, setBikes] = useState(allBikes);

    var [selectedClass, setSelectedClass] = useState('-');
    var [selectedBike, setSelectedBike] = useState('-');
    var [selectedBrand, setSelectedBrand] = useState('-');
    var [keyword, setKeyword] = useState('');

    var allClasses = useGetClass(selectedBrand, selectedBike);

    // useEffect(() => {
    //     if (allBrands && allBrands.length > 0) {
    //         setBrands(allBrands);
    //     }
    // }, [allBrands]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            setBikes(allBikes);
        }
    }, [allBikes]);

    useEffect(() => {
        if (selectedBrand !== '-' && selectedBike !== '-' && selectedClass !== '-') {
            // var groupPath = "/" + COLLECTIONS.BRAND + "/" + selectedBrand
            //     + "/" + COLLECTIONS.SUB_BIKE + "/" + selectedBike
            //     + "/" + COLLECTIONS.SUB_CLASS + "/" + selectedClass
            //     + "/" + COLLECTIONS.SUB_GROUP;
            // var groupRef = db.collectionGroup(groupPath);
            var groupRef = db.collectionGroup(COLLECTIONS.SUB_GROUP);
            onResult && onResult(null);

            groupRef = groupRef.where("brand", "==", selectedBrand);
            groupRef = groupRef.where("bike", "==", selectedBike);
            groupRef = groupRef.where("bikeClass", "==", selectedClass.replace('_','/'));
            console.log("== brand: ", selectedBrand);
            console.log("== bike: ", selectedBike);
            console.log("== class: ", selectedClass);

            groupRef.limit(limit).get().then((docs) => {
                let results = [];
                docs.forEach((doc) => {
                    if (doc.exists) {
                        console.log("== Group: ", doc.data());
                        var validWord = true;
                        if (keyword && doc.data().name.toUpperCase().indexOf(keyword.toUpperCase()) <= -1)
                            validWord = false;

                        if (validWord)
                            results.push({ key: doc.id, ...doc.data() });
                    }
                })
                onResult && onResult(results);
                console.log("FILTER GROUP RESULTS: ", results);
            });
        }

    }, [selectedClass, keyword])

    const onEnter = (text) => {
        setKeyword(text);
    }

    const selectBrand = (brand) => {
        if (brand !== '-' && allBikes && allBikes.length > 0) {
            setBikes(allBikes.filter(bike => {
                if (bike && (bike.brand.toUpperCase().indexOf(brand.toUpperCase()) > -1))
                    return true;
                else return false;
            }));
        }
        setSelectedBrand(brand);
    }
    const selectBike = (bike) => {
        if (bike !== '-' && allClasses && allClasses.length > 0) {
            setSelectedBike(bike);
        }
        setSelectedBike(bike);
    }
    const selectClass = (bikeClass) => {
        // if (bikeClass === 'all') {
        //     setGroups(allGroups);
        // } else if (allGroups && allGroups.length > 0) {
        //     setGroups(allGroups.filter(group => {
        //         // console.log("BIke class: ", group);
        //         if (group && group.bikeClass && Array.isArray(group.bikeClass) && group.bikeClass.length == 3 && (group.bikeClass[2].toLowerCase().indexOf(bikeClass.toLowerCase()) > -1))
        //             return true;
        //         else if (group && typeof group.bikeClass === "string" && (group.bikeClass.toLowerCase().indexOf(bikeClass.toLowerCase()) > -1))
        //             return true;
        //         else return false;
        //     }));
        // }
        setSelectedClass(bikeClass);
    }

    const filterClient = (text) => {
        onFilter && onFilter(text);
    }


    return (
        <Row>
            <Col span={5} style={{ paddingRight: "10px" }}>
                <Select
                    defaultValue={selectedBrand}
                    style={{ width: "100%" }}
                    onChange={selectBrand}
                >
                    <Option key="-">Trống</Option>
                    {allBrands.map(brand => (
                        <Option key={brand.key}>{brand.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={6} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedBike}
                    onChange={selectBike}
                >
                    <Option key="-">Hãy chọn 1 loại xe</Option>
                    {bikes.map(bike => (
                        <Option key={bike.key}>{bike.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={6} style={{ paddingRight: "10px" }}>
                <Select
                    style={{ width: "100%" }}
                    value={selectedClass}
                    onChange={selectClass}
                >
                    <Option key="-">Hãy chọn 1 phiên bản</Option>
                    {allClasses ? allClasses.map(bikeClass => (
                        <Option key={bikeClass.key}>{bikeClass.name}</Option>
                    )) : null}
                </Select>
            </Col>
            <Col span={7}>
                <SearchField
                    style={{ width: "100%" }}
                    placeholder="Search..."
                    onChange={(e) => filterClient(e)}
                    placeholder="Nhập tên nhóm phụ tùng"
                    searchText=""
                    onEnter={(e) => onEnter(e)}
                    onSearchClick={(e) => onEnter(e)}
                />
            </Col>
        </Row>
    );
}

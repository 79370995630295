import React, { useState, useContext, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as COLLECTIONS from '../../common/constant/collections';
import { db } from '../../common/firebase/Firestore';
import EditableObject from '../components/EditableObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import { BrandContext } from '../../App';
import Brand from '../../objects/Brand';
import Bike from '../../objects/Bike';
import SearchField from "react-search-field";
import { Icon, Button, Col, Modal, Input, Upload } from 'antd';
import Part from '../../objects/Part';
import {PlusOutlined} from '@ant-design/icons';

export default function NewPartComponent(props) {
    const groupOptions = props.groupOptions;
    var [newPart, setNewPart] = useState(null);
    var [parts, setParts] = useState(props.groups);
    var [selectedGroup, setSelectedGroup] = useState("");
    var [selectedClass, setSelectedClass] = useState("");
    var [selectedBike, setSelectedBike] = useState("");
    var [selectedBrand, setSelectedBrand] = useState("");
    var [error, setError] = useState(" ");
    var [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        if (groupOptions && groupOptions.length > 0) {
            var firstOption = groupOptions[0];
            if (firstOption.value) {
                setSelectedBrand(groupOptions[0].value);
            }
            if (firstOption.chidren && Array.isArray(firstOption.chidren) && firstOption.chidren[0] && firstOption.chidren[0].value) {
                var bike = firstOption.chidren[0];
                setSelectedBike(bike.value);

                if (bike.chidren && Array.isArray(bike.chidren) && bike.chidren[0] && bike.chidren[0].value) {
                    var bikeClass = bike.chidren[0];
                    setSelectedClass(bike.chidren[0].value);

                    if (bikeClass.chidren && Array.isArray(bikeClass.chidren) && bikeClass.chidren[0] && bikeClass.chidren[0].value) {
                        setSelectedGroup(bikeClass.chidren[0].value);
                        getAllPartInGroup(firstOption.value, bike.value, bikeClass.value, bikeClass.chidren[0].value);
                    }
                }
            }
        }
    }, [groupOptions]);

    const getAllPartInGroup = (brand, bike, bikeClass, group) => {
        console.log("Selected brand", brand);
        console.log("Selected bike", bike);
        console.log("Selected class", bikeClass);
        console.log("Selected group", group);
        db.collection(COLLECTIONS.BRAND).doc(brand)
            .collection(COLLECTIONS.SUB_BIKE).doc(bike)
            .collection(COLLECTIONS.SUB_CLASS).doc(bikeClass)
            .collection(COLLECTIONS.SUB_GROUP).doc(group).get().then((docs) => {
                let listPart = [];
                if (docs.exists && docs.length>0) {
                    docs.forEach((doc) => {
                        if (doc.exists) {
                            const part = {
                                key: doc.id,
                                ...doc.data()
                            };
                            listPart.push(part);
                        }
                    });
                }
                setParts(listPart);
                console.log("List part", listPart);
            });
    }

    const onChange = (data) => {
        if (data.group && Array.isArray(data.group)) {
            console.log("Change group data: ", data.group);
            if (data.group.length > 0 && data.group[0] != selectedBrand)
                setSelectedBrand(data.group[0]);

            if (data.group.length > 1 && data.group[1] != selectedBike)
                setSelectedBike(data.group[1]);

            if (data.group.length > 2 && data.group[2] != selectedClass)
                setSelectedClass(data.group[2]);

            if (data.group.length == 4 && data.group[3] != selectedGroup) {
                console.log("Selected class: ", selectedGroup);
                setSelectedGroup(data.group[3]);

                getAllPartInGroup(data.group[0], data.group[1], data.group[2], data.group[3]);
            }
        }
    }

    const createNewPart = async (inputData) => {
        if (!inputData) {
            setError("Dữ liệu phụ tùng đang trống");
            return false;
        }
        try {
            setIsLoading(true);
            var newPartData = inputData;

            //Check new brand is existed
            if (!selectedBrand) {
                setError("Bạn phải chọn thương hiệu trước");
            } else if (!selectedBike) {
                setError("Bạn phải chọn đời xe cho nhóm phụ tùng mới");
            } else if (!selectedClass) {
                setError("Bạn phải chọn phiên bản xe");
            } else if (!selectedGroup) {
                setError("Bạn phải chọn Nhóm phụ tùng");
            } else if (!parts || parts.length == 0 || parts.filter(b => { return b.name.toLowerCase() == newPartData.name.toLowerCase() }).length == 0) {
                //Fill brand & bike property
                newPartData.brand = selectedBrand;
                newPartData.bike = selectedBike;
                newPartData.bikeClass = selectedClass;
                newPartData.group_id = selectedGroup;

                //Brand not exists, create new brand
                var subPartRef = db.collection(COLLECTIONS.BRAND).doc(`${selectedBrand}`)
                    .collection(COLLECTIONS.SUB_BIKE).doc(`${selectedBike}`)
                    .collection(COLLECTIONS.SUB_CLASS).doc(`${selectedClass}`)
                    .collection(COLLECTIONS.SUB_GROUP).doc(`${selectedGroup}`)
                    .collection(COLLECTIONS.SUB_PART);
                await subPartRef.add(newPartData)
                    .then((doc) => {
                        setNewPart(null);
                        setParts([...parts, newPartData]);
                        setError(" ");
                        newPartData.key = doc.id;
                        subPartRef.doc(newPartData.key).update({ key: newPartData.key });
                    }).catch((error) => {
                        setError("Error write new part: " + error);
                        return false;
                    });
                return true;
            } else {
                //Show message to notify that brands is existed
                setError("Part's name [" + newPartData.name + "] is existed");
            }
        } catch (error) {
            setError(error.name);
        }
    };

    const openAddNewDialog = () => {
        var partObj = new Part();
        partObj.setGroups(groupOptions);
        setNewPart(partObj);
    }

    return (
        <div style={{ marginBottom: "10px" }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddNewDialog}>Thêm mới</Button>
            </div>

            <EditableObject
                input={newPart}
                onSubmit={createNewPart}
                error={error}
                submitButton="Thêm mới Phụ tùng"
                title="Thêm mới phụ tùng"
                onChange={onChange} />

        </div>
    );
}
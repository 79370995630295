import moment from 'moment';

export const getDateTime = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY H:mm:ss');
}

export const getDate = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY');
}

export const getTime = (timestamp) => {
    return moment.unix(timestamp).format('H:mm:ss');
}

export const getSimpleDateTime = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM H:mm');
}

import React, { useState, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import { db } from '../../common/firebase/Firestore';
import { Form, Button, Col, Modal, Input, Upload, Select, InputNumber } from 'antd';
import { LoadingOutlined, PlusSquareTwoTone } from '@ant-design/icons';
import * as InputObject from '../../objects/InputObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import 'antd/dist/antd.css';
const { Option } = Select;

function InputFormByObject(props) {
    const [form] = Form.useForm();
    // var dataSource = props.source;
    const submit_label = props.submitButton ? props.submitButton : "Submit";
    var onChange = props.onChange;
    var onSubmit = props.onSubmit;
    var onFinish = props.onFinish;
    var uiObject = props.input;
    var [inputUI, setInputUI] = useState(uiObject.inputUI());
    var [data, setData] = useState(uiObject.data());
    var [disable, setDisable] = useState(false);
    var [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        if (uiObject) {
            console.log("Set initvalues: ", uiObject.getOriginData());
            form.setFieldsValue(uiObject.getOriginData());
        }
        else
            form.setFieldsValue({});
    }, [uiObject]);

    const onChangeData = (key, value) => {
        console.log("On change Data value: ", value);
        var newData = data;
        newData[key] = value;
        console.log("New Data: ", newData);
        if (uiObject) uiObject.onChange(newData);
        onChange && onChange(newData);
        setData(newData);
    }

    const basicProps = (key, fProps) => {
        var mappedProps = {};
        mappedProps.label = fProps ? (fProps.label ? fProps.label : key) : key;
        mappedProps.placeholder = fProps ? (fProps.placeholder ? fProps.placeholder : key) : key;
        mappedProps.required = fProps ? (fProps.required ? fProps.required : false) : false;
        mappedProps.style = fProps ? (fProps.style ? fProps.style : {}) : {};
        mappedProps.default_require_message = "Please input " + mappedProps.label;
        mappedProps.require_message = fProps ? (fProps.require_message ? fProps.require_message : mappedProps.default_require_message) : mappedProps.default_require_message;
        mappedProps.rules = fProps ? (fProps.rules ? fProps.rules : []) : [];

        mappedProps.defaultValue = "";
        if (fProps && fProps.defaultValue)
            mappedProps.defaultValue = fProps.defaultValue;

        return mappedProps;
    }

    const hiddenInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}>
                <span style={{ color: "darkgray" }}>{mapProps.defaultValue}</span>
            </Form.Item>
        );
    }

    const textInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                rules={[
                    {
                        required: mapProps.required,
                        message: mapProps.require_message,
                    },
                    ...mapProps.rules
                ]}
                initialValue={mapProps.defaultValue}
            >
                <Input
                    placeholder={mapProps.placeholder}
                    style={mapProps.style}
                    // value={mapProps.defaultValue}
                    onChange={(e) => { onChangeData(key, e.target.value) }}
                />
            </Form.Item>
        );
    }

    const selectInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);
        console.log("------- PROPS: ", fprops);
        const mode = fprops.mode ? fprops.mode : "default";

        var options = [{ key: "empty", label: "No Data" }];

        if (fprops && fprops.options && Array.isArray(fprops.options) && fprops.options.length > 0)
            options = fprops.options;

        var defaultValue = options[0].key ? options[0].key : options[0];
        if (fprops && fprops.defaultValue)
            defaultValue = fprops.defaultValue;
        console.log("------- defaultValue: ", defaultValue);

        //require, rules
        return (
            <Form.Item label={mapProps.label} hasFeedback key={key}
                name={key}
                initialValue={defaultValue ? defaultValue : (options[0].key ? options[0].key : options[0])}
                rules={[
                    {
                        required: mapProps.required, message: mapProps.require_message
                    },
                    ...mapProps.rules
                ]}
            >
                <Select placeholder={mapProps.placeholder} onChange={(e) => { onChangeData(key, e) }} mode={mode}>
                    {
                        options.map((item) => {
                            const itemKey = item.key ? item.key : item;
                            const label = item.label ? item.label : item;
                            return (
                                <Option value={itemKey} key={itemKey}>{label}</Option>
                            );
                        })
                    }
                </Select>
            </Form.Item>
        );
    }

    const numberInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                initialValue={{ number: mapProps.defaultValue ? mapProps.defaultValue : 0 }}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message },
                    ...mapProps.rules
                ]}
            >
                <Input addonBefore={mapProps.prefixSelector} style={{ width: '100%' }}
                    onChange={(e) => { onChangeData(key, e) }} />
            </Form.Item>
        );
    }
    const currencyInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                initialValue={{ number: mapProps.defaultValue, currency: 'usd' }}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please input ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            ><PriceInput />
            </Form.Item>
        );
    }

    const handleChangeUploadLogo = (keyName, info) => {
        if (info.file.status === 'uploading') {
            setIsLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            ImageHelper.getBase64(info.file.originFileObj, imageUrl => {
                onChangeData(keyName, imageUrl);
                setIsLoading(false);
            });
        }
        console.log("Upload DONE");
    };

    const imageInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);
        var currentLogo = fprops ? (fprops.value ? fprops.value : null) : null;
        if (data[key])
            currentLogo = data[key];

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please select ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <Upload
                    name={key}
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5185415ba171ea3a00704eed"
                    beforeUpload={ImageHelper.beforeUpload}
                    onChange={(e) => handleChangeUploadLogo(key, e)}
                >
                    {
                        currentLogo ?
                            <img src={currentLogo} alt="avatar" style={{ width: '100%' }} />
                            :
                            <div>
                                {isLoading ? <LoadingOutlined /> : <PlusSquareTwoTone />}
                                <div className="ant-upload-text">Upload</div>
                            </div>}
                </Upload>
            </Form.Item>
        );
    }

    const onChangeFileHandler = (event, key) => {
        console.log(event.target.files[0]);
        if (event.target.files[0].status === 'uploading') {
            setIsLoading(true);
            return;
        }
        if (event.target.files[0].status === 'done') {
            // Get this url from response in real world.
            ImageHelper.getBase64(event.target.files[0], imageUrl => {
                onChangeData(key, imageUrl);
                setIsLoading(false);
            });
            // const reader = new FileReader();
        }
        console.log("Upload DONE");
    };

    const fileInput = (key, fprops) => {
        const mapProps = basicProps(key, fprops);
        var currentLogo = fprops ? (fprops.value ? fprops.value : null) : null;
        if (data[key])
            currentLogo = data[key];

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please select ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <input type="file" name="file" onChange={(e)=>this.onChangeFileHandler(e, key)}/>
            </Form.Item>
        );
    }

    const handleSubmit = (values) => {
        console.log('Received values of form: ', values);
        setDisable(true);
        submit();
    };

    const onFinishFailed = (error) => {
        console.log("Submit Failed");
    }

    const submit = async () => {
        setIsLoading(true);
        var success = false;
        if (onSubmit)
            success = await onSubmit(data);
        if (success) {
            await onFinishListener();
        }
        setIsLoading(false);
        setDisable(false);
    }

    const onFinishListener = async () => {
        onFinish && await onFinish();
        clearForm();
    }

    const clearForm = () => {
        setInputUI(uiObject.inputUI());
        const originData = uiObject.getOriginData();
        uiObject.onChange(originData);
        onChange && onChange(originData);
        setData(originData);
        form.resetFields();
    }

    const { getFieldDecorator } = form;
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };


    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed} form={form}  {...formItemLayout} >
            {
                inputUI ?
                    Object.keys(inputUI).map((key) => {
                        const item = inputUI[key];
                        // console.log("Input Field Item: ", item);
                        let fieldInput = textInput(key, item.props);;
                        if (!item.dataType)
                            item.dataType = INPUT_TYPE.TEXT;
                        switch (item.dataType) {
                            case INPUT_TYPE.HIDDEN:
                                fieldInput = hiddenInput(key, item.props);
                                break;
                            case INPUT_TYPE.NUMBER:
                                fieldInput = numberInput(key, item.props);
                                break;
                            case INPUT_TYPE.SELECT:
                                fieldInput = selectInput(key, item.props);
                                // console.log("Input select render: ", fieldInput);
                                break;
                            case INPUT_TYPE.LOGO:
                                fieldInput = fileInput(key, item.props);
                                // console.log("Input logo render: ", fieldInput);
                                break;
                            default:
                                fieldInput = textInput(key, item.props);
                                break;
                        }
                        // console.log("Input Field render: ", fieldInput);

                        return (
                            <div key={key}>
                                {fieldInput}
                            </div>
                        );
                    })
                    :
                    null
            }
            <Button type="primary" htmlType="submit" style={{ width: "100%", height: "30px" }} disabled={disable} loading={isLoading} >
                {submit_label}
            </Button>
        </Form>
    );
}

class PriceInput extends React.Component {
    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        const value = props.value || {};
        this.state = {
            number: value.number || 0,
            currency: value.currency || 'usd',
        };
    }

    handleNumberChange = e => {
        const number = parseInt(e.target.value || 0, 10);
        if (isNaN(number)) {
            return;
        }
        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    };

    handleCurrencyChange = currency => {
        if (!('value' in this.props)) {
            this.setState({ currency });
        }
        this.triggerChange({ currency });
    };

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            });
        }
    };

    render() {
        const { size } = this.props;
        const { currency, number } = this.state;
        return (
            <span>
                <Input
                    type="text"
                    size={size}
                    value={number}
                    onChange={this.handleNumberChange}
                    style={{ width: '65%', marginRight: '3%' }}
                />
                <Select
                    value={currency}
                    size={size}
                    style={{ width: '32%' }}
                    onChange={this.handleCurrencyChange}
                >
                    <Option value="usd">USD</Option>
                    <Option value="vnd">VND</Option>
                    <Option value="thb">THB</Option>
                </Select>
            </span>
        );
    }
}
// const InputFormByObject = Form.create({ name: 'Dynamic input form via input Object' })(InputForm);
export default InputFormByObject;
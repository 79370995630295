import React, {Fragment, useState} from 'react';
import EditableObject from '../components/EditableObject.js';
import {TextConfig, useGetTextConfig, save} from './TextConfig.js';
import {EditFilled} from '@ant-design/icons';

import { Button } from 'antd';

export default function ContentScreen(props) {
    const [refresh, setRefresh] = useState(false);
    let texts = useGetTextConfig(refresh);
    const [editingText, setEditingText] = useState(texts);
    const [error, setError] = useState(null);
    
    const onUpdate=(newData)=>{
        let error=null;
        if(newData) {
            save(newData, ()=>{setRefresh(true);setEditingText(null)}, (err)=>{setError(err)});
        }
        return error;
    }

    return (
        <Fragment>
            
            <h1>Cấu hình Nội dung trên website</h1>
            <Button type="primary" icon={<EditFilled />} onClick={() => { setEditingText(new TextConfig(texts)) }}>Chỉnh sửa</Button>

            <EditableObject
                input={editingText}
                onCancel={() => { setEditingText(null) }}
                onSubmit={onUpdate}
                error={error}
                submitButton="Cập nhật"
                title="Tùy biến nội dung trên website" />

        </Fragment>
    )
}
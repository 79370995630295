import React, { useState, useEffect } from 'react';
import * as COLLECTIONS from '../common/constant/collections';
import * as NAME from '../common/constant/name';
import { message } from 'antd';
import { fb, db } from '../common/firebase/Firestore';
import * as firebase from "firebase/app";
import * as Converter from '../common/helpers/Converter';
import md5 from 'md5';

function useGetOrders(searchText, limit) {
    var [allOrders, setAllOrder] = useState(null);

    useEffect(() => {
        if (limit > 0) {
            let orderRef = db.collection(COLLECTIONS.ORDER).orderBy("created", "desc");
            if (searchText)
                orderRef = orderRef.where("keywords", "array-contains", searchText.toLowerCase());

            orderRef.limit(limit).get().then((docs) => {

                let results = [];
                docs.forEach((doc) => {
                    if (doc.exists) {
                        results.push({ key: doc.id, ...doc.data() });
                        console.log("Search result:", doc.data());
                    }
                })
                setAllOrder(results);
            });
        }
    }, [searchText])

    return allOrders;
}

export function useGetMoneyHistory(user) {
    const [history, setHistory] = useState(null);

    useEffect(() => {
        if (user && user.uid) {
            getAllHistory()
        }
    }, [user])

    const getAllHistory = async () => {
        const histories = []
        const results = await Promise.all([getRechargeHistory(), getWithDrawHistory()])
        results.forEach((item)=>{
            if(item && Array.isArray(item)) {
                histories.push(...item)
            }
        })
        setHistory(histories)
        console.log("HISTORY: ", histories)
    }
    const getRechargeHistory = async () => {
        const status = await db.collection("Recharge").where("uid","==", user.uid).get()
        if(status && status.docs) {
            let data = [];
            status.docs.forEach((doc) => {
                if (doc.exists) {
                    const infor = doc.data()
                    data.push({ key: doc.id, ...infor, type:"Nạp tiền", after: infor.after_recharge, before:infor.before_recharge });
                }
            })
            return data
        } else return [];
    }
    
    const getWithDrawHistory = async () => {
        const status = await db.collection("WithDraw").where("uid","==", user.uid).get()
        if(status && status.docs) {
            let data = [];
            status.docs.forEach((doc) => {
                if (doc.exists) {
                    const infor = doc.data()
                    data.push({ key: doc.id, ...infor, type:"Rút tiền", after: infor.after_withdraw, before: infor.before_withdraw });
                }
            })
            return data
        } else return [];
    }

    return history;
}


function useGetCustomers(limit, refreshData) {
    var [allCustomers, setAllCustomers] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (allCustomers == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let customerRef = db.collection(COLLECTIONS.USER).orderBy("name", "asc");
        if (limit > 0)
            customerRef = customerRef.limit(limit);

        customerRef.get().then((docs) => {

            let results = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    results.push({ key: doc.id, ...doc.data() });
                    // console.log("Customer data:", doc.data());
                }
            })
            setAllCustomers(results);
            setRefresh(false);
        });
    }

    return allCustomers;
}

const recharge = (user, amount, content, onSuccess, onFail) => {
    try {
        const newMoney = user.money ? (user.money + amount) : amount;
        db.collection(COLLECTIONS.USER).doc(user.uid).update({ money: newMoney }).then((doc) => {
            onSuccess && onSuccess(newMoney);
        })

        //Write log on system
        db.collection(COLLECTIONS.RECHARGE_HISTORY).add({
            uid: user.uid,
            name: user.name,
            phone: user.phone ? user.phone : "Unknow",
            amount: amount,
            created: firebase.firestore.Timestamp.now(),
            before_recharge: user.money ? user.money : 0,
            after_recharge: newMoney,
            content: content,
        }).then((doc) => {
            //Save account's money history
            var money_history = {
                exchange_type: NAME.EXCHANGE_TYPE.RECHARGE,
                amount: amount,
                created: firebase.firestore.Timestamp.now(),
                before: user.money ? user.money : 0,
                after: newMoney,
                content: content,
                recharge_id: doc.id,
            };
            var old_history = user.money_history ? user.money_history : [];
            db.collection(COLLECTIONS.USER).doc(user.uid).update({
                money_history: [money_history, ...old_history],
            })

            //Add new notification
            db.collection(COLLECTIONS.USER).doc(user.uid).collection(COLLECTIONS.SUB_NOTI).add({
                type: NAME.NOTI_TYPE.MONEY_CHANGE,
                read: false,
                created: firebase.firestore.Timestamp.now(),
                target: doc.id,
                content: "Tài khoản được nạp thêm " + Converter.numberWithCommas(amount) + " VNĐ",
            })

        })

    } catch (error) {
        console.log(error);
        onFail && onFail(error.name);
    }
}

const withDraw = (user, amount, content, onSuccess, onFail) => {
    try {
        const newMoney = user.money ? (user.money - amount) : 0;
        db.collection(COLLECTIONS.USER).doc(user.uid).update({ money: newMoney }).then((doc) => {
            onSuccess && onSuccess(newMoney);
        })

        //Write log on system
        db.collection(COLLECTIONS.WITHDRAW_HISTORY).add({
            uid: user.uid,
            name: user.name,
            phone: user.phone ? user.phone : "Unknow",
            amount: amount,
            created: firebase.firestore.Timestamp.now(),
            before_withdraw: user.money ? user.money : 0,
            after_withdraw: newMoney,
            content: content,
        }).then((doc) => {
            //Save account's money history
            var money_history = {
                exchange_type: NAME.EXCHANGE_TYPE.WITH_DRAW,
                amount: amount,
                created: firebase.firestore.Timestamp.now(),
                before: user.money ? user.money : 0,
                after: newMoney,
                content: content,
                recharge_id: doc.id,
            };
            var old_history = user.money_history ? user.money_history : [];
            db.collection(COLLECTIONS.USER).doc(user.uid).update({
                money_history: [money_history, ...old_history],
            })

            //Add new notification
            db.collection(COLLECTIONS.USER).doc(user.uid).collection(COLLECTIONS.SUB_NOTI).add({
                type: NAME.NOTI_TYPE.MONEY_CHANGE,
                read: false,
                created: firebase.firestore.Timestamp.now(),
                target: doc.id,
                content: "Tài khoản đã rút bớt " + Converter.numberWithCommas(amount) + " VNĐ",
            })

        })

    } catch (error) {
        console.log(error);
        onFail && onFail(error.name);
    }
}

function useSignUpEmail(userInfo, onSuccess, onFail) {
    const [signUpStatus, setSignUpStatus] = useState(null);
    const [info, setInfo] = useState(userInfo);

    useEffect(() => {
        if (userInfo) {
            const signupInfo = userInfo.data();
            if (signupInfo && signupInfo.email && signupInfo.password && info != signupInfo) {
                setInfo(signupInfo);
                doSignUp(signupInfo)
            }
        }
    }, [userInfo])

    const doSignUp = async (signupInfo) => {
        console.log("Do signup new user: ", signupInfo);
        let signUpStt = { status: true, message: "" };
        let success = true;

        //Create firebase account for authen
        await firebase.auth().createUserWithEmailAndPassword(signupInfo.email, md5(signupInfo.password))
            .then((userCredential) => {
                console.log("UserCredential: ", userCredential);
                //Create new User info
                if (userCredential.user) {
                    const newUser = GetLatestInfo(userCredential.user);
                    let validateInfo = { ...signupInfo };
                    if (!validateInfo.phone)
                        delete validateInfo['phone'];
                    // if (!validateInfo.address)
                    //     delete validateInfo['address'];
                    updateCustomerInfo({ ...newUser, ...validateInfo, password: md5(validateInfo.password) }, onSuccess);
                    signUpStt.message = "Đăng ký thành công";
                } else {
                    signUpStt.message = "Không khởi tạo được thông tin người dùng";
                }
                setSignUpStatus(signUpStt);

            }).catch(function (error) {
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log("Exception: ", errorMessage);
                signUpStt.status = false;
                signUpStt.message = "Đăng ký không thành công: " + errorMessage;
                onFail && onFail(signUpStt);
                success = false;
                setSignUpStatus(signUpStt);
            });
    }

    return signUpStatus;
}

const GetLatestInfo = (userInfo) => {
    // User is signed in.
    const displayName = userInfo.displayName;
    const email = userInfo.email;
    const photoURL = userInfo.photoURL;
    const phoneNumber = userInfo.phoneNumber;
    const uid = userInfo.uid;

    let newInfo = { type: "customer", status: 1, address: "" };
    if (displayName)
        newInfo.name = displayName;
    if (email)
        newInfo.email = email;
    if (photoURL)
        newInfo.avatar = photoURL;
    if (uid)
        newInfo.uid = uid;
    if (phoneNumber)
        newInfo.phone = phoneNumber;

    return newInfo;
}

const updateCustomerInfo = async (userInfo, callBack) => {
    await db.collection("Users").doc(userInfo.uid).get().then((doc) => {
        if (doc.exists) {
            const newInfo = { ...userInfo, ...doc.data(), isLogin: true };
            db.collection("Users").doc(userInfo.uid).update({ ...newInfo }).then((doc) => {
                callBack && callBack(newInfo);
            });
        } else {
            db.collection("Users").doc(userInfo.uid).set({ ...userInfo, isLogin: true }).then((doc) => {
                callBack && callBack(userInfo);
            }).catch((error) => {
                console.log("Cannot found user: ", userInfo.uid);
            });
        }
    }).catch((error) => {
        console.log("Cannot get user info: ", error);
    });
}

export {
    useGetOrders,
    useGetCustomers,
    recharge,
    withDraw,
    useSignUpEmail,
    updateCustomerInfo,
    GetLatestInfo
}

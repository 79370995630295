import { Input } from "antd";
import InputFormByObject from "../module/components/InputFormByObject";

const INPUT_TYPE = {
    TEXT: "text",
    NUMBER: "number",
    CURRENCY: "currency",
    LOGO: "logo",
    PASSWORD: "password",
    PERCENT: "percent",
    SELECT: "select",
    CASCADER: "cascader",
    DATETIME: "datetime",
    CHECKBOX: "checkbox",
    RADIO: "radio",
    FORM: "form",
    HIDDEN: "hidden",
    LOCKED: "locked",
    SWITCH: "switch",
}

class InputField {
    key = "";
    value = ""; //Or object
    props = {};
    dataType = INPUT_TYPE.TEXT;

    constructor(key, value) {
        this.key = key;
        this.value = value;
        this.addProp("defaultValue", value);
        this.dataType = INPUT_TYPE.TEXT;

        if (typeof value === "boolean")
            this.dataType = INPUT_TYPE.SWITCH;
        else if ((typeof value === "number") || typeof value === "bigint") {
            // console.log("VALUE: ", this);
            this.dataType = INPUT_TYPE.NUMBER;
        } else if (Array.isArray(value)) {
            this.dataType = INPUT_TYPE.CASCADER;
        }
        else if (typeof value === "object") {
            this.dataType = INPUT_TYPE.SUB_FORM;
            this.value = createInput(value);
        }
    }

    addProp = (key, value) => {
        if (!this.props)
            this.props = {};
        if (key)
            this.props[key] = value;
    }

    setProps = (props) => {
        this.props = props;
    }

    convertToSelect = (options) => {
        this.addProp("options", options);
        this.dataType = INPUT_TYPE.SELECT;
    }
    convertToMultiSelect = (options) => {
        this.addProp("options", options);
        this.addProp("mode", "tags");
        this.dataType = INPUT_TYPE.SELECT;
    }
    convertToCascader = (options) => {
        this.addProp("options", options);
        this.dataType = INPUT_TYPE.CASCADER;
    }
    convertToLazyCascader = (options, loadDataEvent) => {
        this.addProp("options", options);
        this.addProp("loadData", loadDataEvent);
        this.dataType = INPUT_TYPE.CASCADER;
    }
    convertToCurrency = (currencyType) => {
        this.addProp("currencyType", currencyType);
        this.dataType = INPUT_TYPE.CURRENCY;
    }
}

const mapInput = (input, template) => {
    if (typeof input != "object") {
        return input;
    }
    // console.log("Created new input object with template: ", template);
    var output = {};
    var mappedField = [];

    Object.keys(input).map((key) => {
        const fieldValue = input[key];
        if (template && template[key] && template[key] instanceof InputField) {
            if (typeof fieldValue === "object") {
                if (template[key].value instanceof Object) {
                    // console.log("========== Template is Object: ", template[key].value);
                    // console.log("========== fieldValue: ", fieldValue);
                    if (Array.isArray(template[key].value) && template[key].dataType == INPUT_TYPE.CASCADER) {
                        output[key] = template[key];
                        output[key].addProp("defaultValue", fieldValue);
                    } else {
                        output[key] = mapInput(fieldValue, template[key].value);
                        // console.log("========== New object UI: ", output[key]);
                    }
                } else if (Array.isArray(fieldValue)) {
                    output[key] = template[key];
                    output[key].addProp("defaultValue", fieldValue);
                } else {
                    output[key] = new InputField(key, fieldValue);
                    // console.log("New object UI (template is not array): ", output[key]);
                }
            } else {
                output[key] = template[key];
                output[key].addProp("defaultValue", fieldValue);
                // console.log("New input UI: ", output[key]);
            }
            mappedField.push(key);
        } else {
            // console.error("Template is invalid at Key: ", key);
            output[key] = new InputField(key, fieldValue);
        }
    });

    //create remain fields in Template, which is empty in origin data
    Object.keys(template).map((key) => {
        if (!mappedField.includes(key) && template && template[key] && template[key] instanceof InputField) {
            output[key] = template[key];
        }
    });

    return output;
}

const createInput = (input) => {
    if (typeof input != "object") {
        return input;
    }
    var output = {};

    Object.keys(input).map((key) => {
        const fieldValue = input[key];
        let field = new InputField(key, fieldValue);

        output[key] = field;
    });
    // console.log("Created new input object: ", output);
    return output;
}

export { INPUT_TYPE, InputField, mapInput, createInput }
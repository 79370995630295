import {InputField, INPUT_TYPE} from './InputObject';
import UIObject from './UIObject';

const COUNTRY = [
    "Việt Nam", "Nhật", "Thái Lan", "Trung Quốc", "Mỹ", "Italia", "Anh"
];

export default class Brand extends UIObject {
    constructor(data) {
        super(data);

        this.name= new InputField("name", "Brand's name");
        this.name.setProps({
            required:true,
            label:"Tên thương hiệu",
            placeholder: "Hãy nhập tên thương hiệu",
        });

        this.description = new InputField ("description", "description default");
        this.description.setProps({
            label:"Mô tả",
            placeholder: "SEO sẽ hoạt động tốt hơn khi có mô tả đầy đủ",
        });

        this.country = new InputField("country", "Viet Nam") ;
        this.country.setProps({
            label:"Quốc gia",
        });
        this.country.convertToSelect(COUNTRY);

        this.brandType = new InputField("type", "auth");
        this.brandType.setProps({
            label:"Quy mô (Kiểu)",
        });
        this.brandType.convertToSelect(["auth","fake1","fake2"]);

        this.logo = new InputField("logo", "");
        this.logo.setProps({
            label:"Logo",
            required:true,
        });
        this.logo.dataType=INPUT_TYPE.LOGO;

        this.mapData();           
    }
}
import {InputField, INPUT_TYPE} from './InputObject';
import UIObject from './UIObject';

export default class PartGroup extends UIObject {
    constructor(data) {
        var mappedData = {...data};
        if(data && data.bike && data.brand && data.bikeClass && Array.isArray(data.bikeClass)==false) {
            console.log("Bike class: ", data.bikeClass);
            mappedData.bikeClass = [data.brand, data.bike, data.bikeClass];
            console.log("================ Bike class: ", mappedData.bikeClass);
        }
        super(mappedData);

        this.logo = new InputField("logo", "");
        this.logo.setProps({
            label:"Logo",
            required:true,
        });
        this.logo.dataType=INPUT_TYPE.LOGO;

        this.bikeClass = new InputField("bikeClass", mappedData?mappedData.bikeClass:[]) ;
        console.log("*** Cascader bike value 1: ", this.bikeClass.value);
        this.bikeClass.setProps({
            required:true,
            label:"Phiên bản xe",
            placeholder: "Chọn: Thương hiệu / Loại xe / Phiên bản",
        });
        this.bikeClass.convertToCascader([{label: "Kawasaki", value: "kawasaki"}]);

        this.name= new InputField("name", "Group's name");
        this.name.setProps({
            required:true,
            placeholder: "Hãy nhập tên nhóm phụ tùng",
            label:"Tên nhóm phụ tùng",
        });

        this.description = new InputField ("description", " ");
        this.description.setProps({
            label:"Mô tả",
            placeholder: "Hãy nhập mô tả cho nhóm phụ tùng",
        });

        this.tag = new InputField("tag", "");
        this.tag.setProps({
            label:"Tag",
            placeholder: "Nhập các từ khóa liên quan"
        });
        this.tag.convertToMultiSelect([]);

        this.addLockedField("key");
        this.addHiddenField("brand");
        this.addHiddenField("bike");
        // this.addLockedField("group_id");
        this.mapData();           
    }

    setClasses=(classOptions) => {
        console.log("BIKE OPTIONS: ", classOptions);
        this.bikeClass.convertToCascader(classOptions);
        this.mapData();     
    }

    setLazyClasses=(classOptions, loadData) => {
        console.log("BIKE OPTIONS: ", classOptions);
        this.bikeClass.convertToLazyCascader(classOptions, loadData);
        this.mapData();     
    }

    setClass=(brand, bike, bikeClass) => {
        this.bikeClass.addProp({defaultValue:[brand,bike, bikeClass]})
        this.mapData();     
    }
}
import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import EditableCell, { EditableContext } from '../components/EditalbeCell';
import { Table, Tag, Popconfirm, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Upload, message } from 'antd';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext } from '../../App';
import NewBikeComponent from './NewBikeComponent';
// import InputFormByObject from '../components/InputFormByObject';
import EditableObject from '../components/EditableObject';
import * as CustomAntd from './antd-modified.css';
import Bike from '../../objects/Bike';

// const FormItem = Form.Item;

// const expandedRowRender = record => <p>{record.description}</p>;
// const showHeader = true;
// const footer = () => 'Here is footer';
// const scroll = { y: 240 };
// const pagination = { position: 'bottom' };



function BikeScreen(props) {
    // const [form] = Form.useForm();
    const user = useContext(UserContext);
    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    var [editingBike, setEditingBike] = useState(null);
    var [bikes, setBikes] = useState([]);
    var [brands, setBrands] = useState([]);
    var [pagination, setPagination] = useState({ position: 'both' });
    var [searchText, setSearchText] = useState("");
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState("");
    // var [showingModal, setShowingModal] = useState(false);
    console.log("==== Brands: ", brands);

    const columns = [
        {
            title: 'Mã xe',
            dataIndex: 'name',
            key: 'name',
            width: "10%",
            render: (text) => <a href="#">{text}</a>,
        },
        {
            title: 'Tên đầy đủ',
            dataIndex: 'full_name',
            key: 'full_name',
            width: "10%",
            render: (text) => <a href="#">{text}</a>,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            width: "15%",
        },
        {
            title: 'Thương Hiệu',
            dataIndex: 'brand',
            key: 'brand',
            width: "10%",
        },
        {
            title: 'Năm sản xuất',
            dataIndex: 'year',
            key: 'year',
            width: "15%",
            render: tag => {
                return (tag && Array.isArray(tag)) ? (
                    <span>
                        {tag.map(tag => {
                            return (
                                <Tag key={tag}>
                                    {tag}
                                </Tag>
                            );
                        })}
                    </span>) : null
            },
        },
        {
            title: 'Tags',
            dataIndex: 'tag',
            key: 'tag',
            width: "20%",
            render: tag => {
                return (tag && Array.isArray(tag)) ? (
                    <span>
                        {tag.map(tag => {
                            return (
                                <Tag key={tag}>
                                    {tag}
                                </Tag>
                            );
                        })}
                    </span>) : null
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "20%",
            render: (text, record) => (
                <div>
                    <Button onClick={() => editBike(record)} type="primary">
                        Edit
                    </Button>
                    
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa xe này?"
                        onConfirm={() => deleteBike(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                    <Button type="danger">
                        Delete
                    </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    useEffect(() => {
        setBikes(allBikes);
    }, [allBikes]);

    useEffect(() => {
        setBrands(allBrands);
    }, [allBrands]);

    const handlePaginationChange = e => {
        const { value } = e.target;
        setPagination(value === 'none' ? false : { position: value });
    };

    const cancel = () => {
    };

    const deleteBike = (item) => {

        db.collection(COLLECTIONS.BRAND).doc(item.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(item.key).delete().then(function() {
            message.success('Xóa xe "' + item.name + '" thành công!');
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            message.error('Xóa xe "' + item.name + '" thất bại. Hãy thử lại sau!');
        });

        setBikes(bikes.filter(p => {
            if (p && p.key != item.key)
                return true;
            else return false;
        }));
    }

    const editBike = (bike) => {
        console.log("Edit bike: ", bike);
        setEditingBike(new Bike(bike));
        // setShowingModal(true);
    }

    const updateBike = async (bikeData) => {
        console.log("bike update data: ", bikeData);
        //Update client
        const oldData = bikes;
        const newData = [...bikes];
        const index = newData.findIndex(item => bikeData.key === item.key);
        if (index > -1) {
            newData.splice(index, 1, bikeData);
        } else {
            newData.push(bikeData);
        }
        setBikes(newData);

        //Save to server
        try {
            db.collection(COLLECTIONS.ALL_BIKE).doc(bikeData.key).update(bikeData);
            db.collection(COLLECTIONS.BRAND).doc(bikeData.brand).collection(COLLECTIONS.SUB_BIKE).doc(bikeData.key).update(bikeData);
        } catch (error) {
            //Recover old data
            setBikes(oldData);
            console.log('error', error)
        }

        setIsLoading(false);
        setEditingBike(null);
    }

    // useEffect(() => {
    //     setEdittingModal(<Modal
    //         visible={showingModal}
    //         title="Cập nhật thông tin  Xe"
    //         onCancel={() => { setShowingModal(false); setEditingBike(new Bike()) }}
    //         footer={
    //             <span style={{ color: "red" }}>{error}</span>
    //         }
    //     >
    //         <InputFormByObject input={editingBike} onSubmit={updateBike} submitButton="Cập nhật" />

    //     </Modal>);
    //     setShowingModal(true);
    //     console.log("Editing bike is changed", editingBike);
    // }, [editingBike]);

    const dataSource = Search.searchInObject(bikes, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    return (
        <div>
            <h1>Quản lý xe</h1>
            <NewBikeComponent brands={brands} isLoading={false} searchListener={(text) => { setSearchText(text) }} />

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh Sách Xe</span>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource}
                    loading={bikes.length == 0}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key + "_" + record.brand)}
                />
            </div>

            <EditableObject
                // visible={showingModal}
                // onCancel={()=>{setShowingModal(false)}}
                input={editingBike}
                onSubmit={updateBike}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin  Xe" />
        </div>
    );
}

// const BikeScreen = Form.create({ name: 'bike' })(BikeForm);
export default BikeScreen;
import React, { useState } from 'react';
import { fb, db } from '../../common/firebase/Firestore';
import { Button, Spin, Progress, Row, Col, Checkbox, Modal } from 'antd';
import async from 'async';
import csv from 'csvtojson';
import CSVReader from "react-csv-reader";
import * as COLLECTIONS from '../../common/constant/collections';
import firebase from "firebase";

const csvFilePath = './DataFile.csv';

export default function ImportScreen(props) {
    var [running, setRunning] = useState(false);
    var [notice, setNotice] = useState(false);
    var [forceStop, setForceStop] = useState(false);
    var [isLoading, setIsLoading] = useState(false);
    var [percent, setPercent] = useState(0);
    var [current, setCurrent] = useState(0);
    var [totalTask, setTotalTask] = useState(0);
    var [outDate, setOutDate] = useState([]);
    var [errors, setErrors] = useState([]);
    var [syncData, setSyncData] = useState(false);
    var [needConfirm, setNeedConfirm]=useState(false);
    // var [jsonData, setJsonData] = useState(null);

    async function asyncPushData(array, callback) {
        for (let index = 0; index < array.length; index++) {
            if (forceStop) {
                index = array.length;
                break;
            }
            else
                await callback(array[index], index, array);
        }
    }

    const validate = (partData) => {
        var result = {
            code: 0,
            message: ""
        };

        if (partData.hasOwnProperty('code') == false || !partData.code) {
            result.code = 1;
            result.message = "Empty code field";
        }
        if (partData.hasOwnProperty('brand') == false || !partData.brand) {
            result.code = 1;
            result.message = ((result.message) ? " & " : "") + "Empty brand field";
        }
        if (partData.hasOwnProperty('bike') == false || !partData.bike) {
            result.code = 1;
            result.message = ((result.message) ? " & " : "") + "Empty bike field";
        }
        if (partData.hasOwnProperty('group_id') == false || !partData.group_id) {
            result.code = 1;
            result.message = ((result.message) ? " & " : "") + "Empty group_id";
        }
        if (partData.hasOwnProperty('price_retail') == false || !partData.price_retail) {
            // result.code = 1;
            result.message = ((result.message) ? " & " : "") + "Empty price_retail";
        }

        return result;
    }

    const arrayUnique = (array) => {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    }

    const collectKeyword = (object) => {
        var keywords = [];
        // console.log("START COLLECT KEY: ", object);

        if (typeof object === 'string') {
            // console.log("KEY STRING: ", object);
            if (object.includes(' ')) {
                const allWords = object.split(' ');
                allWords.forEach((word) => {
                    // console.log("WORD: ", word);
                    const aWord = collectKeyword(word);
                    keywords = [...keywords, ...aWord];
                });
            } else {
                for (var i = 0; i <= object.length; i++) {
                    keywords.push(object.substring(0, i).toLowerCase());
                }
            }
        } else if (typeof object === 'object' && Array.isArray(object) == false) {
            // console.log("OBJECT: ", object);
            Object.keys(object).forEach((key) => {
                const wordForKey = collectKeyword(object[key]);
                // console.log("KEY OBJECT: ", key);
                // console.log("KEY WORD: ", wordForKey);
                keywords = [...keywords, ...wordForKey];
                // console.log("KEY_WORD_ALL: ", keywords);
            })
        } else if (Array.isArray(object)) {
            // console.log("KEY_ARRAY: ", object);
            object.forEach((element) => {
                const elementWord = collectKeyword(element);
                keywords = [...keywords, ...elementWord];
            })
        }

        // console.log("END Key word: ", keywords);

        return keywords;
    }

    const doImport = async (data) => {
        if (!data) {
            console.log("Data is empty");
            return;
        }

        var jsonArray = await csv().fromString(data);
        setTotalTask(jsonArray.length);

        setRunning(true);
        setNotice("Đang đẩy dữ liệu");

        // const jsonArray = jsonData;

        let brands = [];
        let bikes = [];
        let classes = [];
        let groups = [];
        let done = 0;
        const startTime = firebase.firestore.Timestamp.now().seconds;

        await asyncPushData(jsonArray, async (object) => {
            done++;
            var progressPercent = Math.round((done / jsonArray.length * 100) * 100) / 100;
            setPercent(progressPercent);
            console.log("Percent: ", progressPercent);
            setCurrent(done);
            object.keywords = arrayUnique(collectKeyword(object));
            setNotice("Process data for part: " + object.code + " (Bike: " + object.bike + ")");

            const valid = validate(object);
            if (valid.code == 0) {
                if (forceStop == false) {
                    var dif_group = false;
                    const bikeClassKey = object.bikeClass.replace("/", "_");
                    let groupId = object.group_id + "";
                    if (!groupId)
                        groupId = "000";
                    console.log("bikeClassKey: ", bikeClassKey);

                    if (brands.includes(object.brand) == false) {
                        brands.push(object.brand);
                        var brand_ref = db.collection('brand').doc(object.brand);
                        //Create new brand on DB
                        const newBrand = await brand_ref.get();
                        console.log("New brand: ", newBrand);
                        if (newBrand.exists == false) {
                            const addedBrand = await brand_ref.set({ name: object.brand });
                        }
                        dif_group = true;
                    }
                    if (bikes.includes(object.brand + "#" + object.bike) == false || dif_group) {
                        bikes.push(object.brand + "#" + object.bike);
                        var bike_ref = db.collection(COLLECTIONS.BRAND)
                            .doc(object.brand).collection(COLLECTIONS.SUB_BIKE)
                            .doc(object.bike);

                        const existedBike = await bike_ref.get();
                        if (existedBike.exists == false) {
                            //Create new bike on DB
                            const addBike = await bike_ref.set({ name: object.bike, full_name: object.bike, brand: object.brand });
                        }
                        dif_group = true;
                    }
                    if (classes.includes(object.brand + "#" + object.bike + "#" + object.bikeClass) == false || dif_group) {
                        classes.push(object.brand + "#" + object.bike + "#" + object.bikeClass);
                        const bikeClass_ref = db.collection(COLLECTIONS.BRAND)
                            .doc(object.brand).collection(COLLECTIONS.SUB_BIKE)
                            .doc(object.bike).collection(COLLECTIONS.SUB_CLASS)
                            .doc(bikeClassKey);

                        const existedBikeClass = await bikeClass_ref.get();
                        if (existedBikeClass.exists == false) {
                            //Create new bikeClass on DB
                            const addBikeClass = await bikeClass_ref.set({ name: object.bikeClass, bike: object.bike, brand: object.brand, key: bikeClassKey, year: object.year });
                        }
                        dif_group = true;
                    }
                    if (groups.includes(object.brand + "#" + object.bike + "#" + object.bikeClass + "#" + groupId) == false || dif_group) {
                        groups.push(object.brand + "#" + object.bike + "#" + object.bikeClass + "#" + groupId);
                        console.log("GROUP ID", groupId);
                        const group_ref = db.collection(COLLECTIONS.BRAND)
                            .doc(object.brand).collection(COLLECTIONS.SUB_BIKE)
                            .doc(object.bike).collection(COLLECTIONS.SUB_CLASS)
                            .doc(bikeClassKey).collection(COLLECTIONS.SUB_GROUP)
                            .doc(groupId);
                        const existedGroup = await group_ref.get();
                        if (existedGroup.exists === false) {
                            //Create new group on DB
                            const addGroup = await group_ref.set({ name: object.group_name, key: groupId, bikeClass: object.bikeClass, bike: object.bike, brand: object.brand, order: groupId });
                        }

                        if (syncData) {
                            //Delete unuse part in current group on Old cloud data
                            const allPartInGroup = await group_ref.collection(COLLECTIONS.SUB_PART).get();

                            try {
                                const deleteAction = Promise.all(allPartInGroup.docs.map(async (doc) => {
                                    const deleteUnUsedPartResult = await group_ref.collection(COLLECTIONS.SUB_PART).doc(doc.id).delete();
                                    console.warn("Delete part: ", doc.id);
                                }));

                            } catch (error) {
                                var errorObject = { ...object };
                                errorObject.error = error.name;
                                setErrors([...errors, partObject]);
                                console.error("Failed: ", error);
                                console.log("Error: Cannot delete un used part in group: ", groupId);
                            }
                        }
                    }

                    object.price_retail = parseInt(object.price_retail) ? parseInt(object.price_retail) : 0;
                    object.price_thai = parseInt(object.price_thai) ? parseInt(object.price_thai) : 0;
                    object.price_vn = parseInt(object.price_vn) ? parseInt(object.price_vn) : 0;
                    object.price_brand = parseInt(object.price_brand) ? parseInt(object.price_brand) : 0;
                    object.price_thai_tax = parseInt(object.price_thai_tax) ? parseInt(object.price_thai_tax) : 0;


                    if (object.old_code == object.code) {
                        var partObject = { ...object };
                        try {
                            console.log("Part Object: ", partObject);
                            partObject.status = 1;
                            partObject.out_date = false;
                            delete partObject.old_code;
                            const addDoc = await db.collection(COLLECTIONS.BRAND)
                                .doc(partObject.brand).collection(COLLECTIONS.SUB_BIKE)
                                .doc(partObject.bike).collection(COLLECTIONS.SUB_CLASS)
                                .doc(bikeClassKey).collection(COLLECTIONS.SUB_GROUP)
                                .doc(partObject.group_id + "").collection(COLLECTIONS.SUB_PART)
                                .doc(partObject.code).set(partObject);

                            // await db.collection(COLLECTIONS.ALL_PART).doc(object.code).set(object)
                            //     .then((doc) => {
                            //     }).catch((error) => {
                            //         object.error = error.name;
                            //         setErrors([...errors, object]);
                            //     });

                        } catch (error) {
                            partObject.error = error.name;
                            setErrors([...errors, partObject]);
                            console.error("Failed: ", error);
                            console.log("Error: Cannot push current part code: ", object.index);
                        }
                    } else {
                        console.log("================= Found new PART: ", object.code);
                        var outDateArr = outDate;
                        outDateArr.push(object);
                        setOutDate(outDateArr);
                        var partObject = { ...object };
                        try {
                            partObject.replaced = [object.old_code];
                            partObject.out_date = false;
                            partObject.status = 1;

                            const newPart = db.collection(COLLECTIONS.BRAND)
                                .doc(object.brand).collection(COLLECTIONS.SUB_BIKE)
                                .doc(object.bike).collection(COLLECTIONS.SUB_CLASS)
                                .doc(bikeClassKey).collection(COLLECTIONS.SUB_GROUP)
                                .doc(groupId).collection(COLLECTIONS.SUB_PART)
                                .doc(object.code).set(partObject);


                            var oldPartObject = { ...object };
                            oldPartObject.replaced = [object.code];
                            oldPartObject.out_date = true;
                            oldPartObject.status = 0;
                            oldPartObject.code = object.old_code;
                            delete oldPartObject.old_code;

                            const oldPartRef = db.collection(COLLECTIONS.BRAND)
                                .doc(object.brand).collection(COLLECTIONS.SUB_BIKE)
                                .doc(object.bike).collection(COLLECTIONS.SUB_CLASS)
                                .doc(bikeClassKey).collection(COLLECTIONS.SUB_GROUP)
                                .doc(groupId).collection(COLLECTIONS.SUB_PART)
                                .doc(object.old_code);

                            const oldPart = await oldPartRef.get();
                            if (oldPart.exists == false) {
                                console.log("PART NOT EXISTS: ", oldPart);
                                await oldPartRef.set(oldPartObject);
                            } else {
                                await oldPartRef.update({ out_date: true, status: 0, replaced: [object.code] });
                            }

                            //Write in all part collection (for searching)
                            // await db.collection(COLLECTIONS.ALL_PART).doc(object.code).set(partObject)
                            //     .then((doc) => {
                            //     }).catch((error) => {
                            //         object.error = error.name;
                            //         setErrors([...errors, object]);
                            //     });
                            // await db.collection(COLLECTIONS.ALL_PART).doc(object.old_code).set(oldPartObject)
                            //     .then((doc) => {
                            //     }).catch((error) => {
                            //         object.error = error.name;
                            //         setErrors([...errors, object]);
                            //     });
                        } catch (error) {
                            partObject.error = error.name;
                            setErrors([...errors, partObject]);
                            console.log("ERROR (New Part): Cannot push current part code: ", object.index);
                        }
                    }
                }
            } else {
                object.error = valid.message;
                var oldErrors = errors;
                oldErrors.push(object);
                setErrors(oldErrors);
                console.log("ERROR: Cannot push current part code: ", object.index);
            }

        });

        const endTime = firebase.firestore.Timestamp.now().seconds;
        const duration = endTime - startTime;
        const min = Math.round(duration / 60);
        const sec = duration % 60;

        setNotice("Đẩy dữ liệu hoàn tất (duration: " + min + "m " + sec + "s)");
        setRunning(false);
        setSyncData(false);
    }

    const stop = () => {
        setForceStop(true);

        setNotice("Đã ngừng chuyển đổi dữ liệu");
        setRunning(false);
    }

    let fileReader;

    const onLoadFileDone = (e) => {
        var data = fileReader.result;
        // setJsonData(data);
        setNotice("Upload file finished. Data processing...");
        setErrors([]);
        setTotalTask(0);
        setOutDate([]);
        doImport(data);
    }
    const errorLoadFile = (error) => {
        setNotice("Error: " + error);
    }

    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = onLoadFileDone;
        fileReader.readAsText(file);
    }

    const onSelectSyncData = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setNeedConfirm(true);
        }
    }

    const noticeColor = running ? "green" : "blue";

    return (
        <div>
            <h1 style={{ marginTop: "20px" }}>Import data into Database</h1>
            <Checkbox onChange={onSelectSyncData} style={{color:"red"}} checked={syncData}>Đồng bộ với file import (Xóa dữ liệu thừa trên cloud mà không có trong file import)</Checkbox>
            <br/>
            <br/>
            <input type='file'
                id='file'
                className='input-file'
                accept='.csv'
                onChange={e => handleFileChosen(e.target.files[0])} />

            <div style={{ display: 'flex' }}>
                <p style={{ marginRight: "10px" }}>Tình trạng: <span style={{ color: noticeColor }}>{notice}</span></p>
                {running ? <Spin /> : null}
            </div>
            <div style={{ marginBottom: "10px" }}>
                <span>{current}/{totalTask}</span>
                <Progress percent={percent} status="active" />
            </div>
            <Button onClick={stop} type="danger" disabled={!running}>Stop</Button>
            <Row style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "left" }}>
                        <span style={{ fontWeight: "bold", color: "darkblue" }}>Out of Date:</span>
                        {outDate.map((part) => {
                            return (
                                <span key={part.code + "_" + part.group_id + "_" + part.bikeClass + "_" + part.bike} style={{ fontSize: "small" }}> <span style={{ textDecoration: "line-through", color: "gray" }}>{part.old_code} </span>is replaced by <span style={{ color: "green" }}>{part.code}</span> in {part.bike}/{part.bikeClass}/{part.group_id} ({part.brand}).</span>
                            );
                        })}
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "left" }}>
                        <span style={{ fontWeight: "bold", color: "darkblue" }}>Errors:</span>
                        {errors.map((part) => {
                            return (
                                <span key={part.code + "_" + part.group_id + "_" + part.bikeClass + "_" + part.bike}><span style={{ color: "red", fontSize: "10" }}>{part.code} ({part.bike})</span><span>  {part.error} </span></span>
                            );
                        })}
                    </div>
                </Col>
            </Row>
            {/* <Button onClick={doImport} type="primary" disabled={running}>Import</Button> */}
            <Modal
                title="Cảnh báo?"
                visible = {needConfirm}
                onOk={()=>{setSyncData(true); setNeedConfirm(false);}}
                onCancel={(e)=>{setNeedConfirm(false);}}
                >
                    <p>Bạn đang chọn chế độ đồng bộ hóa toàn bộ. Các phụ tùng có trên cloud mà trong file excel không có sẽ bị xóa sạch.</p> 
                    <p>Hành động này sẽ làm mất dữ liệu cũ, và không thể khôi phục!</p>
                    <br/>
                    <p>Bạn có chắc chắn muốn thực hiện hành động này không</p>
                </Modal>
        </div>
    );
}
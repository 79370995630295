import { InputField, INPUT_TYPE } from './InputObject';
import UIObject from './UIObject';
import * as Converter from '../common/helpers/Converter';

export default class BikeClass extends UIObject {
    constructor(data) {
        var mappedData = {};
        var bikeName = (data && data.bike && data.brand) ? data.brand + " / " + data.bike : "";
        mappedData = { bikeName: bikeName, ...data };

        console.log("================ Bike class: ", mappedData);
        if (data && data.bike && data.brand && Array.isArray(data.bike) == false) {
            console.log("*** WTF: ", data.bike);
            mappedData.bike = [data.brand, data.bike];
        }
        super(mappedData);

        this.bikeName = new InputField("bikeName", mappedData ? mappedData.bikeName : "");
        this.bikeName.setProps({
            label: "Đời xe",
        });
        if (mappedData && mappedData.bikeName)
            this.addLockedField("bikeName");
        else
            this.addHiddenField("bikeName");

        this.logo = new InputField("logo", "");
        this.logo.setProps({
            label: "Logo",
            required: true,
        });
        this.logo.dataType = INPUT_TYPE.LOGO;

        this.bike = new InputField("bike", mappedData ? mappedData.bike : []);
        console.log("*** Cascader bike value 1: ", this.bike.value);
        this.bike.setProps({
            required: true,
            label: "Đời xe",
            placeholder: "Chọn: Thương hiệu / Loại xe",
        });
        this.bike.convertToCascader([{ label: "Kawasaki", value: "kawasaki" }]);

        this.name = new InputField("name", "Brand's name");
        this.name.setProps({
            required: true,
            placeholder: "Hãy nhập tên phiên bản mới",
            label: "Tên phiên bản",
        });


        this.description = new InputField("description", "description default");
        this.description.setProps({
            label: "Mô tả",
            placeholder: "Hãy nhập mô tả về phiên bản này",
        });

        this.year = new InputField("year", "");
        this.year.setProps({
            label: "Năm sản xuất",
            placeholder: "Nhập các năm ra mắt"
        });
        this.year.convertToMultiSelect(Converter.getLatest10Years());

        this.tag = new InputField("tag", "");
        this.tag.setProps({
            label: "Tag",
            placeholder: "Nhập các từ khóa liên quan"
        });
        this.tag.convertToMultiSelect([Converter.getLatest10Years()[0]]);

        this.addHiddenField("brand");
        this.addHiddenField("key");
        this.mapData();
    }

    setBikes = (bikeOptions) => {
        console.log("BIKE OPTIONS: ", bikeOptions);
        this.bike.convertToCascader(bikeOptions);
        this.mapData();
    }

    setBike = (brand, bike) => {
        this.bike.addProp({ defaultValue: [brand, bike] })
        this.mapData();
    }
}
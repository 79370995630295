import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
// import EditableCell, { EditableContext } from '../components/EditalbeCell';
import { Table, Tag, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Popconfirm, message } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext, GroupContext } from '../../App';
import NewGroupComponent from './NewGroupComponent';
import EditableObject from '../components/EditableObject';
import * as Converter from '../../common/helpers/Converter';
import * as CustomAntd from './antd-modified.css';
import Bike from '../../objects/Bike';
import PartGroup from '../../objects/PartGroup';

import SearchGroupComponent from './SearchGroupComponent';

function GroupScreen(props) {
    const user = useContext(UserContext);
    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    // const allClasses = useContext(ClassContext);
    // const allGroups = useContext(GroupContext);

    const [classOptions, setClassOptions] = useState(Converter.getCascaderBike(allBrands, allBikes));

    var [editingGroup, setEditingGroup] = useState(null);
    // var [groups, setGroups] = useState([]);
    // var [classes, setClasses] = useState([]);
    var [bikes, setBikes] = useState([]);
    // var [brands, setBrands] = useState([]);
    var [pagination, setPagination] = useState({ position: 'both' });
    var [searchText, setSearchText] = useState("");
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState("");

    const columns = [
        {
            title: '#',
            key: 'key',
            width: "10%",
            render: (record) => <a onClick={() => editGroup(record)} style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{record.key}</a>,
        },
        {
            title: 'Logo',
            key: 'logo',
            width: "10%",
            editable: true,
            render: (record) => <div
                style={{ padding: 0, backgroundImage: `url(${record.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "8vmin", height: "8vmin" }}
                className="logo_team"
            />
        },
        {
            title: 'Tên group',
            key: 'name',
            width: "20%",
            render: (record) => <a onClick={() => editGroup(record)} style={{ maxWidth: "15vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }} href="#">{record.name}</a>,
        },
        {
            title: 'Thương Hiệu',
            key: 'brand',
            width: "10%",
            render: (record) => <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{record.brand}</span>,
        },
        {
            title: 'Xe',
            key: 'bike',
            width: "10%",
            render: (record) => <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{record.bike}</span>,
        },
        {
            title: 'Class',
            key: 'bikeClass',
            width: "10%",
            render: (record) => <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{(Array.isArray(record.bikeClass) && record.bikeClass.length == 3) ? record.bikeClass[2] : record.bikeClass}</span>,
        },
        {
            title: 'Tags',
            key: 'tag',
            width: "15%",
            render: record => {
                return (record.tag && Array.isArray(record.tag)) ? (
                    <span style={{ maxWidth: "12vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                        {record.tag.map(tg => {
                            return (
                                <Tag key={tg}>
                                    {tg}
                                </Tag>
                            );
                        })}
                    </span>) : null
            },
        },
        {
            title: 'Action',
            width: "15%",
            render: (text, record) => (
                <div>
                    <Button onClick={() => editGroup(record)} type="primary">
                        <EditFilled />
                    </Button>
                    &nbsp;
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa nhóm này?"
                        onConfirm={() => deleteGroup(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger">
                            <DeleteFilled />
                        </Button>
                    </Popconfirm>

                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     setGroups(allGroups);
    // }, [allGroups]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            // setBikes(allBikes);
            retriveClassOptions(allBrands, allBikes);
        }
    }, [allBikes]);

    useEffect(() => {
        if (allBrands && allBrands.length > 0) {
            // setBrands(allBrands);
            retriveClassOptions(allBrands, allBikes);
        }
    }, [allBrands]);

    // useEffect(() => {
    //     if (allClasses && allClasses.length > 0) {
    //         setClasses(allBrands);
    //         retriveClassOptions(allBrands, allBikes, allClasses);
    //     }
    // }, [allClasses]);

    const retriveClassOptions = (inputBrands, inputBikes) => {
        if (inputBrands.length > 0 && inputBikes.length > 0) {
            var classOptionsObject = Converter.getCascaderBike(inputBrands, inputBikes);
            setClassOptions(classOptionsObject);
            console.log("Set Class option: ", classOptionsObject);
        }
    }

    const handlePaginationChange = e => {
        const { value } = e.target;
        setPagination(value === 'none' ? false : { position: value });
    };

    const cancel = () => {
        setEditingGroup(null);
    };

    const deleteGroup = (group) => {
        console.log("Delete group: ", group);

        db.collection(COLLECTIONS.BRAND).doc(group.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(group.bike)
        .collection(COLLECTIONS.SUB_CLASS).doc(group.bikeClass.replace("/","_"))
        .collection(COLLECTIONS.SUB_GROUP).doc(`${group.key}`).delete().then(function() {
            message.success('Xóa nhóm "' + group.name + '" thành công!');
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            message.error('Xóa nhóm "' + group.name + '" thất bại. Hãy thử lại sau!');
        });

        setData(data.filter(p => {
            if (p && p.key != group.key)
                return true;
            else return false;
        }));
    }

    const editGroup = (group) => {
        console.log("Edit group: ", group);
        var selectedGroup = new PartGroup(group);
        selectedGroup.setClasses(classOptions);
        selectedGroup.addLockedField("bikeClass");
        setEditingGroup(selectedGroup);
    }

    const updateGroup = async (groupData) => {
        console.log("group update data: ", groupData);
        if (data && data.length > 0 && groupData.name !== editingGroup.getOriginData().name && data.filter(b => { return b.name.toLowerCase() === groupData.name.toLowerCase() }).length > 0) {
            setError("Group name [" + groupData.name + "] is existed");
            return;
        }

        //Update client

        //Get origin brand & bike value
        var newGroup = groupData;
        let brand = newGroup.brand;
        let bike = newGroup.bike;
        let bikeClass = newGroup.bikeClass;
        if (newGroup.bikeClass && Array.isArray(newGroup.bikeClass)) {
            if (newGroup.bikeClass.length > 0)
                brand = newGroup.bikeClass[0];
            if (newGroup.bike.length > 1)
                bike = newGroup.bikeClass[1];
            if (newGroup.bikeClass.length == 3)
                bikeClass = newGroup.bikeClass[2];
        }
        if (brand)
            newGroup.brand = brand;
        if (bike)
            newGroup.bike = bike;
        if (bikeClass)
            newGroup.bikeClass = bikeClass;

        //Insert new group into list group at client
        const oldData = data;
        const newData = [...data];
        const index = newData.findIndex(item => newGroup.key === item.key);
        if (index > -1) {
            newData.splice(index, 1, newGroup);
        } else {
            newData.push(newGroup);
        }
        setData(newData);

        //Save to server
        try {
            console.log("update GROUP: ", newGroup);
            // delete newGroup.bikeClass;
            db.collection(COLLECTIONS.BRAND).doc(newGroup.brand)
                .collection(COLLECTIONS.SUB_BIKE).doc(newGroup.bike)
                .collection(COLLECTIONS.SUB_CLASS).doc(newGroup.bikeClass.replace('/', '_'))
                .collection(COLLECTIONS.SUB_GROUP).doc(`${newGroup.key}`).update(newGroup);
        } catch (error) {
            //Recover old data
            setData(oldData);
            console.log('error', error);
        }

        setIsLoading(false);
        resetEditingGroup();
    }

    function resetEditingGroup() {
        setEditingGroup(null);
        console.log("ClearData");
    }

    const [originData, setOriginData] = useState([]);
    const [data, setData] = useState([]);

    const onResult = (results) => {
        setOriginData(results);
        if (searchText) {
            // console.log("New Text search: ", results);
            setData(Search.searchInObject(results, searchText));
        } else {
            // console.log("Text search empty: ", results);
            setData(results);
        }
    }

    useEffect(() => {
        // console.log("Change text search: ", searchText);
        setData(Search.searchInObject(originData, searchText));
    }, [searchText]);

    // const dataSource = Search.searchInObject(groups, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                // inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                // dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    return (
        <div>
            <h1>Quản lý Nhóm phụ tùng</h1>
            <NewGroupComponent classOptions={classOptions} isLoading={false} searchListener={(text) => { setSearchText(text) }} />

            <SearchGroupComponent limit={100} onResult={onResult} onFilter={(text) => { setSearchText(text) }} />

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh Sách Nhóm</span>
                <Table
                    columns={mapColumns}
                    dataSource={data}
                    loading={data == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key + "_" + record.group_id + "_" + record.bikeClass + "_" + record.bike + "_" + record.brand)}
                />
            </div>

            <EditableObject
                input={editingGroup}
                onCancel={resetEditingGroup}
                onSubmit={updateGroup}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin Nhóm phụ tùng" />
        </div>
    );
}

// const GroupScreen = Form.create({ name: 'group' })(GroupForm);
export default GroupScreen;
import React, {useContext} from 'react';
import { Col, Row} from 'antd';
import { UserContext } from '../../App';

const KEY_NAME = {
    address: "Địa chỉ",
    email: "Email",
    name: "Họ Tên",
    phone: "Số điện thoại",
}

export default function Profile (props) {
    const user = useContext(UserContext);

    return (
        <div style={{paddingTop:"20px"}}>
            <h1>User profile</h1>
            {
                user?
                Object.keys(user).map((key)=> {
                    if(KEY_NAME.hasOwnProperty(key))
                        return(
                            <Row key={key}>
                                <Col span={4}><span>{KEY_NAME[key]}:</span></Col> 
                                <Col span={20}><span>{user[key]}</span></Col>
                            </Row>
                        );
                })
                :
                <span>User not found</span>
            }
        </div>
    );
}
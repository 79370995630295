import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import EditableCell, { EditableContext } from '../components/EditalbeCell';
import { Table, Tag, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Popconfirm, message } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext } from '../../App';
import NewClassComponent from './NewClassComponent';
import EditableObject from '../components/EditableObject';
import * as Converter from '../../common/helpers/Converter';
import * as CustomAntd from './antd-modified.css';
import Bike from '../../objects/Bike';
import BikeClass from '../../objects/BikeClass';
import SearchClassComponent from './SearchClassComponent';


function ClassScreen(props) {
    const user = useContext(UserContext);
    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    // const allClasses = useContext(ClassContext);

    const [bikeOptions, setBikeOptions] = useState(Converter.getCascaderBike(allBrands, allBikes));

    var [editingClass, setEditingClass] = useState(null);
    // var [classes, setClasses] = useState([]);
    // var [bikes, setBikes] = useState([]);
    // var [brands, setBrands] = useState([]);
    var [pagination, setPagination] = useState({ position: 'both' });
    var [searchText, setSearchText] = useState("");
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState("");
    // var [newImage, setNewImage] = useState([]);
    // var [newImageUrl, setNewImageUrl] = useState("");

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            width: "8%",
            editable: true,
            render: (text) => <div
                style={{ padding: 0, backgroundImage: `url(${text})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "8vmin", height: "8vmin" }}
                className="logo_team"
            />
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
            width: "10%",
            render: (text, record) => <a onClick={() => editClass(record)} style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            width: "15%",
            render: (text, record) => <span style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>
        },
        {
            title: 'Thương Hiệu',
            dataIndex: 'brand',
            key: 'brand',
            width: "15%",
            render: (text, record) => <span style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>
        },
        {
            title: 'Xe',
            dataIndex: 'bike',
            key: 'bike',
            width: "12%",
            render: (text, record) => <span style={{ maxWidth: "8vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>
        },
        {
            title: 'Years',
            dataIndex: 'year',
            key: 'year',
            width: "10%",
            render: tag => {
                return (tag && Array.isArray(tag)) ? (
                    <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                        {tag.map(tag => {
                            return (
                                <Tag key={tag}>
                                    {tag}
                                </Tag>
                            );
                        })}
                    </span>) : null
            },
        },
        {
            title: 'Tags',
            dataIndex: 'tag',
            key: 'tag',
            width: "10%",
            render: tag => {
                return (tag && Array.isArray(tag)) ? (
                    <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                        {tag.map(tag => {
                            return (
                                <Tag key={tag}>
                                    {tag}
                                </Tag>
                            );
                        })}
                    </span>) : null
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "15%",
            render: (text, record) => (
                <div>
                    <Button onClick={() => editClass(record)} type="primary">
                        <EditFilled />
                    </Button>
                    &nbsp;
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa phiên bản này?"
                        onConfirm={() => deleteClass(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                    <Button type="danger">
                        <DeleteFilled />
                    </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    // const hasNewImage = ({ fileList }) => {
    //     if (fileList.length > 0) {
    //         ImageHelper.getBase64(fileList[0].originFileObj, newImageUrl =>
    //             setNewImageUrl(newImageUrl)
    //         );
    //     }
    //     setNewImage(fileList);
    //     console.log("Logo change: ", fileList);
    // };

    // useMemo(()=>{
    //     setAllClassess(classes);
    // }, [classes, setAllClassess])

    // useEffect(() => {
    //     setClasses(allClasses);
    // }, [allClasses]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            // setBikes(allBikes);
            if (allBrands.length > 0) {
                var bikeOptions = Converter.getCascaderBike(allBrands, allBikes);
                setBikeOptions(bikeOptions);
                console.log("SetBikeOption: ", bikeOptions);
            }
        }
    }, [allBikes]);

    useEffect(() => {
        if (allBrands && allBrands.length > 0) {
            // setBrands(allBrands);
            if (allBikes.length > 0) {
                var bikeOptions = Converter.getCascaderBike(allBrands, allBikes);
                setBikeOptions(bikeOptions);
                console.log("SetBikeOption: ", bikeOptions);
            }
        }
    }, [allBrands]);

    const handlePaginationChange = e => {
        const { value } = e.target;
        setPagination(value === 'none' ? false : { position: value });
    };

    const cancel = () => {
    };

    const deleteClass = (item) => {
        db.collection(COLLECTIONS.BRAND).doc(item.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(item.bike)
        .collection(COLLECTIONS.SUB_CLASS).doc(item.key.replace("/","_")).delete().then(function() {
            message.success('Xóa phiên bản "' + item.name + '" thành công!');
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            message.error('Xóa phiên bản "' + item.name + '" thất bại. Hãy thử lại sau!');
        });

        setData(data.filter(p => {
            if (p && p.key != item.key)
                return true;
            else return false;
        }));
    }

    const editClass = (bikeClass) => {
        console.log("Edit group: ", bikeClass);
        var selectedClass = new BikeClass(bikeClass);
        selectedClass.setBikes(bikeOptions);
        selectedClass.addHiddenField("bike");
        selectedClass.addLockedField("bikeName");
        setEditingClass(selectedClass);
    }

    const updateClass = async (classData) => {
        console.log("group update data: ", classData);
        //Update client

        //Get origin brand & bike value
        var newClass = classData;
        let brand = newClass.brand;
        let bike = newClass.brand;
        if (newClass.bike && Array.isArray(newClass.bike)) {
            if (newClass.bike.length > 0)
                brand = newClass.bike[0];

            if (newClass.bike.length == 2)
                bike = newClass.bike[1];
        }
        if (brand)
            newClass.brand = brand;
        if (bike)
            newClass.bike = bike;

        //Insert new group into list group at client
        const oldData = data;
        const newData = [...data];
        const index = newData.findIndex(item => newClass.key === item.key);
        if (index > -1) {
            newData.splice(index, 1, newClass);
        } else {
            newData.push(newClass);
        }
        setData(newData);

        //Save to server
        try {
            db.collection(COLLECTIONS.BRAND).doc(newClass.brand)
                .collection(COLLECTIONS.SUB_BIKE).doc(newClass.bike)
                .collection(COLLECTIONS.SUB_CLASS).doc(newClass.key).update(newClass);
        } catch (error) {
            //Recover old data
            setData(oldData);
            console.log('error', error);
        }

        setIsLoading(false);
        setEditingClass(null);
    }

    function onCreateSuccess(newBikeClass) {
        let allBikeClass = data;
        allBikeClass.push(newBikeClass);
        console.log("All bike classes", allBikeClass);
        setData(allBikeClass);
        setSearchText("");
    }

    useEffect(() => {
        // console.log("Change text search: ", searchText);
        setData(Search.searchInObject(originData, searchText));
    }, [searchText]);

    const [originData, setOriginData] = useState([]);
    const [data, setData] = useState([]);

    const onResult = (results) => {
        setOriginData(results);
        if (searchText) {
            // console.log("New Text search: ", results);
            setData(Search.searchInObject(results, searchText));
        } else {
            // console.log("Text search empty: ", results);
            setData(results);
        }
    }

    // const dataSource = Search.searchInObject(classes, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                // inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                // dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    return (
        <div source={data}>
            <h1>Quản Phiên bản xe</h1>
            <NewClassComponent bikeOptions={bikeOptions} isLoading={false} searchListener={(text) => { setSearchText(text) }} onCreateSuccess={onCreateSuccess} />

            <SearchClassComponent limit={50} onResult={onResult} onFilter={(text) => { setSearchText(text) }} />

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh sách Phiên bản xe </span>
                <Table
                    columns={mapColumns}
                    dataSource={data}
                    loading={data == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key + "_" + record.bike + "_" + record.brand)}
                />
            </div>

            <EditableObject
                input={editingClass}
                onCancel={() => { setEditingClass(null) }}
                onSubmit={updateClass}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin phiên bản" />
        </div>
    );
}

// const ClassScreen = Form.create({ name: 'class' })(ClassForm);
export default ClassScreen;
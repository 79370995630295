import React from 'react'
import { Menu } from 'antd';
import { GlobalOutlined, HomeFilled, DatabaseFilled, ImportOutlined, ExportOutlined, ProfileOutlined, ShoppingCartOutlined, UserOutlined, SettingFilled, UnorderedListOutlined, DollarCircleFilled, LogoutOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { fb } from '../../common/firebase/Firestore';
import 'antd/dist/antd.css';
import * as PATH from '../../common/constant/path';

const { SubMenu } = Menu;

export default function SideMenu(props) {

  const onMenuClick = () => {
    console.log('onMenuClick')
    props.onMenuClick && props.onMenuClick()
  };

  const signOut = () => {
    onMenuClick();
    fb.auth().signOut().then(function() {
      // Sign-out successful.
      console.log("Sign-out successful");
    }).catch(function(error) {
      // An error happened.
      console.log("Sign-out error: ", error);
    });
  }

  return (
    < Menu
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['management', 'sub2']}
      mode="inline"
      theme="dark"
    >
      <Menu.Item key="1">
        <Link to={PATH.HOME}>
        <HomeFilled />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="database"
        title={
          <span>
            <DatabaseFilled />
            <span>Quản lý dữ liệu</span>
          </span>
        }
      >
        <Menu.Item key="2" onClick={onMenuClick} ><Link to={PATH.IMPORT}> <ImportOutlined /><span>Import</span></Link></Menu.Item>
        <Menu.Item key="3" onClick={onMenuClick}><Link to={PATH.EXPORT}> <ExportOutlined /><span>Export</span></Link></Menu.Item>
      </SubMenu>

      <SubMenu
        key="product"
        title={
          <span>
            <ProfileOutlined />
            <span>Thông tin sản phẩm</span>
          </span>
        }
      >
        <Menu.Item key="5" onClick={onMenuClick}><Link to={PATH.BRAND}>Nhà cung cấp</Link></Menu.Item>
        <Menu.Item key="6" onClick={onMenuClick}><Link to={PATH.BIKE}>Xe</Link></Menu.Item>
        <Menu.Item key="7" onClick={onMenuClick}><Link to={PATH.CLASS}>Phiên bản</Link></Menu.Item>
        <Menu.Item key="8" onClick={onMenuClick}><Link to={PATH.PART_GROUP}>Nhóm</Link></Menu.Item>
        <Menu.Item key="9" onClick={onMenuClick}><Link to={PATH.PART}>Phụ tùng</Link></Menu.Item>
      </SubMenu>
      <SubMenu key="order" title={
        <span> <ShoppingCartOutlined /> <span>Quản lý đơn hàng</span> </span>
      }
      >
        <Menu.Item key="10" onClick={onMenuClick}> <Link to={PATH.ORDER}>Đơn hàng</Link> </Menu.Item>
        <Menu.Item key="21" onClick={onMenuClick}> <Link to={PATH.SHIP_REQUEST}>Yêu cầu giao hàng</Link> </Menu.Item>
        <Menu.Item key="11" onClick={onMenuClick}> <Link to={PATH.INVENTORY}>Hàng tồn kho</Link> </Menu.Item>
        <Menu.Item key="12" onClick={onMenuClick}> <Link to={PATH.RECEIVED}>Nhập hàng</Link> </Menu.Item>
      </SubMenu>
      <SubMenu key="customer" title={
        <span> <UserOutlined /> <span>Khách hàng</span> </span>
      }
      >
        <Menu.Item key="15" onClick={onMenuClick}> <Link to={PATH.CUSTOMER}>Thông tin</Link> </Menu.Item>
        <Menu.Item key="20" onClick={onMenuClick}> <Link to={PATH.ASKING}>Nhu cầu</Link> </Menu.Item>
        <Menu.Item key="16" onClick={onMenuClick}> <Link to={PATH.CUSTOMER}>Thống kê</Link> </Menu.Item>
        <Menu.Item key="17" onClick={onMenuClick}> <Link to={PATH.CUSTOMER}>Chặn</Link> </Menu.Item>
      </SubMenu>

      <SubMenu
        key="config"
        title={
          <span>
            <GlobalOutlined /> 
            <span>Cấu hình website</span>
          </span>
        }
      >
        <Menu.Item key="18" onClick={onMenuClick} ><Link to={PATH.CONTENT}> <UnorderedListOutlined /><span>Nội dung</span></Link></Menu.Item>
        <Menu.Item key="19" onClick={onMenuClick}><Link to={PATH.BANK}> <DollarCircleFilled /><span>Ngân hàng</span></Link></Menu.Item>
      </SubMenu>

      <Menu.Item key="13" onClick={onMenuClick}><Link to={PATH.SETTING}><SettingFilled /><span>Setting</span></Link></Menu.Item>
      <Menu.Item key="14" onClick={signOut}><LogoutOutlined /><span>Logout</span></Menu.Item>
    </Menu >
  );
}
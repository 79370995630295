import React, { useState, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import { db } from '../../common/firebase/Firestore';
import { Form, Button, Cascader, Modal, Input, Upload, Select, InputNumber, Switch } from 'antd';
import { LoadingOutlined, UploadOutlined, PlusSquareTwoTone } from '@ant-design/icons';
import * as InputObject from '../../objects/InputObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import CascaderInput from '../components/CascaderInput';
import 'antd/dist/antd.css';
import * as Converter from '../../common/helpers/Converter';
import { isMobile } from 'react-device-detect';
const { Option } = Select;

const EditableObject = (props) => {
    const [form] = Form.useForm();

    // var dataSource = props.source;
    const submit_label = props.submitButton ? props.submitButton : "Submit";
    var onChange = props.onChange;
    var onCancel = props.onCancel;
    var onSubmit = props.onSubmit;
    var onFinish = props.onFinish;
    var uiObject = props.input;
    var title = props.title;
    var error = props.error;
    var [showingModal, setShowingModal] = useState(false);
    // var inputUI = (uiObject && uiObject.inputUI()) ? uiObject.inputUI() : null;
    const [inputUI, setInputUI] = useState(null);
    var [data, setData] = useState(uiObject ? uiObject.data() : {});
    // var [originData, setOriginData] = useState(uiObject ? uiObject.data() : {});
    var [disable, setDisable] = useState(false);
    var [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        if (uiObject) {
            setData(uiObject.data());
            setInputUI((uiObject && uiObject.inputUI()) ? uiObject.inputUI() : {});
            form.setFieldsValue(uiObject.getOriginData());
            setShowingModal(true);
        } else {
            // form.resetFields();
            setData(null);
            form.setFieldsValue({});
            // setInputUI({});
            setShowingModal(false);
        }
    }, [uiObject])

    const onChangeData = (key, value) => {
        console.log("On change Data value: ", value);
        var newData = data;
        newData[key] = value;
        // console.log("New Data: ", newData);
        if (uiObject) uiObject.onChange(newData);
        onChange && onChange(newData);
        setData(newData);
    }

    const hiddenInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        // console.log("HIDDEN FIELD: ", mapProps.defaultValue);

        return (
            <Form.Item key={key} style={{ lineHeight: 0, marginBottom: 0 }}>
            </Form.Item>
        );
    }

    const lockedInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        // console.log("HIDDEN FIELD: ", mapProps.defaultValue);

        return (
            <Form.Item label={mapProps.label} key={key} style={{ lineHeight: 1, marginBottom: 0 }}>
                <span style={{ color: "darkgray", lineHeight: 1, marginBottom: 0 }}>
                    {
                        Array.isArray(mapProps.defaultValue) ?
                            mapProps.defaultValue.join(" / ")
                            : mapProps.defaultValue.toString()
                    }
                </span>
            </Form.Item>
        );
    }

    const textInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        if (key === "name")
            console.log("Text default: ", mapProps.defaultValue);
        // console.log("Text key: ", key);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                rules={[
                    {
                        required: mapProps.required,
                        message: mapProps.require_message,
                    },
                    ...mapProps.rules
                ]}
            // initialValue={mapProps.defaultValue}
            >
                <Input
                    placeholder={mapProps.placeholder}
                    style={mapProps.style}
                    value="abcde"
                    onChange={(e) => { onChangeData(key, e.target.value) }}
                />
            </Form.Item>
        );
    }

    const selectInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        const mode = fprops.mode ? fprops.mode : "default";

        var options = [{ key: "empty", label: "No Data" }];

        if (fprops && fprops.options && Array.isArray(fprops.options) && fprops.options.length > 0)
            options = fprops.options;

        var defaultValue = options[0].key ? options[0].key : options[0];
        if (fprops && fprops.defaultValue)
            defaultValue = fprops.defaultValue;

        //require, rules
        return (
            <Form.Item label={mapProps.label} hasFeedback key={key}
                name={key}
                initialValue={defaultValue ? defaultValue : (options[0].key ? options[0].key : options[0])}
                rules={[
                    {
                        required: mapProps.required, message: mapProps.require_message
                    },
                    ...mapProps.rules
                ]}
            >
                <Select placeholder={mapProps.placeholder} onChange={(e) => { onChangeData(key, e) }} mode={mode}>
                    {
                        options.map((item) => {
                            // console.log(" ===== ITEM: ", item);
                            const itemKey = item.hasOwnProperty("key") ? item.key : item;
                            // console.log("ITEM KEY: ", itemKey);
                            const label = item.label ? item.label : item;
                            return (
                                <Option value={itemKey} key={itemKey}>{label}</Option>
                            );
                        })
                    }
                </Select>
            </Form.Item>
        );
    }

    const numberInput = (key, fprops) => {
        // console.log("Number input props: ", fprops);
        const mapProps = Converter.getBasicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                initialValue={mapProps.defaultValue ? mapProps.defaultValue : 0}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message },
                    ...mapProps.rules
                ]}
            >
                <InputNumber min={0} precision={0} onChange={(e) => { onChangeData(key, e) }} />
            </Form.Item>
        );
    }

    const currencyInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                initialValue={{ number: mapProps.defaultValue, currency: fprops.currencyType }}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please input ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <PriceInput onChange={(e) => { onChangeData(key, e) }} />
            </Form.Item>
        );
    }

    const switchInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                initialValue={mapProps.defaultValue}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please input ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <Switch defaultChecked={mapProps.defaultValue} onChange={(e) => { onChangeData(key, e) }} />
            </Form.Item>
        );
    }

    const handleChangeUploadLogo = (keyName, info) => {
        // if (info.file.status === 'uploading') {
        //     setIsLoading(true);
        //     return;
        // }
        // if (info.file.status === 'done') {
        // Get this url from response in real world.
        ImageHelper.getBase64(info.file.originFileObj, imageUrl => {
            onChangeData(keyName, imageUrl);
            // setIsLoading(false);
        });
        // }
        // console.log("Upload DONE");
        return info.file;
    };

    const imageInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        var currentLogo = fprops ? (fprops.value ? fprops.value : null) : null;
        if (data && data[key])
            currentLogo = data[key];

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please select ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <Upload
                    name={key}
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // action="https://run.mocky.io/v3/d610960c-f87f-4f83-be87-f932b8ac227a"
                    action={null}
                    beforeUpload={ImageHelper.beforeUpload}
                    onChange={(e) => onChangeFileHandler(e, key)}
                >
                    {
                        currentLogo ?
                            <img src={currentLogo} alt="avatar" style={{ width: '100%' }} />
                            :
                            <div>
                                {isLoading ? <LoadingOutlined /> : <PlusSquareTwoTone />}
                                <div className="ant-upload-text">Upload</div>
                            </div>}
                </Upload>
            </Form.Item>
        );
    }

    const onChangeFileHandler = (event, key) => {
        console.log('Upload event:', event);
        if (Array.isArray(event)) {
            return event;
        }

        console.log("FILE: ", event.file);

        // if (file && file.status === 'uploading') {
        //     console.log('UPLOADINGGGGGG:');
        setIsLoading(true);
        // }
        // if (file && file.status === 'done') {
        console.log('UPLOAD DONEEEEEEEEEE');
        // Get this url from response in real world.
        ImageHelper.getBase64(event.file.originFileObj, imageUrl => {
            onChangeData(key, imageUrl);
            setIsLoading(false);
        });
        // const reader = new FileReader();
        //}
        // console.log("file ----: ", file);
        return event && event.fileList;
    };

    const fileInput = (key, fprops) => {
        const mapProps = Converter.getBasicProps(key, fprops);
        var currentLogo = fprops ? (fprops.value ? fprops.value : null) : null;
        if (data && data[key])
            currentLogo = data[key];

        return (
            <Form.Item label={mapProps.label} key={key}
                name={key}
                getValueFromEvent={(e) => onChangeFileHandler(e, key)}
                valuePropName="fileList"
                rules={[
                    { required: mapProps.required, message: mapProps.require_message ? mapProps.require_message : `Please select ${mapProps.label}!` },
                    ...mapProps.rules
                ]}
            >
                <Upload name={key} action={null} listType="picture">
                    <Button>

                        {
                            currentLogo ?
                                <img src={currentLogo} alt="avatar" style={{ width: '100%' }
                                } />
                                : <UploadOutlined />
                        }
                        Click to upload
                    </Button>
                </Upload>
            </Form.Item>
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                setDisable(true);
                submit();

                // form.resetFields();
            }
        });
    };
    const onSubmitForm = (values) => {
        console.log('Received values of form: ', values);
        setDisable(true);
        submit();
    };
    const onSubmitFormFailed = (err) => {
        console.log("EditableObject - Error: ", err)
    };

    const submit = async () => {
        setIsLoading(true);
        var success = false;
        if (onSubmit)
            success = await onSubmit(data);
        if (success) {
            await onFinishListener();
        }
        setIsLoading(false);
        setDisable(false);
    }

    const onFinishListener = async () => {
        onFinish && await onFinish();
        clearForm();
    }

    const clearForm = () => {
        setInputUI((uiObject && uiObject.inputUI()) ? uiObject.inputUI() : {});
        if (uiObject) {
            const originData = uiObject.getOriginData();
            uiObject.onChange(originData);
            onChange && onChange(originData);
            setData(null);
        }
        console.log("RESET FORM ============");
        form.resetFields();
    }

    // const { getFieldDecorator } = form;
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };

    const onCancelEdit = () => {
        uiObject && uiObject.revert();
        onCancel && onCancel();
        clearForm();
        setShowingModal(false);
    }

    return (
        <div>
            <Modal
                visible={showingModal}
                title={title}
                onCancel={onCancelEdit}
                width={isMobile ? "96%" : "80%"}
                footer={[
                    <span style={{ color: "red" }} key="Error">{error ? error : "."}</span>
                ]}
            >
                {(uiObject && inputUI && form != null) ?
                    <Form
                        onFinish={onSubmitForm}
                        onFinishFailed={onSubmitFormFailed}
                        initialValues={uiObject ? uiObject.getOriginData() : null}
                        form={form}
                        {...formItemLayout}>
                        {
                            Object.keys(inputUI).map((key) => {
                                const item = inputUI[key];
                                // console.log("Input Field Item: ", item);
                                let fieldInput = textInput(key, item.props);
                                if (!item.dataType)
                                    item.dataType = INPUT_TYPE.TEXT;
                                switch (item.dataType) {
                                    case INPUT_TYPE.LOCKED:
                                        fieldInput = lockedInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.HIDDEN:
                                        // fieldInput = hiddenInput(key, item.props);
                                        fieldInput = null;
                                        break;
                                    case INPUT_TYPE.NUMBER:
                                        fieldInput = numberInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.CURRENCY:
                                        fieldInput = currencyInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.SELECT:
                                        fieldInput = selectInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.LOGO:
                                        fieldInput = imageInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.SWITCH:
                                        fieldInput = switchInput(key, item.props);
                                        break;
                                    case INPUT_TYPE.CASCADER:
                                        fieldInput = <CascaderInput field={key} inputProps={item.props} onChange={onChangeData} form={form} />
                                        break;
                                    default:
                                        fieldInput = textInput(key, item.props);
                                        break;
                                }

                                return (
                                    <div key={key}>
                                        {fieldInput}
                                    </div>
                                );
                            })
                        }
                        <Button type="primary" htmlType="submit" style={{ width: "100%", height: "30px" }} disabled={disable} loading={isLoading} >
                            {submit_label}
                        </Button>
                    </Form>
                    : null
                }
            </Modal>
        </div>

    );
}

class PriceInput extends React.Component {
    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        const value = props.value || {};
        this.state = {
            number: value.number || 0,
            currency: value.currency || 'usd',
        };
    }

    handleNumberChange = e => {
        const number = parseInt(e.target.value || 0, 10);
        if (isNaN(number)) {
            return;
        }
        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    };

    handleCurrencyChange = currency => {
        if (!('value' in this.props)) {
            this.setState({ currency });
        }
        this.triggerChange({ currency });
    };

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            });
        }
    };

    render() {
        const { size } = this.props;
        const { currency, number } = this.state;
        return (
            <span>
                <Input
                    type="text"
                    size={size}
                    value={number}
                    onChange={this.handleNumberChange}
                    style={{ width: '65%', marginRight: '3%' }}
                />
                <Select
                    value={currency}
                    size={size}
                    style={{ width: '32%' }}
                    onChange={this.handleCurrencyChange}
                >
                    <Option value="usd">USD</Option>
                    <Option value="vnd">VND</Option>
                    <Option value="thb">THB</Option>
                </Select>
            </span>
        );
    }
}

// const EditableObject = Form.create({ name: 'Dynamic input form via input Object' })(InputForm);
export default EditableObject;
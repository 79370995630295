import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import { Table, Tag, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Upload, message, Spin, DatePicker, Select } from 'antd';
import { EditFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext } from '../../App';
import * as Converter from '../../common/helpers/Converter';
import * as Date from '../../common/helpers/Date';
import Customer from '../../objects/Customer';
import { useGetOrders, useGetCustomers } from '../../hooks/Customers';
import EditableObject from '../components/EditableObject';
import moment from 'moment';
import * as firebase from "firebase/app";
import Recharge from '../components/Recharge';
import WithDrawMoney from '../components/WithDrawMoney';
import CreateCustomerForm from './CreateCustomerForm';
import { isMobile } from 'react-device-detect';
// import * as COLOR from '../../common/constant/color';
import { convertPrice } from "../../common/helpers/Price";
import SearchField from "react-search-field";
import MoneyHistory from '../components/MoneyHistory';

const { Option } = Select;

function CustomerScreen(props) {

    const [editingCustomer, setEditingCustomer] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    var [refreshData, setRefreshData] = useState(false);

    const customers = useGetCustomers(1000, refreshData);

    const columns = !isMobile ? [
        {
            title: 'Tên Khách hàng',
            dataIndex: 'name',
            key: 'name',
            width: "20%",
            render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: "15%",
            render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: 'status',
            width: "15%",
            render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{Converter.getUserStatus(text)}</a>
        },
        {
            title: 'Cấp độ',
            dataIndex: 'level',
            key: 'level',
            width: "10%",
            render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
        },
        {
            title: 'Tiền',
            dataIndex: 'money',
            key: 'money',
            width: "10%",
            render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{convertPrice(text)}</a>
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: "30%",
            render: (text, record) => (
                <div style={{ display: "flex" }}>
                    <Button onClick={() => viewCustomer(record)} type="primary">
                        <EditFilled />
                        Quản lý
                    </Button>
                    <Recharge customer={record} onRechargeSuccess={onRechargeSuccess} onPrepare={() => { setRefreshData(false) }} />
                    <WithDrawMoney customer={record} onWithDrawSuccess={() => { setRefreshData(true) }} onPrepare={() => { setRefreshData(false) }} />
                    <MoneyHistory customer={record} onRechargeSuccess={onRechargeSuccess} onPrepare={() => { setRefreshData(false) }} />
                </div>
            ),
        },
    ]
        :
        [
            {
                title: 'Tên Khách hàng',
                dataIndex: 'name',
                key: 'name',
                width: "70%",
                render: (text, record) => <a onClick={() => viewCustomer(record)} style={{ maxWidth: "70vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                    {text} - {record.phone} <br />
                    <b>{record.money ? convertPrice(record.money) : "0"}</b> VNĐ
                </a>,
            },
            {
                title: 'Thao tác',
                dataIndex: 'action',
                width: "30%",
                render: (text, record) => (
                    <div style={{ display: "flex" }}>
                        <Button onClick={() => viewCustomer(record)} type="primary">
                            <EditFilled />
                        </Button>
                        <Recharge customer={record} onRechargeSuccess={onRechargeSuccess} onPrepare={() => { setRefreshData(false) }} />
                        {/* <WithDrawMoney customer={record} onWithDrawSuccess={() => { setRefreshData(true) }} onPrepare={() => { setRefreshData(false) }} /> */}
                    </div>
                ),
            },
        ];

    const cancel = () => {
        // setModalDetail(false);
        setEditingCustomer(null);

    };

    const onRechargeSuccess = () => {
        setRefreshData(true);
    }

    const viewCustomer = (user) => {
        console.log("Edit Order: ", user);
        var customer = new Customer(user);
        setEditingCustomer(customer);
        setRefreshData(false);
        // setModalDetail(true);
    }

    const updateCustomer = async (customerData) => {
        console.log("group update data: ", customerData);
        //Save to server
        try {
            db.collection(COLLECTIONS.USER).doc(customerData.uid).update(customerData);

            //Check user status: if user is blocked, save to block list
            var uidRef = await db.collection(COLLECTIONS.BLOCKED_LIST).doc(customerData.uid).get();
            console.log("BLOCK REF", uidRef);
            if (customerData.status == 4) {
                if (!uidRef.exists) {
                    await db.collection(COLLECTIONS.BLOCKED_LIST).doc(customerData.uid).set(customerData);
                }
            } else if (uidRef.exists) {
                await db.collection(COLLECTIONS.BLOCKED_LIST).doc(customerData.uid).delete();
            }

            setRefreshData(true);
        } catch (error) {
            //Recover old data
            console.log('error', error);
        }

        setIsLoading(false);
        setEditingCustomer(null);
    }

    const dataSource = Search.searchInObject(customers, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    return (
        <div>
            <h1>Quản lý Khách hàng</h1>

            <div style={{display:"flex", justifyContent:"space-between"}}>
                <CreateCustomerForm />

                <SearchField
                    placeholder="Search..."
                    onChange={(e) => setSearchText(e)}
                    placeholder="Tìm khách hàng"
                    searchText=""
                />
            </div>
            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh sách Khách hàng </span>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource != null ? dataSource : []}
                    loading={dataSource == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key)}
                />
            </div>

            <EditableObject
                input={editingCustomer}
                onCancel={() => { setEditingCustomer(null) }}
                onSubmit={updateCustomer}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin khách hàng" />
        </div>
    );
}

// const CustomerScreen = Form.create({ name: 'class' })(CustomerForm);
export default CustomerScreen;
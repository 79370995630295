import {InputField, INPUT_TYPE} from './InputObject';
import UIObject from './UIObject';
import * as Converter from '../common/helpers/Converter';

export default class Part extends UIObject {
    constructor(data) {
        var mappedData = data;
        if(data && data.brand && data.bike && data.bikeClass && data.group_name) {
            mappedData.group = [data.brand, data.bike, data.bikeClass, data.group_name];
        }
        super(mappedData);

        this.group = new InputField("group", mappedData?mappedData.group:[]) ;
        this.group.setProps({
            required:true,
            label:"Nhóm phụ tùng",
            placeholder: "Chọn: Thương hiệu / Loại xe / Phiên bản / Nhóm phụ tùng",
        });
        this.group.convertToCascader([{label: "Kawasaki", value: "kawasaki"}]);

        this.name= new InputField("name", "Group's name");
        this.name.setProps({
            placeholder: "Hãy nhập tên phụ tùng",
            label:"Tên phụ tùng",
        });

        this.ref_code= new InputField("ref_code", "Ref code");
        this.ref_code.setProps({
            required:true,
            placeholder: "Hãy nhập mã Ref code",
            label:"Ref code",
        });

        this.code= new InputField("code", "Part's code");
        this.code.setProps({
            required:true,
            placeholder: "Hãy nhập mã phụ tùng",
            label:"Mã phụ tùng",
        });

        this.inventory= new InputField("inventory", 0);
        this.inventory.setProps({
            label:"Hàng tồn kho",
            defaultValue:0
        });

        this.out_date= new InputField("out_date", false);
        this.out_date.setProps({
            label:"Ngừng sản xuất",
            defaultValue:false,
        });
        this.old_code= new InputField("old_code", "");
        this.old_code.setProps({
            placeholder: "Nhập mã phụ tùng cũ tương ứng (đã ngừng sản xuất)",
            label:"Mã phụ tùng cũ",
        });

        this.repeat= new InputField("repeat", 1);
        this.repeat.setProps({
            label:"Số lượng cần thiết trên xe",
            defaultValue:1
        });


        this.price_thai= new InputField("price_thai", 0);
        this.price_thai.setProps({
            label:"Giá bên Thái",
        });
        this.price_thai.convertToCurrency("thb");

        this.price_thai_tax= new InputField("price_thai_tax", 0);
        this.price_thai_tax.setProps({
            label:"Giá Thái sau thuế",
        });
        this.price_thai_tax.convertToCurrency("thb");

        this.price_brand= new InputField("price_brand", 0);
        this.price_brand.setProps({
            label:"Giá nhà cung cấp",
        });
        this.price_brand.convertToCurrency("thb");

        this.price_retail= new InputField("price_retail", 0);
        this.price_retail.setProps({
            label:"Giá bán lẻ",
        });
        this.price_retail.convertToCurrency("vnd");

        this.price_vn= new InputField("price_vn", 0);
        this.price_vn.setProps({
            label:"Giá Việt Nam",
        });
        this.price_vn.convertToCurrency("vnd");

        this.replaced = new InputField("replaced", "");
        this.replaced.setProps({
            label:"Phụ tùng tương tự",
            placeholder: "Nhập những sản phẩm có thể thay thế"
        });
        this.replaced.convertToMultiSelect([]);

        this.des_en= new InputField("des_en", "");
        this.des_en.setProps({
            placeholder: "Hãy nhập mô tả bằng Tiếng Anh",
            label:"Mô tả bằng tiếng Anh",
        });

        this.des_vn= new InputField("des_vn", "");
        this.des_vn.setProps({
            placeholder: "Hãy nhập mô tả bằng Tiếng Việt",
            label:"Mô tả bằng Tiếng Việt",
        });

        this.des_thai= new InputField("des_thai", "");
        this.des_thai.setProps({
            placeholder: "Hãy nhập mô tả bằng Tiếng Thái",
            label:"Mô tả bằng Tiếng Thái",
        });

        this.year = new InputField("year", Converter.getLatest10Years()[0]) ;
        this.year.setProps({
            label:"Năm sản xuất",
        });
        this.year.convertToSelect(Converter.getLatest10Years());


        this.tag = new InputField("tag", "");
        this.tag.setProps({
            label:"Tag",
            placeholder: "Nhập các từ khóa liên quan"
        });
        this.tag.convertToMultiSelect([]);

        this.addLockedField("key");
        this.addHiddenField("brand");
        this.addHiddenField("bike");
        this.addHiddenField("bikeClass");
        this.addHiddenField("group_id");
        this.addHiddenField("group_name");
        this.addHiddenField("index");
        this.addHiddenField("keywords");
        this.addHiddenField("status");
        this.addHiddenField("2012");
        this.addHiddenField("2013");
        this.addHiddenField("2014");
        this.addHiddenField("2015");
        this.addHiddenField("2016");
        this.addHiddenField("2017");
        this.addHiddenField("2018");
        this.addHiddenField("2019");
        this.mapData();           
    }

    setGroups=(groupOptions) => {
        console.log("BIKE OPTIONS: ", groupOptions);
        this.group.convertToCascader(groupOptions);
        this.mapData();     
    }

    setGroup=(brand, bike, bikeClass, group) => {
        this.group.addProp({defaultValue:[brand,bike, bikeClass, group]})
        this.mapData();     
    }
}
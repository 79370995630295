import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import { Table, Checkbox, Form, Button, Row, Col, Modal, Input, Upload, message, Spin, DatePicker, Select } from 'antd';

import 'antd/dist/antd.css';
import { ExclamationCircleFilled, CheckCircleFilled, EyeFilled, BarcodeOutlined, StarFilled, CodeSandboxOutlined } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext } from '../../App';
import * as Converter from '../../common/helpers/Converter';
import * as Date from '../../common/helpers/Date';
import Customer from '../../objects/Customer';
import { useGetNonePrice } from '../../hooks/Goods';
import EditableObject from '../components/EditableObject';
import moment from 'moment';
import * as firebase from "firebase/app";
import Recharge from '../components/Recharge';
// import * as COLOR from '../../common/constant/color';

const { Option } = Select;

function AskingScreen(props) {

    const [editingAskingItem, setEditingAskingItem] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [modalDetail, setModalDetail] = useState(false);

    var [refreshData, setRefreshData] = useState(false);

    const askings = useGetNonePrice(100, refreshData);

    useEffect(() => {
        if (askings != null)
            setIsLoading(false);
        else
            setIsLoading(true);
    }, [askings])

    const columns = [
        {
            title: 'Part code',
            dataIndex: 'key',
            key: 'key',
            width: "20%",
            render: (text, record) => <a onClick={() => viewAskingItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
        },
        {
            title: 'Hãng',
            dataIndex: 'brand',
            key: 'brand',
            width: "20%",
            render: (text, record) => <a onClick={() => viewAskingItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{record.part.brand}</a>
        },
        {
            title: 'Lần hỏi cuối',
            dataIndex: 'latestAsk',
            key: 'latestAsk',
            width: "20%",
            render: (text, record) => <a onClick={() => viewAskingItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{Date.getDateTime(text.seconds)}</a>
        },
        {
            title: 'Đã xử lý hết',
            dataIndex: 'replied',
            key: 'replied',
            width: "20%",
            render: (text, record) => getRepliedAllStatus(record) ? <CheckCircleFilled style={{color:"green", fontSize: "1.2em"}}/> : <ExclamationCircleFilled  style={{color:"red", fontSize: "1.2em"}}/>
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: "20%",
            render: (text, record) => (
                <div style={{ display: "flex" }}>
                    <Button onClick={() => viewAskingItem(record)} type="primary">
                        <EyeFilled />
                        Xem
                    </Button>
                </div>
            ),
        },
    ];

    const getRepliedAllStatus = (askingItem) => {
        var replyAll = true;
        if (askingItem && askingItem.asking) {
            var item;
            for (item of askingItem.asking) {
                console.log("Asking Item: ", item);
                if (item.status === false) {

                    replyAll = false;
                    console.log("replyAll: ", replyAll);
                }
            }
        }
        return replyAll;
    }

    const cancel = () => {
        setModalDetail(false);
        setEditingAskingItem(null);

    };

    const viewAskingItem = (askingItem) => {
        console.log("Edit Order: ", askingItem);

        setEditingAskingItem(askingItem);
        setRefreshData(false);
        setModalDetail(true);
    }

    const updatePrice = async (customerData) => {
        // console.log("group update data: ", customerData);
        // //Save to server
        // try {
        //     db.collection(COLLECTIONS.USER).doc(customerData.uid).update(customerData);
        //     setRefreshData(true);
        // } catch (error) {
        //     //Recover old data
        //     console.log('error', error);
        // }

        setIsLoading(false);
        setEditingAskingItem(null);
        setModalDetail(false);
    }

    const dataSource = Search.searchInObject(askings, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    // const dataChanged =
    //     (
    //         editingOrder
    //         && newStatus == editingOrder.status
    //         && (deliveryDate == null || (editingOrder.delivery_date && moment(deliveryDate).toDate().getDate() == moment(editingOrder.delivery_date.miliseconds).toDate().getDate()))
    //     ) ? false : true;

    // if (editingOrder) {
    //     console.log("value: ", newStatus + " - Origin: " + editingOrder.status);
    //     console.log("data changed: ", dataChanged);
    // }

    const onChange = async (e, item) => {
        if (item && editingAskingItem && editingAskingItem.asking) {
            setRefreshData(false);
            setIsLoading(true);
            var asker;
            for (asker of editingAskingItem.asking) {
                console.log("Asker: ", asker);
                console.log("item: ", item);
                if (asker.uid === item.uid) {
                    console.log("FOUND UID");
                    asker.status = e.target.checked;
                }
            }
            console.log("ASKING: ", editingAskingItem.asking);

            try {
                await db.collection(COLLECTIONS.CHECKING_PRICE).doc(editingAskingItem.key).update({
                    asking: editingAskingItem.asking
                });
                setRefreshData(true);
            } catch (error) {
                //Recover old data
                console.log('error', error);
            }
        }
    }

    return (
        <div>
            <h1>KHÁCH HÀNG QUAN TÂM</h1>

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh sách mặt hàng đang được hỏi giá </span>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource != null ? dataSource : []}
                    loading={dataSource == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key)}
                />
            </div>

            <Modal
                title="Danh sách người quan tâm đến sản phẩm"
                centered
                visible={modalDetail}
                width={800}
                onCancel={cancel}
                footer={
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        {error}
                    </div>
                }
            >
                {
                    editingAskingItem ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", flex: 1 }}>
                            <Row>
                                <Col span={6} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        Hãng: &nbsp;
                                        {editingAskingItem.part.brand}
                                    </div>
                                </Col>
                                <Col span={6} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        <BarcodeOutlined />&nbsp;
                                        {editingAskingItem.key}
                                    </div>
                                </Col>
                                <Col span={6} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        <CodeSandboxOutlined />&nbsp;
                                        {editingAskingItem.part.des_en} ({editingAskingItem.part.des_vn})
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div style={{ display: "flex", height: "45px", alignItems: "center" }}>
                                        <StarFilled />&nbsp;
                                        {editingAskingItem.totalUserAsking}
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ fontSize: "12px", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                                <Col span={4}>Số điện thoại</Col>
                                <Col span={7}>Người hỏi</Col>
                                <Col span={3} style={{ textAlign: "left" }}>Số lần hỏi</Col>
                                <Col span={6} style={{ textAlign: "right" }}>Ngày hỏi gần nhất</Col>
                                <Col span={4} style={{ textAlign: "right" }}>Đã báo giá</Col>
                            </Row>
                            {
                                (editingAskingItem && editingAskingItem.asking && Array.isArray(editingAskingItem.asking) && editingAskingItem.asking.length > 0)
                                    ?
                                    editingAskingItem.asking.map((item) => {
                                        return (
                                            <Row key={item.code} style={{ fontSize: "12px", marginBottom: "6px", paddingTop: "4px", paddingBottom: "3px" }}>
                                                <Col span={4} style={{ fontWeight: "bold" }}>{item.phone}</Col>
                                                <Col span={7} style={{ fontSize: "13px", fontWeight: "bold" }}>{item.userName}</Col>
                                                <Col span={3} style={{ textAlign: "left", fontSize: "13px", fontWeight: "bold" }} className="text-right">{item.totalAsk}</Col>
                                                <Col span={6} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right">{Date.getDateTime(item.latestAsk / 1000)}</Col>
                                                <Col span={4} style={{ color: "green", textAlign: "right" }}>
                                                    <Checkbox disabled={isLoading} defaultChecked={item.status} onChange={(e) => { onChange(e, item) }}> </Checkbox>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    : <Spin style={{ margin: "5px" }} size="small" />
                            }
                            <Row style={{ fontSize: "13px", marginTop: "30px" }}>
                                <Col span={12}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>

                                        <Button style={{ flex: 8 }} type="primary" onClick={() => updatePrice()} disabled={isLoading}>CẬP NHẬT</Button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : <Spin />
                }
            </Modal>
        </div>
    );
}

// const AskingScreen = Form.create({ name: 'class' })(AskingForm);
export default AskingScreen;
import React, { useState, useEffect } from 'react';
import * as Converter from '../../common/helpers/Converter';
import { Form, Cascader } from 'antd';

export default function CascaderInput(props) {
    const field = props.field;
    const onChangeData = props.onChange;
    const fprops = props.inputProps;
    const mapProps = Converter.getBasicProps(field, fprops);

    var basicOptions = [{ value: "empty", label: "No Data" }];

    if (fprops && fprops.options && Array.isArray(fprops.options) && fprops.options.length > 0)
        basicOptions = fprops.options;

    const [options, setOptions] = useState(basicOptions);

    // console.log("ALL PROPS: ", fprops);
    var loadEvent = null;
    if (fprops && fprops.loadData)
        loadEvent = fprops.loadData;

    var defaultValue = [options[0].value];
    if (fprops && fprops.defaultValue)
        defaultValue = fprops.defaultValue;
    // console.log("------- defaultValue: ", defaultValue);
    console.log("------- options: ", options);
    // console.log("------- mapProps.label: ", mapProps.label);
    // console.log("------- key: ", field);

    const loadData = async selectedOptions => {
        if(loadEvent) {
            await loadEvent(selectedOptions);
            setOptions([...options]);
        }
    };

    //require, rules
    return (
        <Form.Item label={mapProps.label} hasFeedback
            name={field}
            initialValue={defaultValue}
            rules={[
                {
                    type: "array",
                    required: mapProps.required, message: mapProps.require_message
                },
                ...mapProps.rules
            ]}
        >
            <Cascader
                placeholder={mapProps.placeholder}
                onChange={(e) => { onChangeData(field, e) }}
                options={options}
                loadData={loadData}
                changeOnSelect={true} />
        </Form.Item>
    );
}

import React, { Fragment, useState } from "react";
import { Button, Modal, message } from "antd";
import * as COLLECTIONS from '../../common/constant/collections';
import { db } from '../../common/firebase/Firestore';
import EditableObject from '../components/EditableObject';
import Customer from "../../objects/Customer";
import {PlusOutlined} from '@ant-design/icons';
import {useSignUpEmail, updateCustomerInfo, GetLatestInfo} from "../../hooks/Customers";
import md5 from 'md5';
import * as firebase from "firebase/app";

export default function CreateCustomerForm(props) {
    const [customer, setCustomer] = useState(null);
    var [error, setError] = useState(" ");
    var [isLoading, setIsLoading] = useState(props.isLoading);

    const onSuccess = () => {
        console.log("Create new customer SUCCESS");
        setIsLoading(false);
        //Reset oldata
        setCustomer(null);
        message.success("Đăng ký thành công");
    }

    const onFail = (errorMessage) => {
        console.log("Create new customer FAILED");
        setError(errorMessage);
        setIsLoading(false);
        setCustomer(null);

    }

    // useSignUpEmail(customer, onSuccess, onFail);

    const openCreateForm = () => {
        var customerObj=new Customer();
        customerObj.unlockField("phone");
        customerObj.unlockField("money");
        customerObj.unlockField("name");
        customerObj.unlockField("address");
        customerObj.unlockField("password");
        customerObj.unHideField("password");
        customerObj.unlockField("index");
        customerObj.addHiddenField("index");
        customerObj.addHiddenField("status");
        setCustomer(customerObj);
    }

    const createNewCustomer = (inputData) => {
        console.log("INPUT DATA FOR CUSTOMER: ", inputData);
        if (!inputData) {
            setError("Dữ liệu phiên bản mới đang trống");
            return false;
        }
        try {
            setIsLoading(true);
            doSignUp(inputData);

        } catch (error) {
            setError(error.name+": "+error.message);
            console.log("ERROR: ", error.message);
            setIsLoading(false);
            return false;
        }
    }

    const doSignUp = async (signupInfo) => {
        console.log("Do signup new user: ", signupInfo);

        //Create firebase account for authen
        await firebase.auth().createUserWithEmailAndPassword(signupInfo.email, md5(signupInfo.password))
            .then((userCredential) => {
                console.log("UserCredential: ", userCredential);
                //Create new User info
                if (userCredential.user) {
                    const newUser = GetLatestInfo(userCredential.user);
                    let validateInfo = { ...signupInfo };
                    if (!validateInfo.phone)
                        delete validateInfo['phone'];
                    // if (!validateInfo.address)
                    //     delete validateInfo['address'];
                    updateCustomerInfo({ ...newUser, ...validateInfo, password: md5(validateInfo.password) }, onSuccess);
                    
                } else {
                    message.error("Không khởi tạo được thông tin người dùng");
                }

            }).catch(function (error) {
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log("Exception: ", errorMessage);
                message.error("Đăng ký không thành công: " + errorMessage);
                onFail("Đăng ký không thành công: " + errorMessage);
            });
    }

    return (
        <Fragment>
            <Button type="primary" icon={<PlusOutlined />} onClick={openCreateForm}>Create New Customer</Button>
            <EditableObject
                input={customer}
                onSubmit={createNewCustomer}
                error={error}
                submitButton="Tạo mới người dùng"
                title="Tạo mới người dùng"
                // onChange={onChange} 
                />
        </Fragment>
    )
}
import React, { useContext } from 'react';
import { Col, Layout, Avatar, Breadcrumb, Badge, Menu, Dropdown } from 'antd';
import { CaretDownFilled, UserOutlined, LogoutOutlined, SettingFilled, HomeFilled } from '@ant-design/icons';
import SideMenu from './SideMenu';
import { renderRoutes } from 'react-router-config';
import { Link } from 'react-router-dom';
import logo from '../../logo.svg';
import LayoutStyle from './layout.module.css';
import * as PATH from '../../common/constant/path';
import { UserContext } from '../../App';
import { fb, db, DB_TYPE } from '../../common/firebase/Firestore';
import * as SYSTEM from '../../common/constant/system';

const { Header, Sider, Content, Footer } = Layout;


export default function LayoutScreen(props) {
  const user = useContext(UserContext);

  const signOut = () => {
    fb.auth().signOut().then(function () {
      // Sign-out successful.
      console.log("Sign-out successful");
    }).catch(function (error) {
      // An error happened.
      console.log("Sign-out error: ", error);
    });
  }

  const userInfoComponent = () => {
    return (
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link" href="#" style={{ top: 0, right: 0, position: "absolute", whiteSpace: "nowrap" }}>
          <span style={{ marginRight: 2 }}>{user.name}</span>
          <CaretDownFilled style={{ marginRight: 8 }} />
          <span style={{ marginRight: 24 }}>
            <Badge count={1}>
              <Avatar shape="square" icon="user" />
            </Badge>
          </span>
        </a>
      </Dropdown>

    );
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={PATH.PROFILE}><UserOutlined /><span>&nbsp; Profile</span></Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={PATH.SETTING}><SettingFilled /><span>&nbsp; Setting</span></Link>
      </Menu.Item>
      <Menu.Item onClick={signOut}>
        <LogoutOutlined /><span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const breadcrumbs = () => {
    let parts = props.location.pathname.split("/");
    const place = parts[parts.length - 1];
    parts = parts.slice(1, parts.length - 1);


    return (
      <Breadcrumb style={{ height: "100%", alignItems: "center", float: "left", display: "flex" }}>
        <Breadcrumb.Item>
          <HomeFilled />
          <a href={PATH.HOME} >&nbsp;&nbsp;Trang chủ </a>
        </Breadcrumb.Item>
        {parts.map(crumb)}
        <Breadcrumb.Item>{place}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  const crumb = (part, partIndex, parts) => {
    const path = ['', ...parts.slice(0, partIndex + 1)].join(" / ");
    return (<Breadcrumb.Item><a href={path}>{part}</a></Breadcrumb.Item>);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <img src={logo} className={LayoutStyle.logo} alt="logo" />
        <SideMenu />
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0, height: "10vh" }} >
          <div style={{ height: "100%", paddingLeft: "10px", alignItems: "center" }}>
            {breadcrumbs()}
            {userInfoComponent()}
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          {renderRoutes(props.route.routes)}
        </Content>
        <Footer style={{ textAlign: 'center' }}>PhuTungPKL.com ©2019 Created by Comartek (v.{SYSTEM.VERSION} - Database: {DB_TYPE}). </Footer>
      </Layout>
    </Layout>
  );
}

import React, { useState, Fragment } from 'react';
import { useGetAdminNoti } from '../../hooks/UserStatus';
import { Spin, Row, Col, Button, Checkbox, Card, List, Avatar } from 'antd';
import * as Date from '../../common/helpers/Date';
import { db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';

export default function DashBoardScreen(props) {

    var [refreshData, setRefreshData] = useState(false);
    const notis = useGetAdminNoti(30, refreshData);

    const onChange = (e, noti) => {
        db.collection(COLLECTIONS.ADMIN_NOTI).doc(noti.key).update(
            {
                read: e.target.checked
            }
        )
    }

    return (
        <Fragment>
            <Card title="THÔNG BÁO MỚI NHẤT"
                extra={<Button >Đã đọc tất cả</Button>}
                size="small"
                hoverable={true}
                style={{ width: 680 }}
            >
                {
                    notis != null ?
                        <List
                            itemLayout="horizontal"
                            dataSource={notis}
                            renderItem={noti => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={noti.avatar} />}
                                        title={<span><a href="#">{noti.userName}&nbsp;</a><span style={{ fontWeight: "400", fontSize: "10pt", fontStyle: "italic", color: "lightgray" }}>({Date.getSimpleDateTime(noti.created.seconds)})</span></span>}
                                        description={noti.message}
                                    />
                                    <Checkbox defaultChecked={noti.read} onChange={(e) => { onChange(e, noti) }}>Đã đọc</Checkbox>
                                </List.Item>
                            )}
                        />
                        : <Spin />
                }

            </Card >
        </Fragment>
    );
}
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

//Production environment
const DB_TYPE="Production";
const firebaseConfig = {
  apiKey: "AIzaSyA1_LuuZPFbVvN6NUPnLjZOgCtRD9eMaU4",
  authDomain: "phutungxanh.firebaseapp.com",
  databaseURL: "https://phutungxanh.firebaseio.com",
  projectId: "phutungxanh",
  storageBucket: "phutungxanh.appspot.com",
  messagingSenderId: "572659303878",
  appId: "1:572659303878:web:86338737e858957d3a9ec7"
};

//Demo environment
// const DB_TYPE="Demo";
// const firebaseConfig = {
//   apiKey: "AIzaSyA8vFAR4fcdHW6rORDE950xkn3fRs4ijow",
//   authDomain: "phutungxe.firebaseapp.com",
//   databaseURL: "https://phutungxe.firebaseio.com",
//   projectId: "phutungxe",
//   storageBucket: "phutungxe.appspot.com",
//   messagingSenderId: "72680893596",
//   appId: "1:72680893596:web:3a29f0abf5ddb0c9c29159"
// };

//Dev environment
// const DB_TYPE="Dev";
// const firebaseConfig = {
//   apiKey: "AIzaSyB76xWu0R0INjOhb59UED4iI15FObGP9p0",
//   authDomain: "phutungpkl-dev.firebaseapp.com",
//   databaseURL: "https://phutungpkl-dev.firebaseio.com",
//   projectId: "phutungpkl-dev",
//   storageBucket: "phutungpkl-dev.appspot.com",
//   messagingSenderId: "300741188646",
//   appId: "1:300741188646:web:d80e9db94e72d3c8"
// };

// Test for investor environment
// const DB_TYPE="Test";
// const firebaseConfig = {
//   apiKey: "AIzaSyD4gXaNR5PHFFhgmntkEtDHfvm3lWEzT5g",
//   authDomain: "phutung-pkl-test.firebaseapp.com",
//   databaseURL: "https://phutung-pkl-test.firebaseio.com",
//   projectId: "phutung-pkl-test",
//   storageBucket: "phutung-pkl-test.appspot.com",
//   messagingSenderId: "68544797183",
//   appId: "1:68544797183:web:2529fc01f6995e48"
// };

const fb = firebase.initializeApp(firebaseConfig);
const fb_provider = new firebase.auth.FacebookAuthProvider();
const gg_provider = new firebase.auth.GoogleAuthProvider();
console.log(gg_provider);
const db = fb.firestore();

export { fb, db, fb_provider, gg_provider, DB_TYPE };
import { useState, useEffect } from 'react';
import { fb, db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/constant/collections';
import * as NAME from '../../common/constant/name';
import { message } from 'antd';
import * as firebase from "firebase/app";
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import UIObject from '../../objects/UIObject';
import * as Converter from '../../common/helpers/Converter';

export default class TextConfig extends UIObject {
    constructor(data) {
        super(data);

        this.home_title = new InputField("home_title", "Bank's name");
        this.home_title.setProps({
            placeholder: "Nhập tiêu đề cho trang chủ",
            label: "Tiêu đề trên trang chủ",
        });

        this.home_des = new InputField("home_des", "Home's description");
        this.home_des.setProps({
            placeholder: "Nhập mô tả hiển thị trên trang chủ",
            label: "Mô tả ngắn trên trang chủ",
        });

        this.comitment1_tt = new InputField("comitment1_tt", "Home's description");
        this.comitment1_tt.setProps({
            placeholder: "Tiêu đề cho cam kết 1",
            label: "Tiêu đề cho cam kết 1",
        });
        this.comitment2_tt = new InputField("comitment2_tt", "Home's description");
        this.comitment2_tt.setProps({
            placeholder: "Tiêu đề cho cam kết 2",
            label: "Tiêu đề cho cam kết 2",
        });
        this.comitment3_tt = new InputField("comitment3_tt", "Home's description");
        this.comitment3_tt.setProps({
            placeholder: "Nội dung cho cam kết 3",
            label: "Nội dung cho cam kết 3",
        });

        this.comitment1_des = new InputField("comitment1_des", "Home's description");
        this.comitment1_des.setProps({
            placeholder: "Nội dung cho cam kết 1",
            label: "Nội dung cho cam kết 1",
        });
        this.comitment2_des = new InputField("comitment2_des", "Home's description");
        this.comitment2_des.setProps({
            placeholder: "Nội dung cho cam kết 2",
            label: "Nội dung cho cam kết 2",
        });
        this.comitment3_des = new InputField("comitment3_des", "Home's description");
        this.comitment3_des.setProps({
            placeholder: "Tiêu đề cho cam kết 3",
            label: "Tiêu đề cho cam kết 3",
        });

        this.mapData();
    }

}

function useGetTextConfig(refreshData) {
    var [textConfig, setTextConfig] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (textConfig == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let textRef = db.collection(COLLECTIONS.CONFIG).doc("text");
        textRef.get().then((doc) => {
            if (doc.exists) {
                setTextConfig({...doc.data()});
            }
            setRefresh(false);
        });
    }

    return textConfig;
}

const save = (textConfig, onSuccess, onFail) => {
    try {
        //Save bank account info
        db.collection(COLLECTIONS.CONFIG).doc("text").update({
            ...textConfig
        }).then((doc)=>{
            onSuccess && onSuccess();
        })

    } catch (error) {
        console.log(error);
        onFail && onFail(error.name);
    }
}

export {
    TextConfig,
    useGetTextConfig,
    save
}

import React, { useState, useContext, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as COLLECTIONS from '../../common/constant/collections';
import { db } from '../../common/firebase/Firestore';
import InputFormByObject from '../components/InputFormByObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import { BrandContext } from '../../App';
import Brand from '../../objects/Brand';
import Bike from '../../objects/Bike';
import SearchField from "react-search-field";
import { Button, Col, Modal, Input, Upload } from 'antd';
import {PlusOutlined} from '@ant-design/icons';

export default function NewBikeComponent(props) {
    const oldData = useContext(BrandContext);
    const searchBikeListener = props.searchListener;
    var [bike, setBike] = useState(new Bike());
    var [selectedBrand, setSelectedBrand] = useState("");
    var [brands, setBrands] = useState(props.brands);
    var [bikes, setBikes] = useState(props.bikes);
    var [error, setError] = useState("");
    var [isLoading, setIsLoading] = useState(props.isLoading);
    var [showingModal, setShowingModal] = useState(false);
    // console.log("==== Brands: ", brands);

    useEffect(() => {
        setBrands(oldData);
    }, [oldData]);

    useEffect(() => {
        //Update input form
        var originBike = bike;
        var brandList = [];
        oldData.forEach(item => {
            brandList.push({ key: item.key, label: item.name });
        });
        console.log("==== Brands: ", brandList);

        if (oldData[0] && oldData[0].key) {
            var oldBikeData = bike.data();
            oldBikeData.brand = oldData[0].key;
            originBike = new Bike(oldBikeData);
            originBike.setBrand(brandList[0]);
            setSelectedBrand(oldData[0].key);
            getAllBikesInBrand(oldData[0].key);
        }
        originBike.setBrands(brandList);
        setBike(originBike);

        //Get all bike from brands
    }, [brands]);

    const getAllBikesInBrand = (brand) => {
        console.log("Select brand", brand);
        db.collection(COLLECTIONS.BRAND).doc(brand).collection(COLLECTIONS.SUB_BIKE).get().then((docs) => {
            let listBike = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    const bike = {
                        key: doc.id,
                        ...doc.data()
                    };
                    listBike.push(bike);
                }
            });
            setBikes(listBike);
            console.log("List bikes", listBike);
        });
    }

    const onChange = (data) => {
        if (data.brand && selectedBrand != data.brand) {
            setSelectedBrand(data.brand)

            //Get all bikes in selected brand
            getAllBikesInBrand(data.brand);
        }
    }

    const createNewBike = async (inputData) => {
        if (!inputData) {
            setError("Input data for new Bike is empty");
            return false;
        }
        try {
            setIsLoading(true);
            const newBike = inputData;
            newBike.key = inputData.name;

            //Check new brand is existed
            if (!selectedBrand) {
                setError("You need choose a brand");
            } else if (!bikes || bikes.length == 0 || bikes.filter(b => { return b.key.toLowerCase() == newBike.key.toLowerCase() }).length == 0) {
                //Brand not exists, create new brand
                await db.collection(COLLECTIONS.BRAND).doc(`${selectedBrand}`).collection(COLLECTIONS.SUB_BIKE).doc(newBike.key).set(newBike)
                    .then((doc) => {
                        setBikes([...brands, newBike]);
                        console.log("Add new bike success");
                        setShowingModal(false);
                        setError("");
                    }).catch((error) => {
                        setError("Error write new bike: " + error);
                        return false;
                    });
                await db.collection(COLLECTIONS.ALL_BIKE).doc(newBike.key).set(newBike)
                    .then((doc) => {
                        return true;
                    }).catch((error) => {
                        setError("Error write new bike: " + error);
                        return false;
                    });
                return true;
            } else {
                //Show message to notify that brands is existed
                setError("Bike name [" + newBike.name + "] is existed");
            }
        } catch (error) {
            setError(error);
        }
    };

    const searchBike = (e) => {
        searchBikeListener && searchBikeListener(e);
    }

    return (
        <div style={{ marginBottom: "10px" }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => { setShowingModal(true) }}>Thêm mới</Button>
                <SearchField
                    placeholder="Search..."
                    onChange={(e) => searchBike(e)}
                    placeholder="Nhập tên xe"
                    searchText=""
                />
            </div>

            <Modal
                visible={showingModal}
                title="Thêm mới Xe"
                // onOk={createNewBrand}
                onCancel={() => { setShowingModal(false) }}
                footer={
                    <span style={{ color: "red" }}>{error}</span>
                }
            >
                <InputFormByObject input={bike} onSubmit={createNewBike} submitButton="Thêm mới" onChange={onChange} />

            </Modal>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { PART } from '../../common/constant/path';
import { Row, Col, Button, Table, Popconfirm } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';

export default function ListPart(props) {
    // const parts = props.parts;
    const onEdit = props.onEdit;
    const onDelete = props.onDelete;
    const [parts, setParts] = useState(props.parts);
    // console.log("PARTS 1: ", props.parts);
    const [showingModal, setShowingModal] = useState(false);

    useEffect(() => {
        setParts(props.parts);
    }, [props.parts]);

    const columns = [
        {
            title: 'Ref Code',
            dataIndex: 'ref_code',
            key: 'ref_code',
            width: "8%",
            render: (text, record) => <a onClick={() => editPart(record)} style={{ maxWidth: "5vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
        },
        {
            title: 'Tên phụ tùng',
            dataIndex: 'name',
            key: 'name',
            width: "15%",
            render: (text, record) => <a style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }} onClick={() => editPart(record)} >{text}</a>,
        },
        {
            title: 'Part Code',
            dataIndex: 'code',
            key: 'code',
            width: "15%",
            render: (text, record) => <a style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }} onClick={() => editPart(record)} >{text}</a>,
        },
        {
            title: 'Older',
            dataIndex: 'old_code',
            key: 'old_code',
            width: "15%",
            render: (text, record) => <a style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }} onClick={() => editPart(record)} >{text}</a>,
        },
        {
            title: 'Xe',
            dataIndex: 'bike',
            key: 'bike',
            width: "10%",
            render: (text, record) => <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>,
        },
        {
            title: 'Phiên bản',
            dataIndex: 'bikeClass',
            key: 'bikeClass',
            width: "10%",
            render: (text, record) => <span style={{ maxWidth: "7vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>,
        },
        {
            title: 'Nhóm',
            dataIndex: 'group_name',
            key: 'group_name',
            width: "20%",
            render: (text, record) => <span style={{ maxWidth: "15vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "18%",
            render: (text, record) => (
                <div>
                    <Button size="small" onClick={() => editPart(record)} type="primary">
                        <EditFilled style={{ marginLeft: "8px", marginRight: "5px" }} />
                    </Button>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa phụ tùng này?"
                        onConfirm={()=>deletePart(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small" type="danger">
                            <DeleteFilled />
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const editPart = (part) => {
        onEdit && onEdit(part);
    }
    const deletePart = (part) => {
        onDelete && onDelete(part);

        setParts(parts.filter(p => {
            if (p && p.code != part.code)
                return true;
            else return false;
        }));
    }

    return (
        <Table
            columns={columns}
            dataSource={parts}
            scroll={{ x: "100%", y: "100%" }}
            size="small"
            pagination={{
                position: "both"
            }}
            rowKey={record => (record.code + "_" + record.group_id + "_" + record.bikeClass + "_" + record.bike + "_" + record.brand)}
        />
        // <div>
        //     {
        //         parts?parts.map((part) => {
        //             return (
        //                 <Row key={part.key} style={{marginTop:"2px"}}>
        //                     <Col span={2}>{part.ref_code}</Col>
        //                     <Col span={6}>{part.name}</Col>
        //                     <Col span={4}>{part.code}</Col>
        //                     <Col span={4}>{part.old_code}</Col>
        //                     <Col span={2}>{part.bike}</Col>
        //                     <Col span={3}>{part.price_vn} VND</Col>
        //                     <Col span={3}><Button type="primary" size="small">Edit</Button></Col>
        //                 </Row>
        //             );
        //         }):null
        //     }
        // </div>
    );
}
export const BRAND = "brand";
export const USER = "Users";
export const BLOCKED_LIST = "Blocked";
export const ALL_BIKE = "All_Bikes";
export const ALL_GROUP = "All_Groups";
export const ALL_PART = "All_Parts";
export const SUB_BIKE = "bike";
export const SUB_CLASS = "class";
export const SUB_GROUP = "group";
export const SUB_PART = "part";
export const ORDER = "Order";
export const RECHARGE_HISTORY = "Recharge";
export const WITHDRAW_HISTORY = "WithDraw";
export const SUB_NOTI = "Notifications";
export const CONFIG = "Config";
export const CHECKING_PRICE = "CheckingPrice";
export const SHIP_REQUEST = "ShipRequest";
export const ADMIN_NOTI = "AdminNoti";
import React, { useState, useContext, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as COLLECTIONS from '../../common/constant/collections';
import { db } from '../../common/firebase/Firestore';
import EditableObject from '../components/EditableObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import { BrandContext } from '../../App';
import Brand from '../../objects/Brand';
import Bike from '../../objects/Bike';
import SearchField from "react-search-field";
import { Icon, Button, Col, Modal, Input, Upload } from 'antd';
import PartGroup from '../../objects/PartGroup';
import { PlusOutlined } from '@ant-design/icons';

export default function NewGroupComponent(props) {
    const classOptions = props.classOptions;
    const [classOptionsObject, setClassOptionsObject] = useState(props.classOptions);
    const searchGroupListener = props.searchListener;
    var [group, setGroup] = useState(null);
    var [groups, setGroups] = useState(props.groups);
    var [selectedBike, setSelectedBike] = useState("");
    var [selectedBrand, setSelectedBrand] = useState("");
    var [selectedClass, setSelectedClass] = useState("");
    var [error, setError] = useState(" ");
    var [isLoading, setIsLoading] = useState(props.isLoading);
    // var [showingModal, setShowingModal] = useState(false);

    useEffect(() => {
        console.log("CLASS_OPTIONS: ", classOptions);
        if (classOptions && classOptions.length > 0) {
            var firstOption = classOptions[0];
            if (firstOption.value) {
                setSelectedBrand(classOptions[0].value);
            }
            if (firstOption.chidren && Array.isArray(firstOption.chidren) && firstOption.chidren[0] && firstOption.chidren[0].value) {
                var bike = firstOption.chidren[0];
                setSelectedBike(bike.value);

                if (bike.chidren && Array.isArray(bike.chidren) && bike.chidren[0] && bike.chidren[0].value) {
                    setSelectedClass(bike.chidren[0].value);
                    getAllGroupInClass(firstOption.value, bike.value, bike.chidren[0].value);
                }
            }
            setClassOptionsObject(classOptions);
        }
    }, [classOptions]);

    const getAllGroupInClass = (brand, bike, bikeClass) => {
        console.log("Selected brand", brand);
        console.log("Selected bike", bike);
        console.log("Selected class", bikeClass);
        db.collection(COLLECTIONS.BRAND)
            .doc(brand).collection(COLLECTIONS.SUB_BIKE)
            .doc(bike).collection(COLLECTIONS.SUB_CLASS)
            .doc(bikeClass).collection(COLLECTIONS.SUB_GROUP).get().then((docs) => {
                // console.log("Docs", docs);
                let listGroup = [];
                if (docs.docs.length > 0) {
                    docs.docs.forEach((doc) => {
                        if (doc.exists) {
                            const group = {
                                key: doc.id,
                                ...doc.data()
                            };
                            listGroup.push(group);
                        }
                    });
                }
                setGroups(listGroup);
                // console.log("List group", listGroup);
            });
    }

    const onChange = (data) => {
        if (data.bikeClass && Array.isArray(data.bikeClass)) {
            console.log("Change group data: ", data.bikeClass);
            if (data.bikeClass.length > 0 && data.bikeClass[0] != selectedBrand)
                setSelectedBrand(data.bikeClass[0]);

            if (data.bikeClass.length > 1 && data.bikeClass[1] != selectedBike)
                setSelectedBike(data.bikeClass[1]);

            if (data.bikeClass.length == 3 && data.bikeClass[2] != selectedClass) {
                console.log("Selected class: ", selectedClass);
                setSelectedClass(data.bikeClass[2]);

                //Get all group in selected bike
                getAllGroupInClass(data.bikeClass[0], data.bikeClass[1], data.bikeClass[2]);
            }
        }
    }

    const createNewGroup = async (inputData) => {
        if (!inputData) {
            setError("Dữ liệu nhóm phụ tùng mới đang trống");
            return false;
        }
        try {
            setIsLoading(true);
            var newGroup = inputData;
            console.log("GROUPS", groups);

            //Check new brand is existed
            if (!selectedBrand) {
                setError("Bạn phải chọn thương hiệu trước");
            } else if (!selectedBike) {
                setError("Bạn phải chọn đời xe cho nhóm phụ tùng mới");
            } else if (!selectedClass) {
                console.log("Selected class: ", selectedClass);
                setError("Bạn phải chọn phiên bản xe");
            } else if (!groups || groups.length == 0 || groups.filter(b => { return b.name.toLowerCase() === newGroup.name.toLowerCase() }).length == 0) {
                //Fill brand & bike property
                newGroup.brand = selectedBrand;
                newGroup.bike = selectedBike;
                newGroup.bikeClass = selectedClass;

                //Brand not exists, create new brand
                var subGroupRef = db.collection(COLLECTIONS.BRAND).doc(`${selectedBrand}`)
                    .collection(COLLECTIONS.SUB_BIKE).doc(selectedBike)
                    .collection(COLLECTIONS.SUB_CLASS).doc(selectedClass)
                    .collection(COLLECTIONS.SUB_GROUP);
                await subGroupRef.add(newGroup)
                    .then((doc) => {
                        setGroup(null);
                        setGroups([...groups, newGroup]);
                        console.log("Add new group success");
                        setError(" ");
                        newGroup.key = doc.id;
                        subGroupRef.doc(newGroup.key).update({ key: newGroup.key });
                    }).catch((error) => {
                        setError("Error write new group: " + error);
                        return false;
                    });
                // await db.collection(COLLECTIONS.ALL_GROUP).doc(newGroup.key).set(newGroup)
                //     .then((doc) => {
                //         return true;
                //     }).catch((error) => {
                //         setError("Error write new group: " + error);
                //         return false;
                //     });
                return true;
            } else {
                //Show message to notify that brands is existed
                setError("Group name [" + newGroup.name + "] is existed");
            }
        } catch (error) {
            setError(error.name);
        }
    };

    const openAddNewDialog = () => {
        var groupObj = new PartGroup();
        groupObj.setLazyClasses(classOptions, getBikeClass);
        setGroup(groupObj);
    }

    const getBikeClass = async (selectedOptions) => {
        console.log("===== selectedOptions: ", selectedOptions);
        if (selectedOptions.length == 2) {
            const targetOption = selectedOptions[selectedOptions.length - 1];
            targetOption.loading = true;

            //Get all classes in bike
            const bikeClassUrl = "/" + COLLECTIONS.BRAND + "/" + selectedOptions[0].value + "/" + COLLECTIONS.SUB_BIKE + "/" + selectedOptions[1].value + "/" + COLLECTIONS.SUB_CLASS;
            console.log("SELECT bike: ", bikeClassUrl);

            var allBikeClassData = await db.collection(bikeClassUrl).get();
            if (allBikeClassData && allBikeClassData.docs) {
                let bikeClassData = [];
                allBikeClassData.docs.forEach((doc) => {
                    if (doc.exists) {
                        bikeClassData.push({ label: doc.data().name, value: doc.id });
                    }
                })
                // Update children in cascader
                targetOption.children = bikeClassData;
            }
            targetOption.loading = false;

            //Update classOption
            setClassOptionsObject(classOptions);
        }
    }

    const searchGroup = (e) => {
        searchGroupListener && searchGroupListener(e);
    }

    return (
        <div style={{ marginBottom: "10px" }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddNewDialog}>Thêm mới</Button>
                <SearchField
                    placeholder="Search..."
                    onChange={(e) => searchGroup(e)}
                    placeholder="Nhập tên group"
                    searchText=""
                />
            </div>

            <EditableObject
                input={group}
                onSubmit={createNewGroup}
                error={error}
                submitButton="Thêm mới Group"
                title="Thêm mới nhóm phụ tùng"
                onChange={onChange} />
        </div>
    );
}
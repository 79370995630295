import React, { useState, useContext, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import { db } from '../../common/firebase/Firestore';
import InputFormByObject from '../components/InputFormByObject';
import {InputField, INPUT_TYPE} from '../../objects/InputObject';
import { BrandContext } from '../../App';
import Brand from '../../objects/Brand';
import SearchField from "react-search-field";
import { Icon, Button, Col, Modal, Input, Upload } from 'antd';
import {PlusOutlined} from '@ant-design/icons';

export default function NewBrandComponent(props) {
    const oldData = useContext(BrandContext);
    const searchBrandListener = props.searchListener;
    var [brand, setBrand] = useState(new Brand());
    var [brands, setBrands] = useState(props.brands);
    var [error, setError] = useState("");
    var [isLoading, setIsLoading] = useState(props.isLoading);
    var [showingModal, setShowingModal] = useState(false);
    // console.log("==== Brands: ", brands);

    useEffect(() => {
        setBrands(oldData);
    }, [oldData]);

    // var [key, setKey] = useState("");
    // var [name, setName] = useState("");
    // var [description, setDescription] = useState("");
    // var [country, setCountry] = useState("");
    // var [logo, setLogo] = useState("");
    // var [type, setType] = useState("");
    // var [imageUrl, setImageUrl] = useState("");

    // const handleChangeUploadLogo = info => {
    //     if (info.file.status === 'uploading') {
    //         setIsLoading(true);
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         ImageHelper.getBase64(info.file.originFileObj, imageUrl => {
    //             setLogo(imageUrl);
    //             setIsLoading(false);
    //         });
    //     }
    //     console.log("Upload DONE");
    // };

    // const uploadButton = (
    //     <div>
    //         <Icon type={isLoading ? 'loading' : 'plus'} />
    //         <div className="ant-upload-text">Upload</div>
    //     </div>
    // );

    const createNewBrand = async (inputData) => {
        if(!inputData) {
            setError("Input data for new Brand is empty");
            return false;
        }
        try {
            setIsLoading(true);
            const newBrand = inputData;
            newBrand.key=inputData.name.toLowerCase();
            console.log("New Brand: ", newBrand);
            console.log("All brand: ", brands);

            //Check new brand is existed
            if (!brands || brands.length == 0 || brands.filter(b => { return b.key == newBrand.key }).length == 0) {
                //Brand not exists, create new brand
                await db.collection('brand').doc(`${newBrand.key}`).set(newBrand)
                    .then((doc) => {
                        setBrands([...brands, newBrand]);
                        console.log("Add new brand success");
                        setShowingModal(false);
                        setBrand(new Brand());
                        setError("");
                        return true;
                    }).catch((error) => {
                        setError("Error write new brand: "+error);
                    });
            } else {
                //Show message to notify that brands is existed
                setError("Brand name is existed");
            }
        } catch (error) {
            setError(error);
        }
    };

    const searchBrand = (e) => {
        searchBrandListener && searchBrandListener(e);
    }

    return (
        <div style={{ marginBottom: "10px" }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => { setShowingModal(true) }}> Thêm mới</Button>
                <SearchField
                    placeholder="Search..."
                    onChange={(e) => searchBrand(e)}
                    placeholder="Nhập tên thương hiệu"
                    searchText=""
                />
            </div>

            <Modal
                visible={showingModal}
                title="Thêm mới Thương hiệu"
                // onOk={createNewBrand}
                onCancel={() => { setShowingModal(false) }}
                footer={[
                    <span style={{color:"red"}}>{error}</span>
                    // <Button key="back" onClick={() => { setShowingModal(false) }}>Cancel</Button>,
                    // <Button key="submit" type="primary" loading={isLoading} onClick={createNewBrand}>Thêm mới</Button>,
                ]}
            >
                <InputFormByObject input={brand} onSubmit={createNewBrand} submitButton="Thêm mới"/>
               
            </Modal>
        </div>
    );
}
import React from 'react';
import { InputNumber, Form, Input } from 'antd';

export const EditableContext = React.createContext();

export default class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = () => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {(editing && this.props.inputType !== 'logo') ? (
                    <Form.Item style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: this.props.inputType === 'logo' ? false : true,
                                message: `Hãy nhập ${title}!`,
                            },
                        ]}
                        initialValue={record[dataIndex]}
                    >

                        {this.getInput()}

                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

import React, { useState, useEffect } from 'react';
import { fb, db } from '../common/firebase/Firestore';
import * as COLLECTIONS from '../common/constant/collections';
import * as NAME from '../common/constant/name';
import { message } from 'antd';
import * as firebase from "firebase/app";

function useGetNonePrice(limit, refreshData) {
    var [allGoods, setAllGoods] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (allGoods == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let goodRef = db.collection(COLLECTIONS.CHECKING_PRICE).orderBy("latestAsk", "desc");
        if (limit > 0)
            goodRef = goodRef.limit(limit);

        goodRef.get().then((docs) => {

            let results = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    results.push({ key: doc.id, ...doc.data() });
                    // console.log("Goods data:", doc.data());
                }
            })
            setAllGoods(results);
            setRefresh(false);
        });
    }

    return allGoods;
}

function useGetShipRequest(limit, refreshData) {
    var [allGoods, setAllGoods] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (allGoods == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let goodRef = db.collection(COLLECTIONS.SHIP_REQUEST).orderBy("updated", "desc");
        if (limit > 0)
            goodRef = goodRef.limit(limit);

        goodRef.get().then((docs) => {

            let results = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    results.push({ key: doc.id, ...doc.data() });
                    // console.log("ShipRequest data:", doc.data());
                }
            })
            setAllGoods(results);
            setRefresh(false);
        });
    }

    return allGoods;
}

export {
    useGetNonePrice,
    useGetShipRequest
}
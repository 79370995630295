
export const HOME = "/";
export const LOGIN = "/login";
export const IMPORT = "/import";
export const EXPORT = "/export";
export const BRAND = "/brand";
export const CLASS = "/class";
export const PART_GROUP = "/group";
export const BIKE = "/bike";
export const PART = "/part";
export const PROFILE = "/profile";
export const SETTING = "/setting";
export const ORDER = "/order";
export const INVENTORY = "/inventory";
export const SHIP_REQUEST = "/ship_request";
export const CUSTOMER = "/customer";
export const RECEIVED = "/received";
export const CONTENT = "/content";
export const BANK = "/bank";
export const ASKING = "/asking";
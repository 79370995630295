import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import { Table, Checkbox, Form, Button, Row, Col, Modal, Input, Upload, message, Spin, DatePicker, Select } from 'antd';

import Price from '../components/Price';
import 'antd/dist/antd.css';
import { ExclamationCircleFilled, CheckCircleFilled, EyeFilled, BarcodeOutlined, StarFilled, IdcardFilled, PhoneFilled } from '@ant-design/icons';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext } from '../../App';
import * as Converter from '../../common/helpers/Converter';
import * as Date from '../../common/helpers/Date';
import Customer from '../../objects/Customer';
import { useGetShipRequest } from '../../hooks/Goods';
import EditableObject from '../components/EditableObject';
import moment from 'moment';
import * as firebase from "firebase/app";
import Recharge from '../components/Recharge';
// import * as COLOR from '../../common/constant/color';
import { isMobile } from 'react-device-detect';

const { Option } = Select;

function ShipRequestScreen(props) {

    const [editingShipRequestItem, setEditingShipRequestItem] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [modalDetail, setModalDetail] = useState(false);
    const [itemSaving, setItemSaving] = useState([]);

    var [refreshData, setRefreshData] = useState(false);

    const shipRequests = useGetShipRequest(100, refreshData);

    useEffect(() => {
        if (shipRequests != null)
            setIsLoading(false);
        // else
        //     setIsLoading(true);
    }, [shipRequests])

    const columns = !isMobile?[
        {
            title: 'Đơn hàng',
            dataIndex: 'key',
            key: 'key',
            width: "15%",
            render: (text, record) => <a onClick={() => viewShipRequestItem(record)} style={{ overflowWrap: "normal", maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'userName',
            key: 'userName',
            width: "18%",
            render: (text, record) => <a onClick={() => viewShipRequestItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: "15%",
            render: (text, record) => <a onClick={() => viewShipRequestItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: "30%",
            render: (text, record) => <a onClick={() => viewShipRequestItem(record)} style={{ maxWidth: "10vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>{text}</a>
        },
        {
            title: 'Đã xử lý hết',
            dataIndex: 'replied',
            key: 'replied',
            width: "10%",
            render: (text, record) => isShipAll(record) ? <CheckCircleFilled style={{ color: "green", fontSize: "1.2em" }} /> : <ExclamationCircleFilled style={{ color: "red", fontSize: "1.2em" }} />
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: "10%",
            render: (text, record) => (
                <div style={{ display: "flex" }}>
                    <Button onClick={() => viewShipRequestItem(record)} type="primary">
                        <EyeFilled />
                        Xem
                    </Button>
                </div>
            ),
        },
    ]:[
        {
            title: 'Đơn hàng',
            dataIndex: 'key',
            key: 'key',
            width: "80%",
            render: (text, record) => <a onClick={() => viewShipRequestItem(record)} style={{ overflowWrap: "normal", maxWidth: "80vw", overflow: "hidden", display: "block", textOverflow: "ellipsis" }}>
                {text} <br/>
                {record.userName} - {record.phone} <br/>
                {record.address}
            </a>,
        },
        
        {
            title: 'Đã xử lý hết',
            dataIndex: 'replied',
            key: 'replied',
            width: "20%",
            render: (text, record) => isShipAll(record) ? <CheckCircleFilled style={{ color: "green", fontSize: "1.2em" }} /> : <ExclamationCircleFilled style={{ color: "red", fontSize: "1.2em" }} />
        },
    ];

    const isShipAll = (shipItem) => {
        var shipAll = true;
        if (shipItem && shipItem.items) {
            for (var item of shipItem.items) {
                console.log("ship request Item: ", item);
                if (!item.deliveried || item.deliveried == false) {
                    shipAll = false;
                    console.log("Is Shipped all: ", shipAll);
                }
            }
        }
        return shipAll;
    }

    const cancel = () => {
        setModalDetail(false);
        setEditingShipRequestItem(null);

    };

    const viewShipRequestItem = (askingItem) => {
        console.log("Edit Order: ", askingItem);

        setEditingShipRequestItem(askingItem);
        setRefreshData(false);
        setModalDetail(true);
    }

    const updatePrice = async (customerData) => {
        // console.log("group update data: ", customerData);
        // //Save to server
        // try {
        //     db.collection(COLLECTIONS.USER).doc(customerData.uid).update(customerData);
        //     setRefreshData(true);
        // } catch (error) {
        //     //Recover old data
        //     console.log('error', error);
        // }

        setIsLoading(false);
        setEditingShipRequestItem(null);
        setModalDetail(false);
    }

    const dataSource = Search.searchInObject(shipRequests, searchText);

    const mapColumns = columns.map(col => {
        // console.log("column: ", col);
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'logo' ? 'logo' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    })

    // const dataChanged =
    //     (
    //         !hasChangedPartStatus
    //         && editingShipRequestItem
    //     ) ? false : true;

    const onChange = async (e, part) => {
        if (part && editingShipRequestItem && editingShipRequestItem.items) {
            setRefreshData(false);
            setIsLoading(true);
            var item;
            for (item of editingShipRequestItem.items) {
                // console.log("item: ", item);
                if (item.code === part.code) {
                    // part.deliveried = !part.deliveried;
                    item.deliveried = e.target.checked;
                    console.log("FOUND PART:", item.deliveried);
                }
            }

            try {
                await db.collection(COLLECTIONS.SHIP_REQUEST).doc(editingShipRequestItem.key).update({
                    items: editingShipRequestItem.items
                });
                setRefreshData(true);
            } catch (error) {
                //Recover old data
                console.log('error', error);
            }
        }
    }


    return (
        <div>
            <h1>YÊU CẦU GIAO HÀNG</h1>

            <div style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: "8px", left: 0 }}>Danh sách mặt hàng đang được yêu cầu giao hàng luôn </span>
                <Table
                    columns={mapColumns}
                    dataSource={dataSource != null ? dataSource : []}
                    loading={dataSource == null}
                    scroll={{ x: "100%", y: "100%" }}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        position: "both"
                    }}
                    rowKey={record => (record.key)}
                />
            </div>

            <Modal
                title="Tình trạng giao hàng hiện tại"
                centered
                visible={modalDetail}
                width={800}
                onCancel={cancel}
                footer={
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        {error}
                    </div>
                }
            >
                {
                    editingShipRequestItem ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", flex: 1 }}>
                            <Row>
                                <Col span={10} xs={24} style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center", fontWeight: "bold" }}>
                                            THÔNG TIN ĐƠN HÀNG
                                        </div>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center" }}>
                                            <BarcodeOutlined />&nbsp;
                                        {editingShipRequestItem.key}
                                        </div>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center" }}>
                                            Ngày tạo: &nbsp;
                                        {Date.getDate(editingShipRequestItem.created.seconds)}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={14} xs={24}  style={{ fontSize: "14px", color: "gray" }} >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center" }}>
                                            <IdcardFilled />&nbsp;
                                        {editingShipRequestItem.userName} ({editingShipRequestItem.uid})
                                        </div>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center" }}>
                                            <PhoneFilled />&nbsp;
                                        {editingShipRequestItem.phone}
                                        </div>
                                        <div style={{ display: "flex", height: "35px", alignItems: "center" }}>
                                            <StarFilled />&nbsp;
                                        {editingShipRequestItem.address}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ fontSize: "12px", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                                <Col span={3}>Code</Col>
                                <Col span={7}>Tên</Col>
                                <Col span={5} style={{ textAlign: "right" }}>Giá (VNĐ)</Col>
                                <Col span={4} style={{ textAlign: "right" }}>Số lượng</Col>
                                <Col span={5} style={{ textAlign: "right" }}>Đã giao hàng</Col>
                            </Row>
                            {
                                (editingShipRequestItem && editingShipRequestItem.items && Array.isArray(editingShipRequestItem.items) && editingShipRequestItem.items.length > 0)
                                    ?
                                    editingShipRequestItem.items.map((item) => {
                                        return (
                                            <Row key={item.key} style={{ fontSize: "12px", marginBottom: "6px", paddingTop: "4px", paddingBottom: "3px" }}>
                                                <Col span={3} style={{ fontWeight: "bold" }}>{item.code}</Col>
                                                <Col span={7} style={{ fontSize: "13px", fontWeight: "bold" }}>{item.des_en}</Col>
                                                <Col span={5} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right"><Price price={item.price_retail} color="black" /></Col>
                                                <Col span={4} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right">{item.count}</Col>
                                                <Col span={5} style={{ color: "green", textAlign: "right" }}>
                                                    <Checkbox disabled={isLoading} defaultChecked={item.deliveried} onChange={(e) => { onChange(e, item) }}> </Checkbox>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    : <Spin style={{ margin: "5px" }} size="small" />
                            }
                            <Row style={{ fontSize: "13px", marginTop: "30px" }}>
                                <Col span={12}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>

                                        <Button style={{ flex: 8 }} type="primary" onClick={() => updatePrice()} disabled={isLoading}>CẬP NHẬT</Button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : <Spin />
                }
            </Modal>
        </div>
    );
}

// const AskingScreen = Form.create({ name: 'class' })(AskingForm);
export default ShipRequestScreen;
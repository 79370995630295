import React, { Fragment, useState } from 'react';
import { Modal, Button, Col, InputNumber, Row, Input, message } from 'antd';
import { DollarCircleFilled } from '@ant-design/icons';
import { recharge } from "../../hooks/Customers";
import { isMobile } from 'react-device-detect';

const { TextArea } = Input;

export default function Recharge(props) {
    const user = props.customer;
    const onRechargeSuccess = props.onRechargeSuccess;
    const onPrepare = props.onPrepare;

    const [showingModal, setShowingModal] = useState(false);
    const [newMoney, setNewMoney] = useState(0);
    const [content, setContent] = useState("Nạp tiền");
    const [error, setError] = useState("");

    const onChangeMoney = (inputMoney) => {
        setNewMoney(inputMoney);
    }

    const confirm = () => {
        Modal.confirm({
            title: 'Bạn có chắc chắn?',
            content: 'Nạp thêm cho tài khoản "' + user.name + '" số tiền là: ' + newMoney + ' VNĐ',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            onOk: () => {
                onPrepare && onPrepare();
                doRecharge(user, newMoney, content);
            }
        });
    }

    const onError = (errorDetail) => {
        setError(errorDetail);
    }

    const doRecharge = (user, newMoney, message) => {
        recharge(user, newMoney, message, rechargeSuccess, onError);
    }

    const rechargeSuccess = (latestMoney) => {
        onRechargeSuccess && onRechargeSuccess(latestMoney);
        onCancel();
        message.success('Nạp tiền cho tài khoản "' + user.name + '" thành công!');
    }

    const onCancel = () => {
        setShowingModal(false);
    }

    return (
        <div>
            <Fragment>
                <Button onClick={() => { setShowingModal(true) }} style={{ background: "green", marginLeft: "5px", color: "white" }}><DollarCircleFilled /></Button>
                <Modal
                    visible={showingModal}
                    title="Nạp tiền cho khách hàng"
                    onCancel={onCancel}
                    width={isMobile?"96%":"60%"}
                    footer={[
                        <span style={{ color: "red" }} key="Error">{error ? error : "."}</span>
                    ]}
                >
                    <Row>
                        <Col span={4}>Khách hàng: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.name} </Col>
                        <Col span={2}>&nbsp; </Col>
                        <Col span={4}>Số điện thoại: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.phone} </Col>
                    </Row>
                    <Row>
                        <Col span={4}>Số tiền hiện tại: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.money} VNĐ</Col>
                        <Col span={2}>&nbsp; </Col>
                        <Col span={4}>Địa chỉ: </Col>
                        <Col span={7} style={{fontWeight:"bold"}}>{user.address} </Col>
                    </Row>
                    <hr />
                    <Row style={{ marginBottom: "10px", marginTop: "20px" }}>
                        <Col span={6} style={{ alignItems: "right", fontWeight: "bold", textAlign: "right", paddingRight: "10px" }}>Số tiền nạp thêm:</Col>
                        <Col span={18}>
                            <InputNumber min={0} onChange={onChangeMoney} style={{ width: "200px" }} /> VNĐ
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px", marginTop: "20px" }}>
                        <Col span={6} style={{ alignItems: "right", fontWeight: "bold", textAlign: "right", paddingRight: "10px" }}>Nội dung:</Col>
                        <Col span={18}>
                            <TextArea rows={3} onChange={(e) => { setContent(e.target.value) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}><Button onClick={confirm} type="primary" style={{ width: "50%" }}>Nạp tiền</Button></Col>
                    </Row>
                </Modal>
            </Fragment>
        </div>
    );
}
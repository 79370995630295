import React, { useState, useEffect } from 'react';
import { fb, db } from '../common/firebase/Firestore';
import * as COLLECTIONS from '../common/constant/collections';

export default function useUserStatus(onLoginSuccess) {
    // var [isLogin, setIsLogin] = useState(false);
    var [user, setUser] = useState(null);
    var [onLogin, setOnLogin] = useState(onLoginSuccess);

    useEffect(() => {
        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                // var displayName = user.displayName;
                // var email = user.email;
                // var emailVerified = user.emailVerified;
                // var photoURL = user.photoURL;
                // var isAnonymous = user.isAnonymous;
                // var uid = user.uid;
                // var providerData = user.providerData;
                console.log("User: ", user);
                getUserInfo(user);
                // ...
            } else {
                // User is signed out.
                console.log("Signed out");
                // setIsLogin(false);
                setUser({ isLogin: false });
                // ...
            }
        });
    }, [onLogin]);

    const getUserInfo = (user) => {
        db.collection("Users").doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                const userInfo = { ...user, ...doc.data(), isLogin: true };
                setUser(userInfo);
                console.log("On login success: ", onLoginSuccess);
                onLoginSuccess && onLoginSuccess(userInfo);
            }
        }).catch((error) => {
            setUser({ ...user, isLogin: true });
            console.log("Cannot get user info: ", error);
        });
    }

    return user;
}

export function useGetAdminNoti(limit, refreshData) {
    var [allGoods, setAllGoods] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (allGoods == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let goodRef = db.collection(COLLECTIONS.ADMIN_NOTI).orderBy("created", "desc");
        if (limit > 0)
            goodRef = goodRef.limit(limit);

        goodRef.get().then((docs) => {

            let results = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    results.push({ key: doc.id, ...doc.data() });
                    console.log("Admin noti data:", doc.data());
                }
            })
            setAllGoods(results);
            setRefresh(false);
        });
    }

    return allGoods;

}

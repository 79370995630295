import Layout from './module/components/Layout';
import DashBoardScreen from './module/dashboard/DashBoardScreen';
import ImportScreen from './module/data/import';
import ExportScreen from './module/data/export';
import BrandScreen from './module/products/BrandScreen';
import BikeScreen from './module/products/BikeScreen';
import ClassScreen from './module/products/ClassScreen';
import GroupScreen from './module/products/GroupScreen';
import PartScreen from './module/products/PartScreen';
import OrderScreen from './module/management/OrderScreen';
import Asking from './module/management/AskingScreen';
import ShipRequestScreen from './module/management/ShipRequestScreen';
import CustomerScreen from './module/management/CustomerScreen';
import Profile from './module/auth/Profile';
import Setting from './module/auth/Setting';
import Content from './module/uiconfig/ContentScreen';
import Bank from './module/uiconfig/BankScreen';
import * as PATH from './common/constant/path';

const routes = [
    {
        component: Layout,
        routes: [
            {
                path: PATH.HOME,
                exact: true,
                component: DashBoardScreen
            },
            {
                path: PATH.IMPORT,
                component: ImportScreen
            },
            {
                path: PATH.EXPORT,
                component: ExportScreen
            },
            {
                path: PATH.BRAND,
                component: BrandScreen
            },
            {
                path: PATH.BIKE,
                component: BikeScreen
            },
            {
                path: PATH.CLASS,
                component: ClassScreen
            },
            {
                path: PATH.PART_GROUP,
                component: GroupScreen
            },
            {
                path: PATH.PART,
                component: PartScreen
            },
            {
                path: PATH.SETTING,
                component: Setting
            },
            {
                path: PATH.PROFILE,
                component: Profile
            },
            {
                path: PATH.CUSTOMER,
                component: CustomerScreen
            },
            {
                path: PATH.ORDER,
                component: OrderScreen
            },
            {
                path: PATH.CONTENT,
                component: Content
            },
            {
                path: PATH.BANK,
                component: Bank
            },
            {
                path: PATH.ASKING,
                component: Asking
            },
            {
                path: PATH.SHIP_REQUEST,
                component: ShipRequestScreen
            },
        ]
    }
];

export default routes;
import React, { useState, useContext, useEffect } from 'react';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as COLLECTIONS from '../../common/constant/collections';
import { db } from '../../common/firebase/Firestore';
import EditableObject from '../components/EditableObject';
import { InputField, INPUT_TYPE } from '../../objects/InputObject';
import { BrandContext } from '../../App';
import Brand from '../../objects/Brand';
import Bike from '../../objects/Bike';
import SearchField from "react-search-field";
import { Icon, Button, Col, Modal, Input, Upload } from 'antd';
import BikeClass from '../../objects/BikeClass';
import {PlusOutlined} from '@ant-design/icons';

export default function NewClassComponent(props) {
    const bikeOptions = props.bikeOptions;
    const searchClassListener = props.searchListener;
    var [bikeClass, setBikeClass] = useState(null);
    var [classes, setClasses] = useState(props.classes);
    var [selectedBike, setSelectedBike] = useState("");
    var [selectedBrand, setSelectedBrand] = useState("");
    var [error, setError] = useState(" ");
    var [isLoading, setIsLoading] = useState(props.isLoading);
    var onCreateSuccess = props.onCreateSuccess;

    useEffect(() => {
        if (bikeOptions && bikeOptions.length > 0) {
            if (bikeOptions.length > 0) {
                if (bikeOptions[0].value) {
                    setSelectedBrand(bikeOptions[0].value);
                }
                if (bikeOptions[0].chidren && Array.isArray(bikeOptions[0].chidren) && bikeOptions[0].chidren[0] && bikeOptions[0].chidren[0].value) {
                    setSelectedBike(bikeOptions[0].chidren[0].value);
                    getAllClassInBike(bikeOptions[0].value, bikeOptions[0].chidren[0].value);
                }

            }
        }
    }, [bikeOptions]);

    const getAllClassInBike = (brand, bike) => {
        console.log("Selected brand", brand);
        console.log("Selected bike", bike);
        db.collection(COLLECTIONS.BRAND).doc(brand)
            .collection(COLLECTIONS.SUB_BIKE).doc(bike)
            .collection(COLLECTIONS.SUB_CLASS).get().then((docs) => {
                let listClass = [];
                if (docs.exists && docs.length > 0) {
                    docs.forEach((doc) => {
                        if (doc.exists) {
                            const bikeClass = {
                                key: doc.id,
                                ...doc.data()
                            };
                            listClass.push(bikeClass);
                        }
                    });
                }
                setClasses(listClass);
                console.log("List class", listClass);
            });
    }

    const onChange = (data) => {
        if (data.bike && Array.isArray(data.bike)) {
            if (data.bike.length > 0 && data.bike[0] != selectedBrand)
                setSelectedBrand(data.bike[0]);

            if (data.bike.length == 2 && data.bike[1] != selectedBike) {
                setSelectedBike(data.bike[1]);

                //Get all group in selected bike
                getAllClassInBike(data.bike[0], data.bike[1]);
            }
        }
    }

    const createNewClass = async (inputData) => {
        if (!inputData) {
            setError("Dữ liệu phiên bản mới đang trống");
            return false;
        }
        try {
            setIsLoading(true);
            var newClass = inputData;

            //Check new brand is existed
            if (!selectedBrand) {
                setError("Bạn phải chọn thương hiệu trước");
            } else if (!selectedBike) {
                setError("Bạn phải chọn đời xe cho phiên bản mới");
            } else if (!classes || classes.length == 0 || classes.filter(b => { return b.name.toLowerCase() == newClass.name.toLowerCase() }).length == 0) {
                //Fill brand & bike property
                newClass.brand = selectedBrand;
                newClass.bike = selectedBike;
                console.log("Start Add new class");

                //Brand not exists, create new brand
                var subClassRef = db.collection(COLLECTIONS.BRAND).doc(`${selectedBrand}`)
                    .collection(COLLECTIONS.SUB_BIKE).doc(selectedBike)
                    .collection(COLLECTIONS.SUB_CLASS);
                var newClassData = await subClassRef.add(newClass);
                console.log("New class Data: ", newClassData);
                    if(newClassData.id) {
                        // var doc=newClassData.docs.doc;
                        setBikeClass(null);
                        setClasses([...classes, newClass]);
                        console.log("Add new class success");
                        setError(" ");
                        newClass.key = newClassData.id;
                        subClassRef.doc(newClass.key).update({ key: newClass.key });
                        onCreateSuccess && onCreateSuccess(newClass);
                        return true;
                    } else {
                        setError("Error write new class");
                        console.log("ERROR: ", newClassData);
                        return false;
                    };
            } else {
                //Show message to notify that brands is existed
                setError("Group name [" + newClass.name + "] is existed");
                console.log("ERROR: ", error.message);
                return false;
            }
        } catch (error) {
            setError(error.name+": "+error.message);
            console.log("ERROR: ", error.message);
            return false;
        }
    };

    const openAddNewDialog = () => {
        var classObj = new BikeClass();
        classObj.setBikes(bikeOptions);
        setBikeClass(classObj);
    }

    const searchClass = (e) => {
        searchClassListener && searchClassListener(e);
    }

    return (
        <div style={{ marginBottom: "10px" }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddNewDialog}>Thêm mới</Button>
                <SearchField
                    placeholder="Search..."
                    onChange={(e) => searchClass(e)}
                    placeholder="Nhập tên Class"
                    searchText=""
                />
            </div>

            <EditableObject
                input={bikeClass}
                onSubmit={createNewClass}
                error={error}
                submitButton="Thêm mới Class"
                title="Thêm mới phiên bản xe"
                onChange={onChange} />

        </div>
    );
}
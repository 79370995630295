import React, { useState, useEffect, useContext } from 'react';
import * as COLLECTIONS from '../../common/constant/collections';
import { fb, db } from '../../common/firebase/Firestore';
import { Table, Tag, InputNumber, Form, Divider, Button, Row, Col, Modal, Input, Upload } from 'antd';
import * as ImageHelper from '../../common/helpers/ImageHelper';
import * as Search from '../../common/helpers/Search';
import { UserContext, BrandContext, BikeContext, ClassContext, GroupContext } from '../../App';
import NewPartComponent from './NewPartComponent';
import EditableObject from '../components/EditableObject';
import * as Converter from '../../common/helpers/Converter';
import * as CustomAntd from './antd-modified.css';
import Bike from '../../objects/Bike';
import PartGroup from '../../objects/PartGroup';
import Part from '../../objects/Part';
import ListPart from './ListPart';
import SearchPartComponent from './SearchPartComponent';
import { message } from 'antd';


function PartScreen(props) {
    const user = useContext(UserContext);
    const allBrands = useContext(BrandContext);
    const allBikes = useContext(BikeContext);
    const allClasses = useContext(ClassContext);
    const allGroups = useContext(GroupContext);

    const [groupOptions, setGroupOptions] = useState(Converter.getCascaderGroup(allBrands, allBikes, allClasses, allGroups));
    // const [groupOptions, setGroupOptions] = useState([]);

    var [editingPart, setEditingPart] = useState(null);
    var [groups, setGroups] = useState([]);
    var [bikeClasses, setBikeClasses] = useState([]);
    var [bikes, setBikes] = useState([]);
    var [brands, setBrands] = useState([]);
    var [pagination, setPagination] = useState({ position: 'both' });
    var [searchText, setSearchText] = useState("");
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState("");

    useEffect(() => {
        if (allBrands && allBrands.length > 0) {
            setBrands(allBrands);
            retriveGroupOptions(allBrands, allBikes, allClasses, allGroups);
        }
    }, [allBrands]);

    useEffect(() => {
        if (allBikes && allBikes.length > 0) {
            setBikes(allBikes);
            retriveGroupOptions(allBrands, allBikes, allClasses, allGroups);
        }
    }, [allBikes]);

    useEffect(() => {
        if (allClasses && allClasses.length > 0) {
            setBikeClasses(allClasses);
            retriveGroupOptions(allBrands, allBikes, allClasses, allGroups);
        }
    }, [allClasses]);

    useEffect(() => {
        if (allGroups && allGroups.length > 0) {
            setGroups(allGroups);
            retriveGroupOptions(allBrands, allBikes, allClasses, allGroups);
        }
    }, [allGroups]);

    const retriveGroupOptions = (inputBrands, inputBikes, inputClasses, inputGroups) => {
        if (inputBrands.length > 0 && inputBikes.length > 0 && inputClasses.length > 0 && inputGroups.length > 0) {
            var groupOptions = Converter.getCascaderGroup(inputBrands, inputBikes, inputClasses, inputGroups);
            setGroupOptions(groupOptions);
            console.log("SetGroupOption: ", groupOptions);
        }
    }

    const handlePaginationChange = e => {
        const { value } = e.target;
        setPagination(value === 'none' ? false : { position: value });
    };

    const cancel = () => {
    };

    const deletePart = (part) => {
        db.collection(COLLECTIONS.BRAND).doc(part.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(part.bike)
        .collection(COLLECTIONS.SUB_CLASS).doc(part.bikeClass.replace("/","_"))
        .collection(COLLECTIONS.SUB_GROUP).doc(`${part.group_id}`)
        .collection(COLLECTIONS.SUB_PART).doc(part.code).delete().then(function() {
            console.log("Part successfully deleted!");
            message.success('Xóa phụ tùng ["' + part.code + '"] thành công!');
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            message.error('Xóa phụ tùng ["' + part.code + '"] thất bại. Hãy thử lại sau!');
        });
    }

    const openPart = (part) => {
        console.log("Edit part: ", part);
        var selectedPart = new Part(part);
        selectedPart.setGroups(groupOptions);
        selectedPart.addLockedField("group");
        setEditingPart(selectedPart);
    }

    const updatePart = async (partData) => {
        console.log("PART update 1: ", partData);
        //Validate existed
        const existRefCode = await db.collection(COLLECTIONS.BRAND).doc(partData.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(partData.bike)
        .collection(COLLECTIONS.SUB_CLASS).doc(partData.bikeClass.replace("/","_"))
        .collection(COLLECTIONS.SUB_GROUP).doc(`${partData.group_id}`)
        .collection(COLLECTIONS.SUB_PART).where("ref_code","==", partData.ref_code).limit(1).get();

        console.log("existRefCode: ", existRefCode);
        if(existRefCode && Array.isArray(existRefCode.docs) && existRefCode.docs.length>0) {
            let found=false;
            existRefCode.docs.forEach((doc)=>{
                if(doc.exists && doc.data().ref_code ===partData.ref_code)
                found=true;
            });
            if(found) {
                setError("Ref code [" + partData.ref_code + "] is existed in current group (group_id:  ["+partData.group_id+"])");
                // return;
            }
        }
        
        const existCode = await db.collection(COLLECTIONS.BRAND).doc(partData.brand)
        .collection(COLLECTIONS.SUB_BIKE).doc(partData.bike)
        .collection(COLLECTIONS.SUB_CLASS).doc(partData.bikeClass.replace("/","_"))
        .collection(COLLECTIONS.SUB_GROUP).doc(`${partData.group_id}`)
        .collection(COLLECTIONS.SUB_PART).where("ref_code","==", partData.ref_code).limit(1).get();

        console.log("existCode: ", existCode);
        if(existCode && Array.isArray(existCode.docs) && existCode.docs.length>0) {
            let found=false;
            existCode.docs.forEach((doc)=>{
                if(doc.exists && doc.data().ref_code ===partData.ref_code)
                found=true;
            });
            if(found) {
                setError("Code [" + partData.ref_code + "] is existed in current group (group_id:  ["+partData.group_id+"])");
                return;
            }
        }

        //Get origin brand & bike value
        var newPart = partData;
        //Save to server
        try {
            console.log("update PART 2: ", newPart);
            db.collection(COLLECTIONS.BRAND).doc(newPart.brand)
                .collection(COLLECTIONS.SUB_BIKE).doc(newPart.bike)
                .collection(COLLECTIONS.SUB_CLASS).doc(newPart.bikeClass.replace("/","_"))
                .collection(COLLECTIONS.SUB_GROUP).doc(`${newPart.group_id}`)
                .collection(COLLECTIONS.SUB_PART).doc(`${newPart.key}`).update(newPart);
        } catch (error) {
            //Recover old data
            // setGroups(oldData);
            console.log('error', error);
        }

        setIsLoading(false);
        setEditingPart(null);
    }

    const searchPart = (e) => {

    }

    const [originData, setOriginData] = useState([]);
    const [data, setData] = useState([]);

    const onResult = (results) => {
        setOriginData(results);
        if (searchText) {
            // console.log("New Text search: ", results);
            setData(Search.searchInObject(results, searchText));
        } else {
            // console.log("Text search empty: ", results);
            setData(results);
        }
    }

    useEffect(() => {
        // console.log("Change text search: ", searchText);
        setData(Search.searchInObject(originData, searchText));
    }, [searchText]);

    return (
        <div>
            <h1>Quản lý phụ tùng</h1>
            <NewPartComponent groupOptions={groupOptions} isLoading={false} searchListener={(text) => { setSearchText(text) }} />

            <h3 style={{ marginTop: "20px" }}>Danh Sách Phụ Tùng</h3>
            <SearchPartComponent limit={50} onResult={onResult} onFilter={(text) => { setSearchText(text) }} />

            <ListPart parts={data} onEdit={openPart} onDelete={deletePart} />

            <EditableObject
                input={editingPart}
                onCancel={() => { setEditingPart(null) }}
                onSubmit={updatePart}
                error={error}
                submitButton="Cập nhật"
                title="Cập nhật thông tin  Xe" />
        </div>
    );
}

// const PartScreen = Form.create({ name: 'part' })(PartForm);
export default PartScreen;
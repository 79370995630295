
export function searchInObject (objects, searchText, searchFields) {
    const results = (searchText && objects) ?
    objects.filter(asset => {
        var searchPart=searchText.trim().split(" ");
        const conditions = searchPart.length;
        var valided = 0;
        var found = false;

        var fields = Object.keys(asset);
        // console.log("Fields: ", fields);

        if(searchFields && searchFields.length>0)
            fields=searchFields;

        for(var text of searchPart) {
            for(var field of fields) {
                // console.log("Field: ", field);
                // console.log("Field Value: ", asset[field] );
                if(asset[field] && asset[field].toString().toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    valided++;
                    break;
                }
            }
            if(valided==conditions) {
                found=true;
                break;
            }
        }
        return found;
    })
    : objects;
    return results;
}
    
